export function colorSelector(contentColor, darkness) {
  const colors = {
    black: {
      soft: '#EBEBEB',
      hard: '#bab9b9',
      dark: '#545454'
    },
    blue: {
      soft: '#C5D6DE',
      hard: '#769CAB',
      dark: '#486278',
      base: '#1661A1'
    },
    green: {
      soft: '#C8DBCD',
      hard: '#8DA894',
      dark: '#57755D',
      base: '#1F5C2C'
    },
    brown: {
      soft: '#E3CDC1',
      hard: '#C29E8A',
      dark: '#A77559',
      base: '#8C502E'
    },
    orange: {
      soft: '#FFC5B2',
      hard: '#f29779',
      dark: '#bf6e54',
      base: '#CE5731',
    },
    red: {
      soft: '#FFC5C2',
      hard: '#E88882',
      dark: '#B5605B',
      base: '#B52D24'
    },
    purple: {
      soft: '#CDBAD4',
      hard: '#A089B2',
      dark: '#695E7F',
      base: '#50318F'
    },
    yellow: {
      soft: '#FCDDB8',
      hard: '#E3B278',
      dark: '#B08756',
      base: '#C47212'
    },
  }

  return contentColor && colors[contentColor] ? colors[contentColor][darkness] : colors.black[darkness]
};