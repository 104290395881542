import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import useStylesFormLink from './styles';

const LinkAtom = ({ formOption1, formOption2, open, setOpen, tabValue }) => {
  const classes = useStylesFormLink();
  const [value, setValue] = useState(tabValue);

  const handleChange = (_, newValue) => {
    setOpen(!open);
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div className={classes.tabPanel}
        style={{display: value !== index && 'none'}}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box className={classes.container_link}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Drogas" />
        <Tab label="Rotinas" />
      </Tabs>
      <TabPanel value={value} index={0}>
        {formOption2}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {formOption1}
      </TabPanel>
    </Box>
  );
};

export default LinkAtom;
