import { makeStyles } from '@material-ui/core/styles';

const useStylesActivePrinciple = makeStyles((theme) => ({

  icons_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
}));

export default useStylesActivePrinciple;
