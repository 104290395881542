import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStylesUnSaveDialogAtom from "./styles";

export default function UnSaveDialogAtom({openUpdateDialog, setOpenUpdateDialog, unSaveAction}) {
  const classes = useStylesUnSaveDialogAtom();

  return (
    <Dialog
      classes={{ paper: classes.dialog_style }}
      open={openUpdateDialog}
      onClose={() => setOpenUpdateDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Deseja sair sem salvar?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Se você sair sem salvar todas as informações serão perdidas. Deseja
          continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          classes={{ root: classes.dialog_action }}
          onClick={() => setOpenUpdateDialog(false)}
          color="primary"
        >
          Não
        </Button>
        <Button
          variant="contained"
          classes={{ root: classes.dialog_action }}
          onClick={() => unSaveAction()}
          color="secondary"
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}
