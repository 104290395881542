/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { ContextAplication } from '../../../context';
import BBLoading from '../../molecules/BBLoading';
import { isRequiredFields } from '../../../utils/isRequiredField';
import ModalAtom from '../../atoms/ModalAtom';
import UnSaveDialogAtom from '../../atoms/UnSaveDialogAtom';
import SpecialSituationForm from '../../molecules/SpecialSituationForm';
import { handleSubmitLinks } from '../../../utils/handleSubmitLinks';
import { useParams } from 'react-router-dom';

const SpecialSituationModal = ({
  closeModal,
  setEditLinkModal,
  handleGetSpecialSituationsByActivePrincipleId,
  specialSituationId,
  setSpecialSituationId,
}) => {
  const { id } = useParams();

  const {
    handleUpdate,
    handleSubmit,
    activePrincipleModel,
    handleGet,
  } = useContext(ContextAplication);

  const [loading, setLoading] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const requiredFields = ['special_situations_item_id'];
  const [specialSituationData, setSpecialSituationData] = useState(null);
  const [initialDataValues, setInitialDataValues] = useState(null);

  const mountObj = (value) => {
    switch (value) {
      case 'Amamentação':
        return setSpecialSituationData({
          ...specialSituationData,
          antidote_active_principle_id: null,
        });
      case 'Gestação':
        return setSpecialSituationData({
          ...specialSituationData,
          antidote_active_principle_id: null,
        });
      case 'Intoxicação e antídoto':
        return setSpecialSituationData({
          ...specialSituationData,
          special_situations_item_type_id: null,
        });
      default:
        return setSpecialSituationData({
          ...specialSituationData,
          antidote_active_principle_id: null,
          special_situations_item_type_id: null,
        });
    }
  };

  const validateErrors = (validateErrors) => {
    if (Object.keys(validateErrors).length !== 0) {
      return true;
    }
  };

  const verifyValueInputRequired = async (
    requiredFields,
    specialSituationData
  ) => {
    const error = await isRequiredFields(requiredFields, specialSituationData);
    setErrors(error);
    return error;
  };

  const handleSubmitCreate = async (isCreate) => {
    setLoading(true);
    try {
      const existError = await verifyValueInputRequired(
        requiredFields,
        specialSituationData
      );

      if (validateErrors(existError)) {
        setLoading(false);
        return false;
      }

      const response = await handleSubmit(
        `specialsituation/${activePrincipleModel._id}`,
        specialSituationData
      );

      if (!isCreate) {
        await setSpecialSituationId(response?._id);
        setLoading(false);
        return setSpecialSituationData(response);
      }

      toast.success('Situação especial criada com sucesso!');

      await handleGetSpecialSituationsByActivePrincipleId();
      handleDialogEvent();
    } catch (error) {
      toast.error('Ocorreu um erro ao criar a situação especial!');
    }
    setLoading(false);
  };

  const handleSubmitUpdate = async (isReview) => {
    setLoading(true);
    try {
      const existError = await verifyValueInputRequired(
        requiredFields,
        specialSituationData
      );

      if (validateErrors(existError)) {
        setLoading(false);
        return false;
      }

      mountObj(specialSituationData.category);

      await handleSubmitLinks(
        initialDataValues,
        specialSituationData,
        ['observation'],
        id
      );

      await handleUpdate(
        'specialsituation',
        specialSituationData._id,
        specialSituationData,
        activePrincipleModel._id
      );

      if (isReview) {
        await handleUpdate(
          'specialsituation/review',
          specialSituationData._id,
          {},
          activePrincipleModel._id
        );
      }

      if (setEditLinkModal) {
        setEditLinkModal(null);
      }
      toast.success('Situação especial atualizada com sucesso!');
      if (handleGetSpecialSituationsByActivePrincipleId) {
        await handleGetSpecialSituationsByActivePrincipleId();
      }
      handleDialogEvent();
      return setLoading(false);
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar a situação especial!');
      setLoading(false);
    }
  };

  const handleChangeEditorPresentation = (key, value) => {
    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
    setSpecialSituationData((state) => ({ ...state, [key]: value }));
  };

  const handleDialogEvent = () => {
    setOpenUpdateDialog(false);
    setSpecialSituationData(null);
    if (setEditLinkModal) {
      setEditLinkModal(null);
    }
    closeModal(null);
  };

  const handleGetSpecialSituation = async (id) => {
    try {
      if (!id) {
        setSpecialSituationData({});
        return;
      }
      setLoading(true);
      const response = await handleGet(`specialsituation/${id}`);
      setSpecialSituationData(response);
      setInitialDataValues(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Ocorreu um erro ao carregar situação especial!');
    }
  };

  useEffect(() => {
    handleGetSpecialSituation(specialSituationId);
  }, [specialSituationId]);

  return (
    <>
      {specialSituationData && (
        <ModalAtom
          modalTheme="yellow"
          onClose={() => {
            handleDialogEvent();
          }}
          title={
            specialSituationId
              ? 'Atualizar situação especial'
              : 'Adicionar situação especial'
          }
          log_review={specialSituationData?.log_review_id}
          isUpdate={specialSituationId}
          submit={specialSituationId ? handleSubmitUpdate : handleSubmitCreate}
          optionLink={specialSituationId ? false : true}
        >
          <SpecialSituationForm
            specialSituationData={specialSituationData}
            setSpecialSituationData={(e) => setSpecialSituationData(e)}
            specialSituationId={specialSituationId}
            handleChangeEditorPresentation={(key, value) =>
              handleChangeEditorPresentation(key, value)
            }
          />
        </ModalAtom>
      )}
      {openUpdateDialog && (
        <UnSaveDialogAtom
          openUpdateDialog={openUpdateDialog}
          setOpenUpdateDialog={(val) => setOpenUpdateDialog(val)}
          unSaveAction={() => {
            handleDialogEvent();
          }}
        />
      )}
      {loading && <BBLoading isOpen={loading} />}
    </>
  );
};

export default SpecialSituationModal;
