/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import TooltipAtom from "../../components/atoms/TooltipAtom";
import { ContextAplication } from "../../context";
import ContentPageOrganism from "../../components/organisms/ContentPageOrganism";
import UnitForm from "../../components/molecules/UnitForm";

import useStylesUnit from "./style";

const Unit = () => {
  const {
    handleGet,
    handleDelete,
    handleSubmit,
    handleUpdate
  } = useContext(ContextAplication);

  const classes = useStylesUnit();
  const [input, setInput] = useState("");
  const [unit, setUnit] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataUnit, setDataUnit] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const handleGetUnit = async () => {
    setLoadingSearch(() => true);
    try {
      const result = await handleGet('unit');
      setUnit(result);
    } catch (error) {
      console.error(error);
    }
    setLoadingSearch(() => false);
  };

  useEffect(() => {
    handleGetUnit();
  }, []);

  const goToCreateUnit = () => {
    setIsUpdate(false);
    setOpen(true);
    setDataUnit({});
  };

  const submitCreate = async () => {
    await handleSubmit('unit', dataUnit);
    await handleGetUnit();
    setDataUnit(null);
    setOpen(false);
  };

  const submitUpdate = async () => {
    await handleUpdate('unit', dataUnit._id, dataUnit);
    await handleGetUnit();
    setDataUnit(null);
    setOpen(false);
  };

  const goToUpdateUnitSelected = (unit) => {
    setIsUpdate(true);
    setOpen(true);
    return setDataUnit(unit);
  };

  const filteredUnit = (data) => {
    if (input !== '') {
      return data.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );
    }
    return data;
  };

  async function handleDeleteButton(unitId, unitTitle) {
    if (window.confirm(`Deseja deletar ${unitTitle}?`)) {
      await handleDelete(`unit/${unitId}`);
      return handleGetUnit();
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Nome",
      align: "left",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Criado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.createdAt}
          placement="top"
        >
          <Typography component={'span'}>{row.createdAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.updatedAt}
          placement="top"
        >
          <Typography component={'span'}>{row.updatedAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row: { _id, title } }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => goToUpdateUnitSelected({ _id, title })}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(_id, title)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let row = [];
  unit.map(
    ({ _id, title, createdAt, updatedAt, deleted }, index) =>
      !deleted &&
      row.push({
        id: index,
        _id,
        title,
        createdAt: moment(createdAt).format("D/M/YYYY - HH:mm"),
        updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
      })
  );

  let timeout = null;
  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  return (
    <DefaultLoggedTemplate>
      <ContentPageOrganism
        titlePage="Unidades"
        onClickAddButton={goToCreateUnit}
        onChangeSearch={debounceEventHandle}
        arrData={filteredUnit(row)}
        columns={columns}
        loading={loadingSearch}
        lottieText="Nenhuma unidade encontrada..."
      />

      {
        open &&
        <UnitForm
          dataUnit={dataUnit}
          setDataUnit={(param) => setDataUnit(param)}
          open={open}
          setOpen={(param) => setOpen(param)}
          submit={isUpdate ? submitUpdate : submitCreate}
        />
      }

    </DefaultLoggedTemplate>
  );
};

export default Unit;
