import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import useStylesStatusReviewAtom from './style';

const AccordionComponent = ({
  isReviewed,
  reviewDate,
  reviewAuthor,
}) => {
  const classes = useStylesStatusReviewAtom();
  
  return (
    <>
      {isReviewed ? (
        <Box className={classes.reviewed_box}>
          <CheckIcon />
          <Typography component={'span'}>
            {
              (reviewDate === '') ?
                'Revisado' :
                `Revisado em ${moment(reviewDate).format("D/M/YYYY - HH:mm")} por 
                ${reviewAuthor}`
            }
          </Typography>
        </Box>
        ) : (
          <Box className={classes.not_reviewed_box}>
            <ClearIcon />
            <Typography component={'span'}>Não revisado</Typography>
          </Box>
        )
      }   
    </>
  );
};

export default AccordionComponent;
