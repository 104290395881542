import { makeStyles } from '@material-ui/core/styles';

const useStylesFormLink = makeStyles((theme) => ({
  container_link: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    width: '70%',
    top: '10%',
    left: '15%',
    '& .MuiTab-wrapper': {
      color: '#fff'
    },
    boxShadow: theme.shadows[5],
    backgroundColor: '#545454',
  },

  tabPanel: {
    width: '90%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  }
}));

export default useStylesFormLink;





