/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";

import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import TooltipAtom from "../../components/atoms/TooltipAtom";
import { ContextAplication } from "../../context";
import ContentPageOrganism from "../../components/organisms/ContentPageOrganism";
import TherapeuticGroupForm from "../../components/molecules/TherapeuticGroupForm";

import useStylesTherapeuticGroup from "./style";

const TherapeuticGroup = () => {
  const {
    handleGetSearch,
  } = useContext(ContextAplication);

  const classes = useStylesTherapeuticGroup();
  const [input, setInput] = useState("");
  const [therapeuticGroup, setTherapeuticGroup] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataTherapeuticGroup, setDataTherapeuticGroup] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const handleGetTherapeuticGroup = async () => {
    if (input.length < 3) {
      return false;
    }
    setLoadingSearch(() => true);
    setTherapeuticGroup([]);
    try {
      const result = await handleGetSearch("therapeuticgroup", input);
      setTherapeuticGroup(result);
    } catch (error) {
      console.error(error);
    }
    setLoadingSearch(() => false);
  };

  useEffect(() => {
    handleGetTherapeuticGroup();
  }, [input]);

  const goToCreateTherapeuticGroup = () => {
    setIsUpdate(false);
    setOpen(true);
    setDataTherapeuticGroup(null);
  };

  const goToUpdateTherapeuticGroupSelected = (object) => {
    setIsUpdate(true);
    setOpen(true);
    return setDataTherapeuticGroup(object);
  };

  const columns = [
    {
      field: "title",
      headerName: "Nome",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.title} placement="top">
          <Typography component={'span'}>{row.title}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "createdAt",
      headerName: "Criado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.createdAt} placement="top">
          <Typography component={'span'}>{row.createdAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.updatedAt} placement="top">
          <Typography component={'span'}>{row.updatedAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => goToUpdateTherapeuticGroupSelected(row)}
          >
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const adapterTherapeuticGroupForDataGrid = () => {
    let row = [];
    therapeuticGroup.length &&
      therapeuticGroup.map(
        (
          {
            _id,
            title,
            specialty_id,
            createdAt,
            updatedAt,
            deleted,
            keywords_id,
            active_principles_id,
            therapeutic_group_active_principles_id,
          },
          index
        ) =>
          !deleted &&
          row.push({
            id: index,
            _id,
            title,
            specialty_id,
            keywords_id,
            active_principles_id,
            therapeutic_group_active_principles_id,
            createdAt: moment(createdAt).format("D/M/YYYY - HH:mm"),
            updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
          })
      );

    return row;
  };

  let timeout = null;
  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  return (
    <DefaultLoggedTemplate>
      <ContentPageOrganism
        titlePage="Grupos Terapêuticos"
        onClickAddButton={goToCreateTherapeuticGroup}
        onChangeSearch={debounceEventHandle}
        arrData={adapterTherapeuticGroupForDataGrid()}
        columns={columns}
        loading={loadingSearch}
        helperText="Digite pelo menos 3 caracteres para iniciar a busca..."
      />
    
      {
        open && 
        <TherapeuticGroupForm
          values={dataTherapeuticGroup}
          setDataTherapeuticGroup={(param) => setDataTherapeuticGroup(param)}
          open={open}
          setOpen={(param) => setOpen(param)}
          isUpdate={isUpdate}
          handleGetTherapeuticGroup={() => handleGetTherapeuticGroup()}
        />
      }
      
    </DefaultLoggedTemplate>
  );
};

export default TherapeuticGroup;
