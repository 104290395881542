import { makeStyles } from '@material-ui/core/styles';

const useStylesDosageUpdateForm = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  close_icon: {
    color: '#fff',
    minWidth: 0,
  },

  modal_header: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },

  paper: {
    margin: '6vh 20vh 20vh 20vh',
    position: 'absolute',
    width: '80%',
    height: '90%',
    color: '#fff',
    backgroundColor: '#8DA894',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#57755d',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#8DA894',
    },
  },

  basic_select_field: {
    display: 'flex',
    padding: 0,
    marginBottom: '10px',
    backgroundColor: '#8da894',
    fontWeight: 'bold',
    width: '100%',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  basic_text_field: {
    width: '100%',
    display: 'flex',
    padding: 0,
    backgroundColor: '#8da894',
    color: '#000',
    fontWeight: '900',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  save_button_box: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  saveButton: {
    width: 'auto',
    height: 40,
    float: 'right',
    marginTop: '1%',
  },

  reviewAndSaveButton: {
    width: 'auto',
    height: 40,
    float: 'left',
    marginTop: '1%',
  },

  review_tag_style: {
    marginTop: '2.5%',
    color: 'black',
    fontWeight: 'bold'
  },

  switchDosageSearch: {
    display: 'contents',
    color: 'black'
  },

  switchDosageUsual: {
    display: 'contents',
    color: 'black'
  },

}));

export default useStylesDosageUpdateForm;
