import React from 'react';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './services/firebase/config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import ApplicationProvider from './context/';
import Routers from './routes';

import './styles/global.css';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Mulish',
      'sans-serif'
    ].join(','),
  }
});

const App = () => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <ThemeProvider theme={theme}>
      <ApplicationProvider>
        <Routers />
        <ToastContainer
          position="bottom-left"
        />
      </ApplicationProvider>
    </ThemeProvider>
  </FirebaseAppProvider>
);

export default App;
