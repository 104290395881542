/* eslint-disable no-multi-str */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/advlist";
import "tinymce/plugins/lists";
import "tinymce/plugins/paste";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import { ContextAplication } from "../../../context";
import ModalLink from "../../molecules/ModalLink";
import { codeHTMLToTranformAccents } from "../../../utils/codeHTMLToTranformAccents";

const EditorHTML = ({
  field,
  handleChangeEditorHTML,
  title,
  initialValue,
  isLink,
  objectData,
  setObjectData,
  objectRoute,
  brandId,
  setPresentationId,
  heightFieldEditor,
  isDebounceNeeded = true,
}) => {
  const [content, setContent] = useState(initialValue);
  let timeout = null;
  const {
    setTextAndIdLink,
    handleGet,
  } = useContext(ContextAplication);
  const [openModalLink, setOpenModalLink] = useState(false);
  const [linkManager, setLinkManager] = useState(null);

  function DebounceEventHandle(value) {
    if (isDebounceNeeded) {
      clearTimeout(timeout);
      timeout = setTimeout(async function () {
        setContent(value);
        return handleChangeEditorHTML(field, value);
      }, 500);
    } else {
      setContent(value);
      return handleChangeEditorHTML(field, value);
    }
  }

  async function captureSelectWord(editor) {
    const selectedText = editor.selection.getContent()
    if (!selectedText) return;
    
    const hash = Date.now();
    const urlHash = `{{link=${hash}}}`;
    const newString = `<a href="${urlHash}" data-pending="true">${selectedText}</a>`;

    editor.execCommand(
      'mceInsertRawHTML',
      false,
      `<a href="${urlHash}" data-pending="true">${selectedText}</a>`
    )

    await setTextAndIdLink({
      text: selectedText,
      id: hash,
      newRow: newString,
      oldRow: newString,
    });

    setOpenModalLink(true);
  }

  async function clickLink(href, text, isPending) {
    if (href) {
      const hash = await href.replace(/[^0-9]+/g, "");

      const newHref = 
        isPending ? 
          `<a href="${href || ""}" data-pending="true">${text}</a>` : 
            `<a href="${href || ""}">${text}</a>`;

      const response = await handleGet(`linkmanager/${hash}/link_id`);

      await setTextAndIdLink({
        text,
        id: hash,
      });
      return setLinkManager({ ...response, text, href: newHref });
    }
  }

  return useMemo(
    () => (
      <Box>
        <Typography component={"span"} style={{ color: "#000" }}>
          {title}
        </Typography>
        {openModalLink && (
          <ModalLink
            setOpenModalLink={(param) => setOpenModalLink(param)}
            linkManager={linkManager}
            setLinkManager={(param) => setLinkManager(param)}
            content={content && codeHTMLToTranformAccents(content)}
            objectData={objectData}
            objectRoute={objectRoute}
            setObjectData={(param) => setObjectData(param)}
            setContent={(param) => setContent(param)}
            field={field}
            brandId={brandId}
            setPresentationId={
              setPresentationId ? (param) => setPresentationId(param) : null
            }
          />
        )}
        <Editor
          initialValue={content}
          init={{
            height: heightFieldEditor,
            menubar: false,
            plugins: [" advlist lists edit paste"],
            toolbar:
              "undo redo | bold italic superscript subscript | \
            numlist bullist | forecolor customInsertButton ",
            default_link_target: "_blank",
            skin: false,
            content_css: false,
            statusbar: false,
            selector: "textarea",
           
            init_instance_callback: function (editor) {
              editor.on("click", async function (e) {
                e.preventDefault()
                if (e?.target?.localName === "a") {
                  await clickLink(
                    e.target.dataset.mceHref, 
                    e.target.outerText, 
                    e.target.dataset?.pending
                  );
                  setOpenModalLink(true);
                }

                if (e?.target?.parentElement?.localName === "a") {
                  await clickLink(
                    e.target.parentElement.dataset.mceHref, 
                    e.target.parentElement.outerText,
                    e.target.parentElement.dataset?.pending
                  );
                  setOpenModalLink(true);
                }
              });
            },

            setup: isLink
              ? function (editor) {
                editor.ui.registry.addButton("customInsertButton", {
                  icon: "link",
                  tooltip: "Inserir link",
                  onAction: () => {
                    captureSelectWord(editor);
                  },
                });
              }
              : null,
          }}
          onEditorChange={(e, _editor) => DebounceEventHandle(e)}
        />
      </Box>
    ),
    [openModalLink, linkManager]
  );
};

export default EditorHTML;
