/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import { ContextAplication } from "../../../context";
import BBLoading from "../../molecules/BBLoading";
import { Keywords } from "../../atoms/Keywords";
import useStylesTherapeuticGroupComponents from "./styles";

const handleChange = (name, input, setData) => {
  setData({ [name]: input });
};

const TherapeuticGroupForm = ({
  values,
  open,
  setOpen,
  isUpdate,
  setDataTherapeuticGroup,
  handleGetTherapeuticGroup,
}) => {
  const classes = useStylesTherapeuticGroupComponents();

  const { handleUpdate, filterIds, getSpecialty, specialty, handleSubmit } =
    useContext(ContextAplication);

  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(values);
  const [keywordsId, setKeywordsId] = useState(values?.keywords_id);
  const [orderedActivePrinciples, setOrderedActivePrinciples] = useState(
    values?.therapeutic_group_active_principles_id
  );

  useEffect(() => {
    if (orderedActivePrinciples) {
      setOrderedActivePrinciples(
        orderedActivePrinciples.sort((a, b) => a.order - b.order)
      );
    }
    getSpecialty();
  }, []);

  const submit = async () => {
    setLoading(true);
    try {
      if (isUpdate) {
        await orderedActivePrinciples.forEach((_, index) => {
          orderedActivePrinciples[index].order = index;
        });

        delete data.keywords_id
        await handleUpdate("therapeuticgroup", values?._id, data);
        await handleSubmit(`therapeuticgroup/log/${values?._id}`, {});
        await handleUpdate("therapeuticgroupactiveprinciple", "ordenation", {
          orderActivePrinciple: orderedActivePrinciples,
        });
        toast.success("Sucesso ao editar o grupo terapêutico!");
      } else {
        await handleSubmit("therapeuticgroup", {
          ...data,
        });
        toast.success("Sucesso ao criar o grupo terapêutico!");
      }

      setKeywordsId([]);
      setLoading(false);
      setOpen(false);
      return handleGetTherapeuticGroup();
    } catch (error) {
      console.log(error)
      toast.error("Ocorreu um erro!");
      return setLoading(false);
    }
  };

  const handleDialogEvent = () => {
    setOpenUpdateDialog(false);
    setData({ title: values?.title, specialty_id: values?.specialty_id });
    setDataTherapeuticGroup(null);
    return setOpen(false);
  };

  const unsaveTherapeuticGroupDialog = (
    <Dialog
      classes={{ paper: classes.dialog_style }}
      open={openUpdateDialog}
      onClose={() => setOpenUpdateDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Deseja sair sem salvar?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Se você sair sem salvar todas as informações serão perdidas. Deseja
          continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          classes={{ root: classes.dialog_action }}
          onClick={() => setOpenUpdateDialog(false)}
          color="primary"
        >
          Não
        </Button>
        <Button
          variant="contained"
          classes={{ root: classes.dialog_action }}
          onClick={handleDialogEvent}
          color="secondary"
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const ordered = Array.from(orderedActivePrinciples);
    const [reorderedItem] = ordered.splice(result.source.index, 1);
    ordered.splice(result.destination.index, 0, reorderedItem);

    setOrderedActivePrinciples(ordered);
    setData({ ...data, therapeutic_group_active_principles_id: ordered });
  };

  const orderArray = (array) => {
    let newArray = array
      .sort((a, b) =>
        a.active_principles_id?.title.localeCompare(
          b.active_principles_id?.title
        )
      )
      .map((arr, idx) => ({
        ...arr,
        order: idx,
      }));

    setOrderedActivePrinciples(newArray);
  };

  const body = (
    <Box className={isUpdate ? classes.paper_update : classes.paper_create}>
      <Box className={classes.modal_header}>
        <Typography component={"span"} variant="h5">
          {isUpdate ? "Atualizar" : "Adicionar"} grupo terapêutico
        </Typography>
        <Button
          onClick={() => {
            setOpenUpdateDialog(true);
            setKeywordsId([]);
          }}
        >
          <CloseIcon className={classes.close_icon} />
        </Button>
      </Box>
      <Box className={isUpdate ? classes.grid_style : classes.flex_style}>
        <Box className={classes.first_container}>
          <TextField
            classes={{ root: classes.basic_text_field_grid }}
            autoComplete="off"
            id="custom-css-outlined-input"
            label="Título"
            variant="outlined"
            value={data?.title}
            onChange={({ target }) =>
              handleChange("title", target.value, setData)
            }
          />
          <Autocomplete
            onChange={(_, value) =>
              setData({ ...data, specialty_id: value._id })
            }
            options={specialty || ""}
            classes={{ root: classes.basic_text_field_grid }}
            defaultValue={data && data.specialty_id}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField {...params} label="Especialidades" margin="normal" />
            )}
          />

          {isUpdate && (
            <Keywords
              keywordArray={keywordsId}
              route={"therapeuticgroup"}
              contentIds={data?._id}
              theme={"gray"}
            />

          )}

        </Box>
        {isUpdate && (
          <>
            <Box className={classes.order_container}>
              <Box className={classes.order_container_title}>
                <h4>Ordenar princípios ativos</h4>
                <Button
                  className={classes.button_order}
                  variant="contained"
                  onClick={() => orderArray(orderedActivePrinciples)}
                >
                  Ordem Alfabética
                </Button>
              </Box>

              <h4 className={classes.order_container_subtitle}>
                ARRASTE para ordenar!
              </h4>
              <Box className={classes.ordenation_component}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="active_principle_card" mode="virtual">
                    {(provided) => (
                      <ul
                        className={classes.active_principle_card_on_list}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {orderedActivePrinciples &&
                          orderedActivePrinciples.map(
                            ({ _id, order, active_principles_id }, index) => {
                              if (active_principles_id) {
                                return (
                                  <Draggable
                                    key={_id}
                                    draggableId={_id}
                                    index={index}
                                    mode="virtual"
                                  >
                                    {(provided) => (
                                      <li
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <Box className={classes.ordenation_title}>
                                          {active_principles_id &&
                                            active_principles_id.title}
                                        </Box>
                                      </li>
                                    )}
                                  </Draggable>
                                );
                              }
                              return null;
                            }
                          )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          startIcon={<SaveIcon />}
          onClick={submit}
        >
          Salvar
        </Button>
      </Box>
      {openUpdateDialog && unsaveTherapeuticGroupDialog}
      {loading && <BBLoading isOpen={loading} />}
    </Box>
  );

  return (
    <Box>
      <Modal
        className={classes.modal}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </Box>
  );
};

export default TherapeuticGroupForm;
