import styled from "styled-components";

const themeSelector = (theme) => {
  const options = {
    green: { dark: "#57755D", light: "#c8dbcd" },
    gray: {dark: "#545454", light:"#BAB9B9"},
  };

  return options[theme];
};

export const KeywordsContainer = styled.div`
  .accordion__header {
    background-color: ${({ theme }) => themeSelector(theme).dark};
    padding: 1rem;
    h3 {
      font-weight: 500;
    }
  }

  .accordion__body {
    background-color: ${({ theme }) => themeSelector(theme).light};

    margin-bottom: 2rem;

    .input__container {
      display: flex;
      flex-wrap: nowrap;

      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        &:focus {
          outline: none;
        }
      }

      .input__addKeywordButton {
        background-color: ${({ theme }) => themeSelector(theme).dark};
        border: none;
        color: white;
        font-size: 1.5rem;
        padding: 0 1rem;
        cursor: pointer;
        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  .optionList__container {
    width: 100%;
    max-height: 10rem;
    max-width: 100%;
    border-top: 1px solid ${({ theme }) => themeSelector(theme).dark};
    background-color: white;
    color: black;
    list-style: none;
    cursor: pointer;
    overflow-y: auto;
    z-index: 200;
    font-size: 1rem;
    font-weight: 700;

    .optionList__item {
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid ${({ theme }) => themeSelector(theme).dark};
      transition: all 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  .selectorButtons__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    button {
      width: 100%;
      padding: 1rem;
      border: none;
      background-color: ${({ theme }) => themeSelector(theme).light};
      cursor: pointer;
      transition: all 0.2s;
      &.active {
        background-color: ${({ theme }) => themeSelector(theme).dark};
        color: white;
      }
      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .emptyKeyword__list {
    display: flex;
    justify-content: center;
    padding: 2rem;
    color: black;
    font-weight: 300;
  }

  .keywordsList {
    max-height: 20rem;
    padding: 1.5rem;
    color: black;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    };
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => themeSelector(theme).dark};
      border-radius: 4px;
    };
    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => themeSelector(theme).light};
    };
    
    ul {
      list-style: none;

      li {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem;
        border-bottom: 1px solid ${({ theme }) => themeSelector(theme).dark};
        &.newKeyword {
          color: #517A22;
        }
      }
    }

    .removeKeyword__button {
      background-color: unset;
      border: none;
      color: #3f51b5;
      cursor: pointer;
      font-size: 1.2rem;

      &:hover {
        filter: brightness(0.4);
      }
    }
  }
`;
