import { makeStyles } from '@material-ui/core/styles';

const useStylesDosageExtraContentForm = makeStyles((theme) => ({

  container: {
    height: 'auto',
  },

  basic_select_field: {
    display: 'flex',
    backgroundColor: '#8da894',
    fontWeight: 'bold',
    width: '100%',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  basic_text_field: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#8da894',
    color: '#000',
    fontWeight: '900',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  size_image: {
    marginTop: '5px',
    width: '40vw',
    height: '40vh'
  },

  text_area_autosize: {
    height: '152px',
    margin: '20px 0 20px 0',
    padding: '5px'
  },

  box_image: {
    width: '40vw',
    alignSelf: 'center',
    marginTop: '10px'
  },

  button_delete_image: {
    marginTop: '10px',
    display: 'flex',
    alignSelf: 'flex-end'
  },

  delete_button_color: {
    backgroundColor: '#f50057',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#f50045',
    }
  }

}));

export default useStylesDosageExtraContentForm;