import React, { useContext } from "react";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ContextAplication } from "../../../context";
import PresentationDataGrid from "../../organisms/Presentation/PresentationDataGrid";

import useStylesBrandComponents from "./styles";

const selectStatusBrand = (value) => {
  const statusMap = {
    AT: "Ativo",
    DE: "Descontinuado",
    DU: "Dúvida",
  };

  return statusMap[value] || "Descontinuado";
};

export default function FormUpdateBrand({
  closeModal,
  handleChange,
  brandData,
  errors,
  hideFields,
  idBrand,
  getBrand
}) {
  const classes = useStylesBrandComponents();

  const { classification, laboratory } = useContext(ContextAplication);

  const verifyId = (value) => {
    if (value) return value._id;
    return null;
  };

  return (
    <>
      <Grid container spacing={3} style={{ alignItems: "center" }}>
        <Grid item={true} xs={3}>
          <FormControl classes={{ root: classes.basic_select_field }}>
            <Autocomplete
              onChange={(e, value) =>
                handleChange("classification_id", verifyId(value))
              }
              options={classification ? classification : []}
              getOptionLabel={(option) => option.title}
              defaultValue={brandData && brandData.classification_id}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Classificação"
                  margin="normal"
                  helperText={
                    errors.classification_id ? errors.classification_id : false
                  }
                  error={errors.classification_id ? true : false}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={3}>
          <TextField
            autoComplete="off"
            classes={{ root: classes.basic_text_field }}
            required
            onChange={({ target }) => handleChange("title", target.value)}
            id="standard-basic"
            label="Nome comercial"
            value={brandData?.title}
            helperText={errors.title ? errors.title : false}
            error={errors.title ? true : false}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <FormControl classes={{ root: classes.basic_select_field }}>
            <Autocomplete
              disabled={hideFields}
              options={laboratory ? laboratory : []}
              getOptionLabel={(option) => option.title}
              defaultValue={brandData ? brandData.laboratory_id : null}
              onChange={(e, value) =>
                handleChange("laboratory_id", verifyId(value))
              }
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Laboratório"
                  margin="normal"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={3}>
          <FormControl classes={{ root: classes.basic_select_field }}>
            <Typography component={'span'} className={classes.statusBrandsField}>
              Status: {selectStatusBrand(brandData?.status)}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>

      <PresentationDataGrid
        closeModal={(param) => closeModal(param)}
        idBrand={idBrand}
        getBrand={() => getBrand()}
      />
    </>
  );
}
