/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { toast } from 'react-toastify';

import Typography from "@material-ui/core/Typography";

import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import TooltipAtom from "../../components/atoms/TooltipAtom";
import { ContextAplication } from "../../context";
import ContentPageOrganism from "../../components/organisms/ContentPageOrganism";


const Logs = () => {
  const {
    handleGet,
  } = useContext(ContextAplication);
  const [input, setInput] = useState("");
  const [logs, setLogs] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const handleGetLogs = async () => {
    setLoadingSearch(() => true);
    try {
      let response = await handleGet(`logs/by-user/log-user-action/${5000}/${input}`);
      setLogs(response);
    } catch (error) {
      toast.error('Nenhum usuário encontrado com esse nome!');
    }
    setLoadingSearch(() => false);
  };

  useEffect(() => {
    if (input?.length >= 3) {
      handleGetLogs();
    }
  }, [input]);

  const columns = [
    {
      field: "title",
      headerName: "Título",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.title}
          placement="top"
        >
          <Typography component={'span'}>{row.title}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "field",
      headerName: "Campo",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.field}
          placement="top"
        >
          <Typography component={'span'}>{row.field}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "name",
      headerName: "Autor",
      align: "left",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.name}
          placement="top"
        >
          <Typography component={'span'}>{row.name}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Data",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.updatedAt}
          placement="top"
        >
          <Typography component={'span'}>{row.updatedAt}</Typography>
        </TooltipAtom>
      ),
    },
  ];

  let row = [];
  logs.map(
    ({ _id, tag, updatedAt, author_id, changedLog }, index) =>
      row.push({
        id: index,
        _id,
        tag,
        updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
        field: changedLog.field_changed,
        title: changedLog.title,
        name: author_id.name
      })
  );

  let timeout = null;
  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  return (
    <DefaultLoggedTemplate>
      <ContentPageOrganism
        titlePage="Histórico"
        onChangeSearch={debounceEventHandle}
        arrData={row}
        columns={columns}
        loading={loadingSearch}
        helperText="Digite o nome completo do usuário para realizar a busca"
      />
    </DefaultLoggedTemplate>
  );
};

export default Logs;
