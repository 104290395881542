/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import TooltipAtom from "../../components/atoms/TooltipAtom";
import { ContextAplication } from "../../context";
import ContentPageOrganism from "../../components/organisms/ContentPageOrganism";
import LaboratoryForm from "../../components/molecules/LaboratoryForm";

import useStylesLaboratory from "./style";

const Laboratory = () => {
  const {
    handleGet,
    handleDelete,
    handleSubmit,
    handleUpdate
  } = useContext(ContextAplication);

  const classes = useStylesLaboratory();
  const [input, setInput] = useState("");
  const [laboratory, setLaboratory] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataLaboratory, setDataLaboratory] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleGetLaboratory = async () => {
    setLoadingSearch(() => true);
    try {
      const result = await handleGet('laboratory');
      setLaboratory(result);
    } catch (error) {
      console.error(error);
    }
    setLoadingSearch(() => false);
  };

  useEffect(() => {
    handleGetLaboratory();
  }, []);

  const goToCreateLaboratory = () => {
    setIsUpdate(false);
    setOpen(true);
    setDataLaboratory({});
  };

  const submitCreate = async () => {
    await handleSubmit('laboratory', dataLaboratory);
    await handleGetLaboratory();
    setDataLaboratory(null);
    setOpen(false);
  };

  const submitUpdate = async () => {
    await handleUpdate('laboratory', dataLaboratory._id, dataLaboratory);
    await handleGetLaboratory();
    setDataLaboratory(null);
    setOpen(false);
  };

  const goToUpdateLaboratorySelected = (laboratory) => {
    setIsUpdate(true);
    setOpen(true);
    return setDataLaboratory(laboratory);
  };

  const filteredLaboratory = (data) => {
    if (input !== '') {
      return data.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );
    }
    return data;
  };

  async function handleDeleteButton(laboratoryId, laboratoryTitle) {
    if (window.confirm(`Deseja deletar ${laboratoryTitle}?`)) {
      await handleDelete(`laboratory/${laboratoryId}`);
      return handleGetLaboratory();
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Nome",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.title}
          placement="top"
        >
          <Typography component={'span'}>{row.title}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "createdAt",
      headerName: "Criado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.createdAt}
          placement="top"
        >
          <Typography component={'span'}>{row.createdAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.updatedAt}
          placement="top"
        >
          <Typography component={'span'}>{row.updatedAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row: { _id, title } }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => goToUpdateLaboratorySelected({ _id, title })}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(_id, title)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let row = [];
  laboratory.map(
    ({ _id, title, createdAt, updatedAt, deleted }, index) =>
      !deleted &&
      row.push({
        id: index,
        _id,
        title,
        createdAt: moment(createdAt).format("D/M/YYYY - HH:mm"),
        updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
      })
  );

  let timeout = null;
  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  return (
    <DefaultLoggedTemplate>
      <ContentPageOrganism
        titlePage="Laboratórios"
        onClickAddButton={goToCreateLaboratory}
        onChangeSearch={debounceEventHandle}
        arrData={filteredLaboratory(row)}
        columns={columns}
        loading={loadingSearch}
        lottieText="Nenhum laboratório encontrado..."
      />

      {
        open &&
        <LaboratoryForm
          dataLaboratory={dataLaboratory}
          setDataLaboratory={(param) => setDataLaboratory(param)}
          open={open}
          setOpen={(param) => setOpen(param)}
          submit={isUpdate ? submitUpdate : submitCreate}
        />
      }

    </DefaultLoggedTemplate>
  );
};

export default Laboratory;
