/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";

import Button from "@material-ui/core/Button";
import BeenhereIcon from "@material-ui/icons/Beenhere";

import useStylesDosageComponents from "./styles";
import { ContextAplication } from "../../../context";
import AlertDialogAtom from "../../atoms/AlertDialogAtom";

const CardFooterMolecule = ({
  isEmpty,
  handleEmptySessionReview,
  openEmptyReviewDialog,
  setOpenEmptyReviewDialog,
}) => {
  const classes = useStylesDosageComponents();

  const { userRole } = useContext(ContextAplication);

  return (
    <div className={classes.container}>
      {userRole !== "editor" && isEmpty ? (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<BeenhereIcon />}
          className={classes.empty_session_button}
          onClick={() => setOpenEmptyReviewDialog(true)}
        >
          Confirmar sessão vazia
        </Button>
      ) : null}
      {openEmptyReviewDialog && (
        <AlertDialogAtom
          onClose={() => setOpenEmptyReviewDialog(false)}
          decline={() => setOpenEmptyReviewDialog(false)}
          confirm={() => handleEmptySessionReview()}
          title="Deseja marcar esta sessão vazia como revisada?"
          description="Ao confirmar a ação, todo esse conteúdo será marcado como revisado!"
          open={openEmptyReviewDialog}
        />
      )}
    </div>
  );
};

export default CardFooterMolecule;
