import { makeStyles } from '@material-ui/core/styles';

const useStylesAdverseEffectsForm = makeStyles((theme) => ({

  basic_select_field: {
    display: 'flex',
    padding: 0,
    marginBottom: '10px',
    fontWeight: 'bold',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

}));

export default useStylesAdverseEffectsForm;