import { makeStyles } from '@material-ui/core/styles';

const useStylesFormLink = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  paper: {
    width: '100%',
    height: '100%',
    backgroundColor: '#BAB9B9',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#545454',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#8DA894',
    },
  },

  basic_select_field: {
    display: 'flex',
    padding: 0,
    marginBottom: '10px',
    backgroundColor: '#BAB9B9',
    fontWeight: 'bold',
    width: '100%',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },
  
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  modal_header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-text': {
      color: '#fff',
      fontWeight: 'bold'
    }
  },

  insertLinkButton: {
    width: 'auto',
    height: 40,
    float: 'right',
    marginTop: '1%',
  },

  close_icon: {
    color: '#fff',
    minWidth: 0,
  },
}));

export default useStylesFormLink;