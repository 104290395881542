import { makeStyles } from '@material-ui/core/styles';

const useStylesStatusReviewAtom = makeStyles((theme) => ({

  reviewed_box: {
    marginRight: 'auto',
    color: 'lawngreen',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  not_reviewed_box: {
    marginRight: 'auto',
    color: 'darkred',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

}));

export default useStylesStatusReviewAtom;
