import { makeStyles } from '@material-ui/core/styles';

const useStylesAccordionBasicInformation = makeStyles((theme) => ({
 
  accordion_container: {
    width: '100%',
  },

  accordion_content: {
    margin: theme.spacing(1),
    flexGrow: 1,
  },

}));

export default useStylesAccordionBasicInformation;
