import { makeStyles } from '@material-ui/core/styles';

const useStylesTherapeuticGroupComponents = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  basic_text_field_grid: {
    marginTop: '10px',
    display: 'flex',
    padding: '5px',
    marginBottom: '10px',
    borderRadius: '5px',
    background: '#424242',
    fontWeight: 'bold',
    '& .MuiFormControl-marginNormal': {
      margin: 0
    },
    '& label.Mui-focused': {
      color: '#606060',
    },
    '& label': {
      color: '#FFF',
    },
    '& input': {
      color: '#FFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#606060',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#606060',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '5vh',
    width: '60vw',
    borderBottom: '0.5px solid dimgray',
  },

  close_icon: {
    color: '#fff',
    minWidth: 0,
  },

  dialog_style: {
    background: '#424242',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    '& .MuiTypography-colorTextSecondary':{
      color: '#fff'
    },
  },

  dialog_action: {
    fontSize: '1rem',
    fontWeight: '700',
  },

  header_content: {
    width: '10vw',
  },

  icons_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },

  therapeutic_group_header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
  },

  modal_header: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper_update: {
    width: '90vw',
    height: 'auto',
    color: '#fff',
    backgroundColor: '#424242',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
  },

  paper_create: {
    height: 'auto',
    color: '#fff',
    backgroundColor: '#424242',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
  },

  grid_style: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
  },

  first_container: {
    display: 'flex',
    flexDirection: 'column',
  },

  order_container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#909090',
    marginTop: '14px',
    '& h4': {
      margin: '2vh',
      fontWeight: '400'
    }
  },

  button_order: {
    height: '1.7rem',
    right: '10px',
  },

  order_container_title: {
    background: '#545454',
    height: '5vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h4': {
      margin: '2vh',
      fontWeight: '400'
    },
  },

  order_container_subtitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  ordenation_component: {
    display: 'flex',
    position: 'relative',
    height: '63vh',
    width: '100%',
  },

  active_principle_card_on_list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    listStyle: 'none',
    width: '100%',
    paddingTop: '45px',
    overflowY: 'scroll',
      '&::-webkit-scrollbar': {

        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: '#5D5D5D',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#909090',
      },
  },

  ordenation_title: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    background: '#545454',
    height: 'auto',
    width: '30vw',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '20px'
  },

  saveButton: {
    width: 120,
    height: 35,
    float: 'right',
    marginTop: '10px',
  },

  table: {
    '& .MuiTableCell-root .MuiTableCell-head .MuiTableCell-alignLeft': {
      position: 'fixed',
    },

    '& MuiTableRow-head': {
      position: 'absolute',
      width: '60vw',
      display: 'flex',
      justifyContent: 'space-around',
    },

    '& .MuiTableCell-head': {
      color: '#fff',
      height: '7vh',
      fontSize: '1.3rem',
    },
    '& .MuiTableCell-root': {
      padding: 0,
      borderBottom: '0.5px rgba(224, 224, 224, 1)',
    },
    '& .MuiTableCell-body': {
      color: '#fff',
    },
  },

  title_content: {
    color: '#fff',
    fontSize: '1rem',
    maxWidth: '10vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

}));

export default useStylesTherapeuticGroupComponents;
