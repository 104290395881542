/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import TooltipAtom from "../../components/atoms/TooltipAtom";
import { ContextAplication } from "../../context";
import ContentPageOrganism from "../../components/organisms/ContentPageOrganism";
import FormPharmaceuticalForm from "../../components/molecules/FormPharmaceuticalForm";

import useStylesPharmaceuticalForm from "./style";

const PharmaceuticalForm = () => {
  const {
    handleGet,
    handleDelete,
    handleSubmit,
    handleUpdate
  } = useContext(ContextAplication);

  const classes = useStylesPharmaceuticalForm();
  const [input, setInput] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataPharmaceuticalForm, setDataPharmaceuticalForm] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const handleGetPharmaceuticalForm = async () => {
    setLoadingSearch(() => true);
    try {
      const result = await handleGet('pharmaceutical');
      setPharmaceuticalForm(result);
    } catch (error) {
      console.error(error);
    }
    setLoadingSearch(() => false);
  };

  useEffect(() => {
    handleGetPharmaceuticalForm();
  }, []);

  const goToCreatePharmaceuticalForm = () => {
    setIsUpdate(false);
    setOpen(true);
    setDataPharmaceuticalForm({});
  };

  const submitCreate = async () => {
    await handleSubmit('pharmaceutical', dataPharmaceuticalForm);
    await handleGetPharmaceuticalForm();
    setDataPharmaceuticalForm(null);
    setOpen(false);
  };

  const submitUpdate = async () => {
    await handleUpdate('pharmaceutical', dataPharmaceuticalForm._id, dataPharmaceuticalForm);
    await handleGetPharmaceuticalForm();
    setDataPharmaceuticalForm(null);
    setOpen(false);
  };

  const goToUpdatePharmaceuticalFormSelected = (pharmaceuticalForm) => {
    setIsUpdate(true);
    setOpen(true);
    return setDataPharmaceuticalForm(pharmaceuticalForm);
  };

  const filteredPharmaceuticalForm = (data) => {
    if (input !== '') {
      return data.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );
    }
    return data;
  };

  async function handleDeleteButton(pharmaceuticalFormId, pharmaceuticalFormTitle) {
    if (window.confirm(`Deseja deletar ${pharmaceuticalFormTitle}?`)) {
      await handleDelete(`pharmaceutical/${pharmaceuticalFormId}`);
      return handleGetPharmaceuticalForm();
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Nome",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.title}
          placement="top"
        >
          <Typography component={'span'}>{row.title}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "createdAt",
      headerName: "Criado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.createdAt}
          placement="top"
        >
          <Typography component={'span'}>{row.createdAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em:",
      align: "center",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.updatedAt}
          placement="top"
        >
          <Typography component={'span'}>{row.updatedAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row: { _id, title } }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => goToUpdatePharmaceuticalFormSelected({ _id, title})}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(_id, title)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let row = [];
  pharmaceuticalForm.map(
    ({ _id, title, createdAt, updatedAt, deleted }, index) =>
      !deleted &&
      row.push({
        id: index,
        _id,
        title,
        createdAt: moment(createdAt).format("D/M/YYYY - HH:mm"),
        updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
      })
  );

  let timeout = null;
  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  return (
    <DefaultLoggedTemplate>
      <ContentPageOrganism
        titlePage="Formas Farmacêuticas"
        onClickAddButton={goToCreatePharmaceuticalForm}
        onChangeSearch={debounceEventHandle}
        arrData={filteredPharmaceuticalForm(row)}
        columns={columns}
        loading={loadingSearch}
        lottieText="Nenhuma forma farmacêutica encontrada..."
      />

      {
        open &&
        <FormPharmaceuticalForm
          dataPharmaceuticalForm={dataPharmaceuticalForm}
          setDataPharmaceuticalForm={(param) => setDataPharmaceuticalForm(param)}
          open={open}
          setOpen={(param) => setOpen(param)}
          submit={isUpdate ? submitUpdate : submitCreate}
        />
      }

    </DefaultLoggedTemplate>
  );
};

export default PharmaceuticalForm;
