import { useState } from 'react';
import { KeywordInputComponent } from './KeywordInputComponent';
import { KeywordsContainer } from './styles';
import { KeywordsList } from './KeywordsList';


export function Keywords({ route, keywordArray, contentIds, theme }) {
  const [keywordsList, setKeywordsList] = useState([]);

  return (
    <KeywordsContainer theme={theme}>
      <div className="accordion__header">
        <h2>Palavras-chave</h2>
      </div>
      <div className="accordion__body">
        <KeywordInputComponent
          route={route}
          contentIds={contentIds}
          setKeywordsList={setKeywordsList}
        />

        <KeywordsList
          keywordsList={keywordsList}
          setKeywordsList={setKeywordsList}
          keywordArray={keywordArray}
          route={route}
          contentIds={contentIds}
        />
      </div>
    </KeywordsContainer>
  );
}
