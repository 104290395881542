import { makeStyles } from '@material-ui/core/styles';

const useStylesActivePrincipleUpdate = makeStyles((theme) => ({
  page_container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    padding: '0px 30px'
  },

  box_link: {
    marginTop: '30px',
  },

  header_active_principle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 8px 10px 0',
    marginBottom: '10px'
  },

  grid_accordions_style: {
    height: '90%',
    padding: '0 20px 20px 20px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#606060',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#303030',
    },  
  },

  content_box: {
    minWidth: '80%',
    height: '90%',
  },
  
  active_principle_title: {
    marginBottom: '10px',
  },

  back_button: {
    height: '35px',
  },

  menu: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    letterSpacing: '0.1rem',
    lineHeight: '2.1rem',
    '& a': {
      color: '#fff',
      textDecoration: 'none'
    },
    paddingLeft: '20px',
  }

}));

export default useStylesActivePrincipleUpdate;