import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import Laboratory from '../Pages/Laboratory';
import ActivePrinciple from '../Pages/ActivePrinciple';
import Specialty from '../Pages/Specialty';
import PharmaceuticalForm from '../Pages/PharmaceuticalForm';
import TherapeuticGroup from '../Pages/TherapeuticGroup';
import Unit from '../Pages/Unit';
import Login from '../Pages/Login/Login';
import PrivateRoute from './PrivateRouter';
import ActivePrincipleUpdate from '../Pages/ActivePrincipleUpdate';
import ActivePrincipleCreate from '../Pages/ActivePrincipleCreate';
import UserRegister from '../Pages/Register';
import Logs from '../Pages/Logs';
import PendingLinks from '../Pages/PendingLinks';
import { PendingLinksUpdate } from '../Pages/PendingLinks/PendingLinksUpdate';

const Routers = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/register" component={UserRegister} />
    <PrivateRoute exact path="/" component={Home} />
    <PrivateRoute exact path="/activeprinciple" component={ActivePrinciple} />
    <PrivateRoute
      exact
      path="/activeprinciple/create"
      component={ActivePrincipleCreate}
    />
    <PrivateRoute
      exact
      path="/activeprinciple/:id"
      component={ActivePrincipleUpdate}
    />
    <PrivateRoute exact path="/therapeuticgroup" component={TherapeuticGroup} />
    <PrivateRoute exact path="/laboratory" component={Laboratory} />
    <PrivateRoute
      exact
      path="/pharmaceuticalform"
      component={PharmaceuticalForm}
    />
    <PrivateRoute exact path="/specialty" component={Specialty} />
    <PrivateRoute exact path="/unit" component={Unit} />
    <PrivateRoute exact path="/logs" component={Logs} />
    <PrivateRoute exact path="/pendinglinks" component={PendingLinks} />
    <PrivateRoute exact path="/pendinglinks/update/:id" component={PendingLinksUpdate} />
    {/* Rota de teste */}
    <PrivateRoute exact path="/test" component={PendingLinksUpdate} />
  </Switch>
);

export default Routers;
