import React from 'react';
import Box from '@material-ui/core/Box';

import useStylesHome from './style';
import logo from '../../assets/images/logo-white.svg';
import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";

const Home = () => {
  const classes = useStylesHome();

  return (
    <DefaultLoggedTemplate>
      <Box className={classes.container_home}>
        <Box className={classes.image_container}>
          <img src={logo} width="500px" alt="Logo BlackBook" />
        </Box>
      </Box>
    </DefaultLoggedTemplate>
  );
};

export default Home;
