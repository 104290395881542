import React from 'react';

import Modal from '@material-ui/core/Modal';

import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';

import { Container } from './styles';

export default function ModalAtom({
  modalTheme,
  onClose,
  title,
  children,
  log_review,
  isUpdate,
  submit,
  optionLink
}) {

  return (
    <>
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container modalTheme={modalTheme}>

          <ModalHeader onClose={onClose} title={title} />

          {children}

          <ModalFooter
            log_review={log_review}
            isUpdate={isUpdate}
            submit={submit}
            optionLink={optionLink}
          />

        </Container>
      </Modal>
    </>
  );
}
