import React, { useContext, useState } from "react";

import EditorHTML from "../../atoms/EditorHTML";
import { ContextAplication } from "../../../context";
import { useEffect } from "react";

import { SpecialSituationFormContainer } from "./style";

export default function SpecialSituationForm({
  handleChangeEditorPresentation,
  specialSituationData,
  setSpecialSituationData,
  specialSituationId
}) {

  const { specialSitutationItem, specialSitutationSecurityType } = useContext(ContextAplication);
  
  const [formType, setFormType] = useState();
  const [isBreastfeedingSecurityType, _setIsBreastfeedingSecurityType] = useState(
    specialSitutationSecurityType.filter((obj) => { return obj.is_breastfeeding === true })
    .sort((a, b) => a.level - b.level)
    );
  const [isPregnancySecurityType, _setIsPregnancySecurityType] = useState(
      specialSitutationSecurityType.filter((obj) => { return obj.is_pregnancy === true })
      .sort((a, b) => a.level - b.level )
      );

  function handleGetFormType() {
    if (!specialSituationData?.special_situations_item_id) {
      return setFormType(null)
    }

    const typeId = specialSituationData.special_situations_item_id?._id ?
    specialSituationData.special_situations_item_id?._id :
    specialSituationData.special_situations_item_id

    const selectedType = specialSitutationItem.find((type) => type._id === typeId)

    setFormType(selectedType.title)
  }

  function handleChangeFormType(value) {
    setSpecialSituationData({
      ...specialSituationData,
      special_situations_item_id: value
    })
  }

  function handleChangeFormSecurityType(value) {
    setSpecialSituationData({
      ...specialSituationData,
      special_situations_item_type_id: value
    })
  }

  useEffect( () => {
    handleGetFormType()
  }, [specialSituationData?.special_situations_item_id])

  return (
    <SpecialSituationFormContainer>
        <form className="category__form">
          <label>
            <select
              className="select__category"
              onChange={(e) => handleChangeFormType(e.target.value)}
              value={specialSituationData?.special_situations_item_id?._id || specialSituationData?.special_situations_item_id}
            >
            <option value="">Categoria</option>
              { specialSitutationItem && specialSitutationItem.map( (category) =>
              <option
                className="option__category"
                value={category._id}
              >
                {category.title}
              </option>
              )}
            </select>
          </label>
        </form>

      { formType === "Amamentação" &&
        <form className="item__form">
          <label>
            <select
              className="select__item"
              onChange={(e) => handleChangeFormSecurityType(e.target.value)}
              value={specialSituationData?.special_situations_item_type_id?._id || specialSituationData?.special_situations_item_type_id}
            >
              <option value="">Item</option>
              { isBreastfeedingSecurityType && isBreastfeedingSecurityType.map( (item) =>
              <option
                className="option__item"
                value={item._id}
              >
                {item.legend}
              </option>
              )}
            </select>
          </label>
        </form>
      }

      { formType === "Gestação" &&
        <form className="item__form">
          <label>
            <select
             className="select__item"
             onChange={(e) => handleChangeFormSecurityType(e.target.value)}
             value={specialSituationData?.special_situations_item_type_id?._id || specialSituationData?.special_situations_item_type_id}
            >
              <option className="option__item">Item</option>
              { isPregnancySecurityType && isPregnancySecurityType.map( (item) =>
              <option
                className="option__item"
                value={item._id}
              >
                {item.legend}
              </option>
              )}
            </select>
          </label>
        </form>
      }

    { specialSituationId &&
      <>
        <EditorHTML
          initialValue={specialSituationData?.observation}
          field={"observation"}
          handleChangeEditorHTML={(key, value) =>
            handleChangeEditorPresentation(key, value)
          }
          title={"Observações"}
          isLink={true}
          objectData={specialSituationData}
          setObjectData={setSpecialSituationData}
          objectRoute={"specialsituation"}
        />
        <EditorHTML
          heightFieldEditor="15vh"
          objectData={specialSituationData}
          initialValue={specialSituationData?.observation_source}
          field={"observation_source"}
          handleChangeEditorHTML={(key, value) =>
            handleChangeEditorPresentation(key, value)
          }
          title={"Fontes (Observações)"}
        />
      </>
    }
    </SpecialSituationFormContainer>
  );
}
