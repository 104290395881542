import { useState } from "react";
import api from "../../../services/axiosApi";

export function KeywordInputComponent({ route, contentIds, setKeywordsList }) {
  const [inputValue, setInputValue] = useState("");


  async function handleSubmitKeywords() {
    try {
      const response = await api.put(`${route}/add-keywords/${contentIds}`, { title: inputValue })
      let oldKeywords = response.data.keywords.filter((keyword) => {
        return response.data.keywordsAdded.findIndex(value => keyword._id === value._id) === -1
      })
      response.data.keywordsAdded.forEach((keyword) => {
        keyword['new'] = true;
      })
      setKeywordsList([...response.data.keywordsAdded, ...oldKeywords])
      setInputValue('')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className="input__container">
        <input
          onChange={(e) => setInputValue(e.target.value)}
          type="text"
          placeholder="Digite a palavra-chave"
          value={inputValue}
        />
        <button
          className="input__addKeywordButton"
          onClick={() => {
            handleSubmitKeywords();
          }}
        >
          +
        </button>
      </div>
    </>
  );
}
