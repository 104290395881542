/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import useStylesAlertDialogAtoms from "./styles";

const AlertDialogAtom = ({onClose, decline, confirm, title, description, open}) => {
  const classes = useStylesAlertDialogAtoms();

  return (
    <Dialog
      classes={{ paper: classes.dialog_style }}
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          classes={{ root: classes.dialog_action }}
          onClick={() => decline()}
          color="primary"
        >
          Não
        </Button>
        <Button
          variant="contained"
          classes={{ root: classes.dialog_action }}
          onClick={()=> confirm()}
          color="secondary"
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogAtom;
