import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label': {
      color: 'white',
    },
    '& input': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
    width: '370',
  },
})(TextField);

const useStylesPharmaceuticalFormComponents = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '5vh',
    width: '60vw',
    borderBottom: '0.5px solid dimgray',
  },

  close_icon: {
    color: '#fff',
    minWidth: 0,
  },
  
  dialog_style: {
    background: '#424242',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    '& .MuiTypography-colorTextSecondary': {
      color: '#fff',
    },
  },

  dialog_action: {
    fontSize: '1rem',
    fontWeight: '700',
  },

  header_content: {
    width: '10vw',
  },

  icons_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },

  pharmaceutical_form_header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
  },

  modal_header: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },

  paper: {
    position: 'absolute',
    top: '30%',
    left: '40%',
    width: 400,
    height: 230,
    color: '#fff',
    backgroundColor: '#424242',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
  },

  saveButton: {
    width: 150,
    height: 40,
    float: 'right',
    marginTop: '1%',
  },

  table: {
    '& .MuiTableCell-root .MuiTableCell-head .MuiTableCell-alignLeft': {
      position: 'fixed',
    },

    '& MuiTableRow-head': {
      position: 'absolute',
      width: '60vw',
      display: 'flex',
      justifyContent: 'space-around',
    },

    '& .MuiTableCell-head': {
      color: '#fff',
      height: '7vh',
      fontSize: '1.3rem',
    },
    '& .MuiTableCell-root': {
      padding: 0,
      borderBottom: '0.5px rgba(224, 224, 224, 1)',
    },
    '& .MuiTableCell-body': {
      color: '#fff',
    },
  },

  title_content: {
    color: '#fff',
    fontSize: '1rem',
    maxWidth: '10vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStylesPharmaceuticalFormComponents;
