import React, { useState, useContext } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import api from "../../services/axiosApi";
import { login } from "../../services";
import { ContextAplication } from "../../context";

import logo from "../../assets/images/logo-white.svg";
import useStylesLogin from "./style";

const Login = () => {
  const { setModelLocalStorage } = useContext(ContextAplication);
  const classes = useStylesLogin();

  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const singIn = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/login", values);
      setModelLocalStorage("loggedinuser", jwt_decode(response.data).data.name);

      login(response.data);

      return history.push("/activeprinciple");
    } catch (err) {
      toast.error("E-mail/Senha inválido(a)!");
    }
  };

  const handleChange =
    (prop) =>
    ({ target }) => {
      setValues({ ...values, [prop]: target.value });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className={classes.page_login}>
      <Box className={classes.container_logo}>
        <img src={logo} width="500px" alt="Logo BlackBook" />
      </Box>
      <Box className={classes.container_form}>
        <Box className={classes.card_login}>
          <TextField
            autoComplete="off"
            classes={{ root: classes.input_field }}
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            onChange={handleChange("email")}
          />
          <FormControl
            classes={{ root: classes.input_field }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    classes={{ root: classes.visible }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <Button
            variant="outlined"
            onClick={singIn}
            classes={{ root: classes.button }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
