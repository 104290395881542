export const presentation_model = {
  pharmaceutical_form_id: null,
  volume: '',
  unity_id: null,
  amount: '',
  free_text: '',
  market: '',
  is_default: true,
  observation: '',
  source_observation: '',
};
