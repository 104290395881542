/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import { ContextAplication } from '../../../context';
import BBLoading from '../../molecules/BBLoading';

import { isRequiredFields } from '../../../utils/isRequiredField';
import ModalAtom from '../../atoms/ModalAtom';
import UseAndSafetyForm from '../../molecules/UseAndSafetyForm';
import { handleSubmitLinks } from '../../../utils/handleSubmitLinks';
import { useParams } from 'react-router-dom';

const UseAndSafetyModal = ({
  closeModal,
  idUseAndSafety,
  setUseAndSafetyId,
  handleGetUseAndSafetiesByActivePrincipleId,
}) => {
  const { id } = useParams();

  const { handleUpdate, handleGet, activePrincipleModel, handleSubmit } =
    useContext(ContextAplication);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const requiredFields = ['category_type_id'];

  const [useAndSafetyData, setUseAndSafetyData] = useState(null);
  const [initialDataValues, setInitialDataValues] = useState(null);

  const handleSubmitCreate = async (isCreate) => {
    setLoading(true);
    try {
      const validateErrors = await isRequiredFields(
        requiredFields,
        useAndSafetyData
      );
      setErrors(validateErrors);
      if (Object.keys(validateErrors).length !== 0) {
        setLoading(false);
        return false;
      }

      const useAndSafetyAdapterData = {
        category_type_id: useAndSafetyData?.category_type_id,
        content: useAndSafetyData?.content,
        content_source: useAndSafetyData?.content_source,
      };

      const response = await handleSubmit(
        `useandsafety/${activePrincipleModel._id}`,
        useAndSafetyAdapterData
      );

      if (!isCreate) {
        await setUseAndSafetyId(response?._id);
        setLoading(false);
        return setUseAndSafetyData(response);
      }

      toast.success('Uso e segurança criado com sucesso!');
      await handleGetUseAndSafetiesByActivePrincipleId();
      closeModal(null);
      setUseAndSafetyData(null);
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar uso e segurança!');
    }

    setLoading(false);
  };

  const handleSubmitUpdate = async (isReview) => {
    setLoading(true);
    try {
      const validateErrors = await isRequiredFields(
        requiredFields,
        useAndSafetyData
      );
      setErrors(validateErrors);
      if (Object.keys(validateErrors).length !== 0) {
        setLoading(false);
        return false;
      }

      const useAndSafetyAdapterData = {
        category_type_id: useAndSafetyData?.category_type_id,
        content: useAndSafetyData?.content,
        content_source: useAndSafetyData?.content_source,
      };

      await handleSubmitLinks(
        initialDataValues,
        useAndSafetyAdapterData,
        ['content'],
        id
      );

      await handleUpdate(
        'useandsafety',
        idUseAndSafety,
        useAndSafetyAdapterData,
        activePrincipleModel._id
      );

      if (isReview) {
        await handleUpdate(
          'useandsafety/review',
          idUseAndSafety,
          {}, //body
          activePrincipleModel._id
        );
      }
      if (handleGetUseAndSafetiesByActivePrincipleId) {
        await handleGetUseAndSafetiesByActivePrincipleId();
      }
      closeModal();
      setUseAndSafetyData(null);
      toast.success('Uso e segurança atualizado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar uso e segurança!');
    }
    setLoading(false);
  };

  const handleChange = (key, value) => {
    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
    setUseAndSafetyData((state) => ({ ...state, [key]: value }));
  };

  const getOneUseAndSafety = async (id) => {
    try {
      if (!id) {
        setUseAndSafetyData({});
        return;
      }

      setLoading(true);
      const response = await handleGet(`useandsafety/${id}`);
      setUseAndSafetyData(response);
      setInitialDataValues(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Ocorreu um erro ao carregar uso e segurança');
    }
  };

  useEffect(() => {
    getOneUseAndSafety(idUseAndSafety);
  }, []);

  return (
    <>
      {useAndSafetyData && (
        <ModalAtom
          modalTheme="purple"
          onClose={() => {
            closeModal(null);
            setUseAndSafetyData(null);
          }}
          title={
            idUseAndSafety
              ? 'Atualizar uso e segurança'
              : 'Adicionar uso e segurança'
          }
          log_review={useAndSafetyData?.log_review_id}
          isUpdate={idUseAndSafety}
          submit={idUseAndSafety ? handleSubmitUpdate : handleSubmitCreate}
          optionLink={idUseAndSafety ? false : true}
        >
          <UseAndSafetyForm
            isUpdate={idUseAndSafety}
            handleChange={(key, value) => handleChange(key, value)}
            useAndSafetyData={useAndSafetyData}
            setUseAndSafetyData={(param) => setUseAndSafetyData(param)}
            errors={errors}
          />
        </ModalAtom>
      )}

      {loading && <BBLoading isOpen={loading} />}
    </>
  );
};

export default UseAndSafetyModal;
