import { makeStyles } from '@material-ui/core/styles';

const useStylesUnSaveDialogAtom = makeStyles((theme) => ({
  dialog_style: {
    background: '#424242',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    width: '40vw',
    '& .MuiTypography-colorTextSecondary': {
      color: '#fff',
    },
  },

  dialog_action: {
    fontSize: '1rem',
    fontWeight: '700',
  },

}));

export default useStylesUnSaveDialogAtom;
