export const specialSituation_model = {
  special_situations_item_type_id: null,
  special_situations_item_id: null,
  observation: '',
  antidote_active_principle_id: null,
  categorie: '',
  item: '',
  observation_source: '',
  icon_id: null,
};
