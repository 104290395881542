import { makeStyles } from '@material-ui/core/styles';

const useStylesAccordions = makeStyles((theme) => ({
  container: {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    margin: '10px 0 0 0',
    backgroundColor: '#545454',
  },

  accordion_summary: {
    backgroundColor: '#545454',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
  },

  accordion_summary_brand: {
    position: 'sticky',
    top: 0,
    zIndex: '1',
    backgroundColor: '#486278',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    },
  },

  accordion_summary_dosage: {
    position: 'sticky',
    top: 0,
    zIndex: '1',
    backgroundColor: '#57755d',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    },
  },

  accordion_summary_special_situations: {
    position: 'sticky',
    top: 0,
    zIndex: '1',
    backgroundColor: '#B08756',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    },
  },

  accordion_summary_adverse_effects: {
    position: 'sticky',
    top: 0,
    zIndex: '1',
    backgroundColor: '#B5605B',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    },
  },

  accordion_summary_use_and_safety: {
    position: 'sticky',
    top: 0,
    zIndex: '1',
    backgroundColor: '#695E7F',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    },
  },

  accordion_summary_link_manager: {
    position: 'sticky',
    top: 0,
    zIndex: '1',
    backgroundColor: '#545454',
    color: '#FFF',
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    },
  },

  accordion_title: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginRight: '1rem'
  },

  reviewed_box: {
    marginRight: 'auto',
    color: 'lawngreen',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  not_reviewed_box: {
    marginRight: 'auto',
    color: 'darkred',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  accordion_container: {
    width: '100%',
  },

  accordion_content: {
    margin: theme.spacing(1),
    flexGrow: 1,
  },

  accordion_sub_content: {
    display: 'flex',
  },

  accordion_details: {
    backgroundColor: '#bab9b9',
    flexDirection: 'column',
    maxHeight: '400vh',
    overflowY: 'scroll',
  },

  accordion_details_brand: {
    backgroundColor: '#769cab',
    flexDirection: 'column',
  },

  accordion_details_dosage: {
    backgroundColor: '#8da894',
    flexDirection: 'column',
  },

  accordion_details_special_situations: {
    backgroundColor: '#E3B278',
    flexDirection: 'column',
  },

  accordion_details_adverse_effects: {
    backgroundColor: '#E88882',
    flexDirection: 'column',
  },

  accordion_details_use_and_safety: {
    backgroundColor: '#A089B2',
    flexDirection: 'column',
  },

  accordion_details_link_manager: {
    backgroundColor: '#BAB9B9',
    flexDirection: 'column',
    height: '133vh'
  },

  display_header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
    width: '100%',
    paddingRight: '10px'
  },

  display_header_title: {
    fontWeight: '500',
  },

  basic_text_field: {
    display: 'flex',
    padding: 0,
    backgroundColor: '#bab9b9',
    color: '#000',
    fontWeight: '900',
    '& label.Mui-focused': {
      color: '#1661a1',
    },
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1661a1',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  basic_text_field_grid: {
    display: 'flex',
    padding: 0,
    marginBottom: '10px',
    backgroundColor: '#bab9b9',
    fontWeight: 'bold',
    '& label.Mui-focused': {
      color: '#1661a1',
    },
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1661a1',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  checkbox_title: {
    '&.Mui-focused': {
      color: '#606060',
    },
  },

  checkbox_button: {
    '&.Mui-checked': {
      color: '#1661a1',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export default useStylesAccordions;
