/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";
import CloseIcon from "@material-ui/icons/Close";

import ItemLog from "../../atoms/ItemLogAtom";
import { ContextAplication } from "../../../context";

import useStylesHistoryLog from "./styles";

const Historylog = ({ field }) => {
  const classes = useStylesHistoryLog();
  const { activePrincipleModel, handleGet } = useContext(ContextAplication);
  const [open, setOpen] = useState(false);
  const [logHistory, setLogHistory] = useState([]);

  async function getLogHistory() {
    let response = await handleGet(
      `logs/${activePrincipleModel._id}--${field}/${3}`
    );

    setLogHistory(response);
    return setOpen(!open);
  }

  const formLog = (
    <Box className={classes.container}>
      <Box className={classes.paper}>
        <Box className={classes.modal_header}>
          <Typography component={"span"} variant="h5">
            Histórico de edições
          </Typography>
          <Button onClick={() => setOpen(false)}>
            <CloseIcon className={classes.close_icon} />
          </Button>
        </Box>
        <Box>
          <List>
            {logHistory.length ? (
              logHistory.map(
                (
                  {
                    author_id: { name },
                    createdAt,
                    changedLog: { id_changed, action, title },
                  },
                  idx
                ) => (
                  <ItemLog
                    key={idx}
                    action={action}
                    createdAt={createdAt}
                    handleGetOne={handleGet}
                    id_changed={id_changed}
                    name={name}
                    title={title || id_changed}
                  />
                )
              )
            ) : (
              <Typography component={"span"} variant="h6">
                Nenhuma ação registrada.
              </Typography>
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <HistoryRoundedIcon
        className={classes.history_log_button}
        fontSize="large"
        onClick={getLogHistory}
      />
      {open && <Modal open={true}>{formLog}</Modal>}
    </Box>
  );
};

export default Historylog;
