import { useContext, useEffect, useState } from 'react';
import EditorHTML from '../../../components/atoms/EditorHTML';
import { PendingLinksUpdateContainer } from './style';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/axiosApi';
import DefaultLoggedTemplate from "../../../components/templates/DefaultLoggedTemplate";
import { ContextAplication } from '../../../context';

export function PendingLinksUpdate() {
  const { activePrincipleModel,
    handleUpdate,
    handleGetActivePrincipleModelById
  } = useContext(ContextAplication)
  const { id } = useParams();
  const history = useHistory();

  const [linkObject, setLinkObject] = useState(null);
  const [contentObject, setContentObject] = useState(null);
  const [brandId, setBrandId] = useState(null);

  async function handleGetOnePendingLink() {
    try {
      const response = await api.get(`/linkmanager/${id}/_id`)
      setLinkObject(response.data)
      handleGetOneContent(response.data?.field_origin, response.data.link_origin_id)
      handleGetLinkParent()
    } catch (error) {
      console.log(error)
    }
  }

  async function handleGetOneContent(route, contentId) {
    try {
      let response = null;

      if (route === "basic_information" || route === "contraindications") {
        response = await api.get(`/activeprinciple/${contentId}`)
      } else {
        response = await api.get(`${route}/${contentId}`)
      }
      setContentObject(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleGetLinkParent(linkId) {
    try {
      const response = await api.get(`/linkmanager/origin/link/${id}`)
      handleGetActivePrincipleModelById(response.data.active_principle)
      setBrandId(response.data?.brand)
    } catch (error) {
      console.log(error)
    }
  }

  function handleParseContentObject() {
    const parseObject = {
      adverse: contentObject?.content,
      useandsafety: contentObject?.content,
      specialsituation: contentObject?.observation,
      presentation: contentObject?.observation,
      dosage: contentObject?.content,
      contraindications: contentObject?.contraindications,
      basic_information: contentObject?.observations,
    }
    return parseObject[linkObject?.field_origin] || null
  }

  function handleParseField() {
    const parseField = {
      adverse: "content",
      useandsafety: "content",
      specialsituation: "observation",
      presentation: "observation",
      dosage: "content",
      contraindications: "contraindications",
      basic_information: "observations"
    }
    return parseField[linkObject?.field_origin] || null
  }

  function handleParseTitle() {
    const parseTitle = {
      adverse: contentObject?.category_type_id?.title,
      useandsafety: contentObject?.category_type_id?.title,
      specialsituation: contentObject?.special_situations_item_id?.title,
      presentation: contentObject?.free_text
        ? `${contentObject?.free_text}`
        : `${contentObject?.pharmaceutical_form_id?.title}: ${contentObject?.volume} ${contentObject?.unity_id?.title} - [${contentObject?.amount}]`,
      dosage: contentObject?.recommendation,
      contraindications: "",
      basic_information: ""
    }
    return parseTitle[linkObject?.field_origin] || null
  }

  function handleChangeContent(field, value) {
    setContentObject({ ...contentObject, [field]: value })
  }

  useEffect(() => {
    handleGetOnePendingLink();
  }, [])

  async function handleSubmitContent() {
    let response = null;

    if (linkObject.field_origin === "presentation") {
      response = await handleUpdate(
        "presentation",
        `${contentObject._id}/${brandId}/${activePrincipleModel._id}`,
        contentObject
      );
    } else {
      response = await handleUpdate(
        linkObject.field_origin === "contraindications" ||
          linkObject.field_origin === "basic_information"
          ? "activeprinciple"
          : `${linkObject.field_origin}`,
        contentObject._id,
        contentObject,
        activePrincipleModel._id
      );
    }
    if (response) {
      history.push('/pendinglinks')
    }
  }

  return (
    <DefaultLoggedTemplate>
      <PendingLinksUpdateContainer>
        {contentObject &&
          <>
            <h3 className="pendingLink__title">Link pendente: {linkObject?.link_origin_text}</h3>
            <h3 className="activePrinciple__title">Princípio Ativo: {activePrincipleModel?.title}</h3>
            <h3 className="field__title">{handleParseTitle()}</h3>

            <EditorHTML
              heightFieldEditor="25vh"
              initialValue={handleParseContentObject()}
              field={handleParseField()}
              handleChangeEditorHTML={(field, value) => handleChangeContent(field, value)}
              isLink={true}
              objectData={contentObject}
              setObjectData={setContentObject}
              objectRoute={linkObject?.field_origin}
              brandId={brandId}
            />

            <button
              className="save__button"
              onClick={() => handleSubmitContent()}
            >
              Salvar
            </button>
          </>
        }
      </PendingLinksUpdateContainer>
    </DefaultLoggedTemplate>
  )
}