/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ContextAplication } from "../../../context";
import EditorHTML from "../../atoms/EditorHTML";
import { Keywords } from "../../atoms/Keywords";

import useStylesDosageUpdateForm from "./styles";

import DosageExtraContentModal from "../../organisms/DosageExtraContentModal";

export default function DosageForm({
  handleChange,
  dosageData,
  errors,
  isUpdate,
  setDosageData,
  handleGetReviewById,
  handleSubmitCreate,
  exitModalDosage,
  dosages
}) {
  const classes = useStylesDosageUpdateForm();
  const { shotGroup } = useContext(ContextAplication);

  const verifyId = (value) => {
    if (value) return value._id;
    return null;
  };

  return (
    <>
      <Grid container spacing={2} classes={{ container: classes.container }}>
        <Grid item={true} xs={3}>
          <FormControl classes={{ root: classes.basic_select_field }}>
            <Autocomplete
              onChange={(_, value) =>
                handleChange("category_type_id", verifyId(value))
              }
              options={shotGroup ? shotGroup : []}
              getOptionLabel={(option) => option.title}
              defaultValue={dosageData.category_type_id}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Grupo"
                  margin="normal"
                  helperText={
                    errors.category_type_id ? errors.category_type_id : false
                  }
                  error={errors.category_type_id ? true : false}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item={true} xs={9}>
          <TextField
            autoComplete="off"
            classes={{ root: classes.basic_text_field }}
            id="custom-css-outlined-input"
            label="Indicação"
            value={dosageData.recommendation}
            helperText={errors.recommendation ? errors.recommendation : false}
            error={errors.recommendation ? true : false}
            onChange={({ target }) =>
              handleChange("recommendation", target.value)
            }
          />
        </Grid>
      </Grid>
      <Box>
        <FormControlLabel
          className={classes.switchDosageUsual}
          control={
            <Switch
              name="Dose usual"
              color="primary"
              checked={dosageData?.is_usual}
              onChange={() => handleChange("is_usual", !dosageData.is_usual)}
            />
          }
          label="Dose usual"
        />
      </Box>

      {isUpdate &&
        <>
          <DosageExtraContentModal
            isUpdateDosage={isUpdate}
            idDosage={dosageData._id}
            handleGetReviewById={() => handleGetReviewById()}
            handleSubmitCreateDosage={(param) => handleSubmitCreate(param)}
            exitModalDosage={() => exitModalDosage()}
          />

          <EditorHTML
            heightFieldEditor="25vh"
            initialValue={dosageData.content}
            field={"content"}
            handleChangeEditorHTML={(key, value) => handleChange(key, value)}
            title={"Conteúdo"}
            isLink={true}
            objectData={dosageData}
            setObjectData={setDosageData}
            objectRoute={"dosage"}
            isDebounceNeeded={false}
          />

          <EditorHTML
            heightFieldEditor="15vh"
            initialValue={dosageData.content_source}
            field={"content_source"}
            handleChangeEditorHTML={(key, value) => handleChange(key, value)}
            title={"Fontes do conteúdo"}
            isDebounceNeeded={false}
          />

          <Keywords
            keywordArray={dosageData.keywords_id}
            route={"dosage"}
            contentIds={dosages}
            theme={"green"}
          />
        </>
      }
    </>
  );
}
