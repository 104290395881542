import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container } from './style.js';
import StatusReviewAtom from '../StatusReviewAtom';

const AccordionAtom = ({
  accordionTitle,
  colorTheme,
  isReviewed,
  reviewDate,
  reviewAuthor,
  isLink,
  children,
  id
}) => {
  return (
    <Container colorTheme={colorTheme}>
      <Accordion id={id} classes={{ root: 'accordion' }}>
        <AccordionSummary
          className="accordion_summary"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography component={'span'} className='accordion_title' variant="h4">
            {accordionTitle}
          </Typography>
          {!isLink && 
            <StatusReviewAtom 
              isReviewed={isReviewed}
              reviewDate={reviewDate}
              reviewAuthor={reviewAuthor}
            />
          }
        </AccordionSummary>

        <AccordionDetails 
          className="accordion_details"
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default AccordionAtom;
