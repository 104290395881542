import React, { createContext, useState } from "react";
import api from "../services/axiosApi";
import {
  setModelLocalStorage,
  getModel,
  clearLocalStorage,
  removeModelLocalStorage,
  isManipulatedClassification,
  getToken,
} from "../services";
import { models } from "../models";
import jwtDecode from "jwt-decode";
const {
  active_principle,
  brand_model,
  presentation_model,
  specialSituation_model,
  dosage_model,
} = models;

export const ContextAplication = createContext();

const AplicationProvider = ({ children }) => {
  const [activePrinciples, setActivePrinciples] = useState([]);
  const [adverseEffectsCategory, setAdverseEffectsCategory] = useState(
    getModel("adversecategory")
  );
  const [brand, setBrand] = useState(getModel("brands"));
  const [classification, setClassification] = useState(
    getModel("classification")
  );
  const [dosage, setDosage] = useState(getModel("dosage"));
  const [dosageExtraContentType, setDosageExtraContentType] = useState(
    getModel("dosageextracontenttype")
  );
  const [laboratory, setLaboratory] = useState([]);
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState([]);
  const [prescription, setPrescription] = useState([]);
  const [shotGroup, setShotGroup] = useState(getModel("group"));
  const [specialty, setSpecialty] = useState([]);
  const [unit, setUnit] = useState([]);
  const [useAndSafetyCategory, setUseAndSafetyCategory] = useState();
  const [routine, setRoutine] = useState([]);

  const [activePrincipleModel, setActivePrincipleModel] = useState(null);

  const [activePrincipleType, setActivePrincipleType] = useState(
    getModel("activeprincipletype")
  );

  const [shortList, setShortList] = useState([]);
  const [isLogTherapeuticGroup, setIsLogTherapeuticGroup] = useState(false);

  const [shortListRemoved, setShortListRemoved] = useState([]);

  const [specialSitutationItem, setSpecialSitutationItem] = useState([]);
  const [specialSitutationSecurityType, setSpecialSitutationSecurityType] =
    useState([]);

  const [isUpdate, setIsUpdate] = useState(false);

  const [openSnackbarCreate, setOpenSnackbarCreate] = useState(false);
  const [openSnackbarUpdate, setOpenSnackbarUpdate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModalLink, setOpenModalLink] = useState(false);
  const [textAndIdLink, setTextAndIdLink] = useState({ text: "", id: "" });

  const [dosageData, setDosageData] = useState(null);

  const handleCheckUserGroup = () => {
    if (getToken()) {
      const result = jwtDecode(getToken()).data.group;
      return result;
    }
    return false;
  };
  
  const handleChangeActivePrinciple = async (field, value) => {
    return setActivePrincipleModel((state) => ({ ...state, [field]: value }));
  };

  const handleClickSnackbarCreate = () => {
    setOpenSnackbarCreate(true);
  };

  const handleCloseSnackbarCreate = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarCreate(false);
  };

  const handleClickSnackbarUpdate = () => {
    setOpenSnackbarUpdate(true);
  };

  const handleCloseSnackbarUpdate = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarUpdate(false);
  };

  const handleClickDialog = () => {
    return true;
  };

  const handleCloseDialog = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    return false;
  };

  const filterIds = (arrayObjects) => {
    let ids = [];
    arrayObjects.map(({ _id }) => ids.push(_id));
    return ids;
  };

  const handleGetActivePrincipleModelById = async (id) => {
    try {
      const responseActivePrinciple = await handleGet(`activeprinciple/${id}`);
      setActivePrincipleModel(responseActivePrinciple);
      setShortList(responseActivePrinciple.therapeutic_group_id);
      return responseActivePrinciple
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetActivePrinciples = async () => {
    try {
      const result = await api.get(`activeprinciple`);
      setActivePrinciples(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDosage = () => {
    if (!getModel("dosage")) {
      api.get("dosage").then(({ data }) => {
        setModelLocalStorage("dosage", data);
        setDosage(getModel("dosage"));
      });
    }
  };

  const getDosageExtraContentType = () => {
    if (!getModel("dosageextracontenttype")) {
      api.get("dosageextracontenttype").then(({ data }) => {
        setModelLocalStorage("dosageextracontenttype", data);
        setDosageExtraContentType(getModel("dosageextracontenttype"));
      });
    }
  };

  const getLaboratory = async () => {
    try {
      const result = await api.get(`laboratory`);
      setLaboratory(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPharmaceuticalForm = () => {
    api.get("pharmaceutical").then(({ data }) => {
      setPharmaceuticalForm(data);
    });
  };

  const getShotGroup = () => {
    if (!shotGroup) {
      api.get("group").then(({ data }) => {
        setShotGroup(data);
      });
    }
  };

  const getUnit = () => {
    api.get("unit").then(({ data }) => {
      setUnit(data);
    }).catch((e) => console.log(e));
  };

  const getUseAndSafetyCategory = async () => {
    if (!useAndSafetyCategory) {
      const response = await api.get("useandsafetycategory");
      setUseAndSafetyCategory(response.data);
    }
  };

  const getSpecialty = async () => {
    try {
      const result = await api.get("specialty");
      const mapData = await result.data.filter((obj) => obj.deleted !== true);
      return setSpecialty(mapData);
    } catch (error) {
      console.error(error);
    }
  };

  const getBrand = () => {
    if (!getModel("brands")) {
      api.get("brands").then(({ data }) => {
        setModelLocalStorage("brands", data);
        setBrand(getModel("brands"));
      });
    }
  };

  const getClassification = async () => {
    const response = await api.get("classification");
    return setClassification(response.data);
  };

  const getAdverseEffectsCategory = () => {
    if (!getModel("adversecategory")) {
      api.get("adversecategory").then(({ data }) => {
        setModelLocalStorage("adversecategory", data);
        setAdverseEffectsCategory(getModel("adversecategory"));
      });
    }
  };

  const getPrescriptions = () => {
    try {
      api.get("icons/prescriptions").then(({ data }) => {
        return setPrescription(data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getActivePrincipleType = () => {
    if (!getModel("activeprincipletype")) {
      api.get("activeprincipletypeoption").then(({ data }) => {
        setModelLocalStorage("activeprincipletype", data);
        setActivePrincipleType(getModel("activeprincipletype"));
      });
    }
  };

  const getSpecialSitutationItem = async () => {
    try {
      const result = await api.get("specialsituationitem");
      setSpecialSitutationItem(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSpecialSitutationSecurityType = async () => {
    try {
      const result = await api.get("specialsituationsecuritytype");
      setSpecialSitutationSecurityType(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutine = async () => {
    try {
      const result = await api.get("routine");
      setRoutine(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGet = async (url, params) => {
    try {
      const result = await api.get(`${url}`, params);
      return result.data;
    } catch (error) {
      throw error?.response?.data;
    }
  };

  const handleDelete = async (url) => {
    return api.delete(`/${url}`);
  };

  const handleSubmit = async (url, body) => {
    const response = api.post(`${url}`, body).then(({ data }) => data);
    return response;
  };

  const handleUpdate = async (url, id, body, tag) => {
    try {
      const result = await api
        .put(`${url}/${id}${tag ? `/${tag}` : ""}`, body)
        .then(({ data }) => data);
      return result;
    } catch (error) {
      console.log(error)
      return false;
    }
  };

  const handleGetSearch = async (url, query) => {
    try {
      const result = await api.get(`${url}/search/${query}`);
      return result.data;
    } catch (error) {
      throw error?.response?.data;
    }
  };

  const userRole = handleCheckUserGroup();

  const context = {
    dosageData,
    setDosageData,
    textAndIdLink,
    setTextAndIdLink,
    openModalLink,
    setOpenModalLink,
    userRole,
    activePrinciples,
    setActivePrinciples,
    brand,
    dosage,
    dosageExtraContentType,
    laboratory,
    pharmaceuticalForm,
    prescription,
    shotGroup,
    unit,
    useAndSafetyCategory,
    specialty,
    classification,
    adverseEffectsCategory,
    activePrincipleType,
    specialSitutationItem,
    specialSitutationSecurityType,
    routine,
    activePrincipleModel,
    active_principle,
    brand_model,
    presentation_model,
    specialSituation_model,
    dosage_model,
    isLogTherapeuticGroup,
    setIsLogTherapeuticGroup,
    setActivePrincipleModel,
    shortList,
    setShortList,
    shortListRemoved,
    setShortListRemoved,
    isUpdate,
    setIsUpdate,
    setBrand,
    openSnackbarCreate,
    openSnackbarUpdate,
    openDialog,
    setOpenDialog,
    handleGetActivePrincipleModelById,
    getModel,
    setModelLocalStorage,
    clearLocalStorage,
    removeModelLocalStorage,
    handleGetActivePrinciples,
    getSpecialSitutationItem,
    getSpecialSitutationSecurityType,
    getBrand,
    getDosage,
    getDosageExtraContentType,
    getLaboratory,
    getShotGroup,
    getPharmaceuticalForm,
    getUnit,
    getUseAndSafetyCategory,
    getSpecialty,
    getClassification,
    getAdverseEffectsCategory,
    getPrescriptions,
    getActivePrincipleType,
    getRoutine,
    handleGet,
    handleDelete,
    handleSubmit,
    handleUpdate,
    handleGetSearch,
    handleClickSnackbarCreate,
    handleClickSnackbarUpdate,
    handleCloseSnackbarCreate,
    handleCloseSnackbarUpdate,
    handleClickDialog,
    handleCloseDialog,
    handleChangeActivePrinciple,
    filterIds,
    isManipulatedClassification,
    handleCheckUserGroup,
  };

  return (
    <div className="container-context">
      <ContextAplication.Provider value={context}>
        {children}
      </ContextAplication.Provider>
    </div>
  );
};

export default AplicationProvider;
