/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ContextAplication } from "../../../context";
import { randomNumber } from "../../../services";

import useStylesPresentationComponents from "../../organisms/Presentation/styles";
import ButtonAtom from "../../atoms/ButtonAtom";

const selectCommercialization = (value) => {
  const statusMap = {
    Ativo: "AT",
    Descontinuado: "IN",
  };

  return statusMap[value] || "DU";
};

const PresentationCreateForm = ({
  open,
  setOpen,
  submit,
  errors,
  setErrors,
  presentationModel,
  setPresentationModel,
}) => {
  const classes = useStylesPresentationComponents();
  const { pharmaceuticalForm, unit, activePrincipleModel } =
    useContext(ContextAplication);

  const [optionForm, setOptionForm] = useState(true);
  const format = ["Padrão", "Texto Livre"];
  const comercialization = ["Ativo", "Descontinuado", "Dúvida"];

  const requiredFields = optionForm
    ? ["pharmaceutical_form_id", "volume", "unity_id", "market"]
    : ["free_text", "market"];

  const optionTextFieldInput = (key, value) => {
    if (value === "Padrão" || value === "") {
      setOptionForm(true);
      return setPresentationModel({ [key]: value });
    } else if (value === "Texto Livre") {
      setOptionForm(false);
      return setPresentationModel({ [key]: value });
    }
  };

  const handleChange = (key, value) => {
    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }

    if (value === "Padrão" || value === "Texto Livre") {
      optionTextFieldInput(key, value);
      return;
    }

    return setPresentationModel((state) => ({ ...state, [key]: value }));
  };

  const verifyId = (value) => {
    if (value) return value._id;
    return null;
  };

  const verifyFreeText = () => {
    if (presentationModel?.free_text) {
      setPresentationModel(() => ({
        ...presentationModel,
        is_default: false,
      }));
    }
  };

  const submitCreate = async (isCreate) => {
    verifyFreeText();

    return submit(
      {
        requiredFields,
        presentationModel,
      },
      isCreate
    );
  };

  const formPresentation = (
    <Box className={classes.paper}>
      <div className={classes.modal_title}>
        <h3>
          Princípio Ativo:{" "}
          {activePrincipleModel ? activePrincipleModel.title : ""}
        </h3>
        <Button onClick={() => setOpen(!open)}>
          <CloseIcon className={classes.close_icon} />
        </Button>
      </div>
      <Box className={classes.modal_header}>
        <Typography component={"span"} variant="h5">
          Adicionar apresentação
        </Typography>
      </Box>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Formato</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={format[0]}
          onChange={({ target }) => handleChange("format", target.value)}
        >
          {format.map((list) => (
            <MenuItem key={randomNumber()} value={list}>
              {list}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {optionForm && (
        <FormControl className={classes.formControl}>
          <Autocomplete
            onChange={(_, value) =>
              handleChange("pharmaceutical_form_id", verifyId(value))
            }
            options={pharmaceuticalForm ? pharmaceuticalForm : []}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Forma farmacêutica"
                margin="normal"
                helperText={
                  errors.pharmaceutical_form_id
                    ? errors.pharmaceutical_form_id
                    : false
                }
                error={errors.pharmaceutical_form_id ? true : false}
              />
            )}
          />
        </FormControl>
      )}
      {optionForm && (
        <TextField
          autoComplete="off"
          onChange={({ target }) => handleChange("volume", target.value)}
          id="standard-basic"
          label="Concentração"
          helperText={errors.volume ? errors.volume : false}
          error={errors.volume ? true : false}
        />
      )}
      {optionForm && (
        <FormControl className={classes.formControl}>
          <Autocomplete
            onChange={(_, value) => handleChange("unity_id", verifyId(value))}
            options={unit.sort((a, b) => a.title.localeCompare(b.title))}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Unidade"
                margin="normal"
                helperText={errors.unity_id ? errors.unity_id : false}
                error={errors.unity_id ? true : false}
              />
            )}
          />
        </FormControl>
      )}
      {optionForm && (
        <TextField
          autoComplete="off"
          onChange={({ target }) => handleChange("amount", target.value)}
          id="standard-basic"
          label="Quantidade"
          helperText={errors.amount ? errors.amount : false}
          error={errors.amount ? true : false}
        />
      )}
      {!optionForm && (
        <TextField
          autoComplete="off"
          onChange={({ target }) => handleChange("free_text", target.value)}
          id="standard-basic"
          label="Campo texto livre"
          helperText={errors.free_text ? errors.free_text : false}
          error={errors.free_text ? true : false}
        />
      )}
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Comercialização</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          helperText={errors.market ? errors.market : false}
          error={errors.market ? true : false}
          onChange={({ target }) =>
            handleChange("market", selectCommercialization(target.value))
          }
        >
          {comercialization.map((list) => (
            <MenuItem key={randomNumber()} value={list}>
              {list}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box>
          <ButtonAtom
            textButton="Criar"
            styles={classes.saveButton}
            onClick={() => submitCreate("Criar")}
          />
      </Box>
    </Box>
  );

  return (
    <Box style={{ marginBottom: "1.3vh" }}>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {formPresentation}
      </Modal>
    </Box>
  );
};

export default PresentationCreateForm;
