/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ContextAplication } from "../../context";
import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import AccordionDosage from "../../components/organisms/AccordionDosage";

import useStylesActivePrincipleUpdate from "./style";
import bbLoadingPA from '../../assets/animations/bbloadingPA.json';

import { BBLoadingAtom } from '../../components/atoms/BBLoadingAtom';
import AccordionAdverseEffects from "../../components/organisms/AccordionAdverseEffects";
import AccordionBrand from "../../components/organisms/AccordionBrand";
import AccordionSpecialSituation from "../../components/organisms/AccordionSpecialSituation";
import AccordionUseAndSafety from "../../components/organisms/AccordionUseAndSafety";
import AccordionBasicInformation from "../../components/organisms/AccordionBasicInformation";
import AccordionContraIndications from "../../components/organisms/AccordionContraIndications";
import AccordionLinks from "../../components/organisms/AccordionLinks";
import api from "../../services/axiosApi";
import { toast } from "react-toastify";

const ActivePrincipleUpdate = () => {
  const classes = useStylesActivePrincipleUpdate();
  const history = useHistory();
  const { id } = useParams();
  const {
    isUpdate,
    setIsUpdate,
    setActivePrincipleModel,
    activePrincipleModel,
    handleGetActivePrincipleModelById,
    specialSitutationItem,
    getSpecialSitutationItem,
    specialSitutationSecurityType,
    getSpecialSitutationSecurityType,
    laboratory,
    getLaboratory,
    setIsLogTherapeuticGroup
  } = useContext(ContextAplication);

  const [review, setReview] = useState(null);

  const handleGetReviewById = async (review_id = null) => {
    try {
      let response = null;
      if(review_id) {
        response = await api.get(
          `review/${review_id}`
        );
      } else {
        response = await api.get(
          `review/${activePrincipleModel.review_id}`
        );
      }
      return setReview(response.data);
    } catch (error) {
      toast.error("Ocorreu um erro ao buscar o review!");
    }
  };

  async function handleGetActivePrincipleAndReview(activePrincipleId) {
    const response = await handleGetActivePrincipleModelById(activePrincipleId)
    handleGetReviewById(response?.review_id)
  }

  useEffect(() => {
    setActivePrincipleModel(null);
    setIsUpdate(true);
    if (id) {
      handleGetActivePrincipleAndReview(id);
    }
    if(!specialSitutationItem.length){
      getSpecialSitutationItem();
    }
    if(!specialSitutationSecurityType.length){
      getSpecialSitutationSecurityType();
    }
    if(!laboratory.length){
      getLaboratory();
    }
  }, []);

  const back = () => {
    setIsLogTherapeuticGroup(false);
    setActivePrincipleModel(null);
    return history.push("/activeprinciple");
  };

  const scroll = (e, id) => {
    e.preventDefault();
    document.querySelector(`${id}`).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <DefaultLoggedTemplate>
      <Box className={classes.page_container}>
        <Box className={classes.content_box}>
          <Box className={classes.header_active_principle}>
            <Typography  component={'span'} variant="h5" className={classes.active_principle_title}>
              Princípio Ativo:{" "}
              {activePrincipleModel ? activePrincipleModel.title : ""}
            </Typography>
            <Button
              classes={{ root: classes.back_button }}
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={back}
            >
              Voltar
            </Button>
          </Box>
          {!activePrincipleModel &&
            <BBLoadingAtom
              loadingJson={bbLoadingPA}
              lottieText="Carregando Princípio Ativo..."
            />
          }
          {activePrincipleModel &&
            <Box className={classes.grid_accordions_style}>
              <AccordionBasicInformation id="basicInformation" handleGetReviewById={handleGetReviewById} review={review}/>
              <AccordionBrand id="brand" handleGetReviewById={handleGetReviewById} review={review}/>
              <AccordionDosage id="dosage" handleGetReviewById={handleGetReviewById} review={review}/>
              <AccordionSpecialSituation id="specialSituations" handleGetReviewById={handleGetReviewById} review={review}/>
              <AccordionContraIndications id="contraIndications" handleGetReviewById={handleGetReviewById} review={review} />
              <AccordionAdverseEffects id="adverseEffects" handleGetReviewById={handleGetReviewById} review={review}/>
              <AccordionUseAndSafety id="useAndSafety" handleGetReviewById={handleGetReviewById} review={review} />
              <Box className={classes.box_link}>
                <AccordionLinks id="linkManager" />
              </Box>
            </Box>
          }
        </Box>
        {isUpdate && (
          <Box className={classes.menu}>
            <li
              href="#basicInformation"
              onClick={(e) => scroll(e, "#basicInformation")}
            >
              Informações básicas
            </li>
            <li onClick={(e) => scroll(e, "#brand")}>
              Marcas
              </li>
            <li onClick={(e) => scroll(e, "#dosage")}>
              Doses
              </li>
            <li onClick={(e) => scroll(e, "#specialSituations")}>
              Situações especiais
            </li>
            <li onClick={(e) => scroll(e, "#contraIndications")}>
              Contra indicações
            </li>
            <li onClick={(e) => scroll(e, "#adverseEffects")}>
              Efeitos colaterais
            </li>
            <li onClick={(e) => scroll(e, "#useAndSafety")}>
              Uso e segurança
            </li>
            <li onClick={(e) => scroll(e, "#linkManager")}>
              Links
            </li>
          </Box>
        )}
      </Box>
    </DefaultLoggedTemplate>
  );
};

export default ActivePrincipleUpdate;
