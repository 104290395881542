import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import UnSaveDialogAtom from "../../atoms/UnSaveDialogAtom";

import useStylesUnitComponents, { CssTextField } from "./styles";

const UnitForm = ({ dataUnit, setDataUnit, open, setOpen, submit }) => {
  const classes = useStylesUnitComponents();

  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const handleChange = (field, value) => {
    setDataUnit({ ...dataUnit, [field]: value });
  };

  const handleDialogEvent = () => {
    setOpenUpdateDialog(false);
    setDataUnit(null);
    return setOpen(false);
  };

  return (
    <Box>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.paper}>
          <Box className={classes.modal_header}>
            <Typography component={"span"} variant="h5">
              Atualizar unidade
            </Typography>
            <Button onClick={() => setOpenUpdateDialog(true)}>
              <CloseIcon className={classes.close_icon} />
            </Button>
          </Box>
          <CssTextField
            autoComplete="off"
            id="custom-css-outlined-input"
            label="Título"
            variant="outlined"
            value={dataUnit?.title}
            onChange={({ target }) => handleChange("title", target.value)}
          />
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              startIcon={<SaveIcon />}
              onClick={() => submit()}
            >
              Salvar
            </Button>
          </Box>
          {openUpdateDialog && (
            <UnSaveDialogAtom
              openUpdateDialog={openUpdateDialog}
              setOpenUpdateDialog={(val) => setOpenUpdateDialog(val)}
              unSaveAction={() => handleDialogEvent()}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default UnitForm;
