import { active_principle } from './activePrinciple';
import { brand_model } from './brands';
import { presentation_model } from './presentation_model';
import { specialSituation_model } from './specialSituation_model';
import { dosage_model } from './dosage';

export const models = {
  active_principle,
  brand_model,
  presentation_model,
  specialSituation_model,
  dosage_model,
};
