import React, { useContext, useState } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { ContextAplication } from "../../context";
import api from "../../services/axiosApi";
import { login } from "../../services";
import useStylesLogin from "./style";
import logo from "../../assets/images/logo-white.svg";

const handleChangeInput = (name, event, input, setRegisterUser) => {
  setRegisterUser({ ...input, [name]: event });
};

const UserRegister = () => {
  const { handleSubmit, setModelLocalStorage } = useContext(ContextAplication);

  const classes = useStylesLogin();

  const [registerUser, setRegisterUser] = useState({
    name: null,
    password: null,
    email: null,
  });
  const history = useHistory();

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      if (!registerUser.email || !registerUser.password || !registerUser.name) {
        toast.warn("Verifique os campos obrigatórios!");
        return;
      }
      await handleSubmit("/create", registerUser);
      const response = await api.post("/login", registerUser);
      toast.success("Usuário criado com sucesso!");
      setModelLocalStorage("loggedinuser", jwt_decode(response.data).data.name);

      login(response.data);

      history.push("/activeprinciple");
    } catch (err) {
      toast.error("Ocorreu um erro ao criar o usuário!");
    }
  };

  const handleClickShowPassword = () => {
    setRegisterUser({
      ...registerUser,
      showPassword: !registerUser.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className={classes.page}>
      <Box className={classes.container_logo}>
        <img src={logo} width="500px" alt="Logo BlackBook" />
      </Box>
      <Box className={classes.container_form}>
        <Box className={classes.card}>
          <TextField
            classes={{ root: classes.input_field }}
            autoComplete="off"
            required
            label="Nome"
            variant="outlined"
            onChange={({ target }) =>
              handleChangeInput(
                "name",
                target.value,
                registerUser,
                setRegisterUser
              )
            }
          />
          <TextField
            classes={{ root: classes.input_field }}
            autoComplete="off"
            required
            label="Email"
            variant="outlined"
            onChange={({ target }) =>
              handleChangeInput(
                "email",
                target.value,
                registerUser,
                setRegisterUser
              )
            }
          />
          <FormControl
            classes={{ root: classes.input_field }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
            <OutlinedInput
              type={registerUser.showPassword ? "text" : "password"}
              value={registerUser.password}
              label="Senha"
              required
              onChange={({ target }) =>
                handleChangeInput(
                  "password",
                  target.value,
                  registerUser,
                  setRegisterUser
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    classes={{ root: classes.visible }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {registerUser.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <Box>
            <Box>
              <Button
                onClick={submitForm}
                classes={{ root: classes.button }}
                type="submit"
              >
                Cadastrar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserRegister;
