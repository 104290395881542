/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { ContextAplication } from "../../context";
import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import ContentPageOrganism from "../../components/organisms/ContentPageOrganism";
import TooltipAtom from "../../components/atoms/TooltipAtom";

import useStylesActivePrinciple from "./style";

const ActivePrinciple = () => {
  const classes = useStylesActivePrinciple();
  const history = useHistory();
  const [input, setInput] = useState("");
  const [activePrinciples, setActivePrinciples] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const {
    handleDelete,
    userRole,
    setIsUpdate,
    setActivePrincipleModel,
    setShortList,
    handleGetSearch,
  } = useContext(ContextAplication);

  const goToCreateActivePrinciple = () => {
    setActivePrincipleModel({});
    setShortList([]);
    setIsUpdate(false);
    history.push("/activeprinciple/create");
  };

  const goToUpdatePage = async (id) => {
    history.push(`/activeprinciple/${id}`);
  };

  async function handleDeleteButton(activePrincipleId, activePrincipleTitle) {
    if (window.confirm(`Deseja deletar ${activePrincipleTitle}?`)) {
      setLoadingSearch(true);
      try {
        await handleDelete(`activeprinciple/${activePrincipleId}`);
        await handleGetActivePrinciples();
        setLoadingSearch(false);
        toast.success("Princípio ativo deletado.");
      } catch (error) {
        toast.error(`${error?.response?.data?.message || 'Erro inesperado!'}`);
      }
    }
  }

  const handleGetActivePrinciples = async () => {
    if (input.length < 3) {
      return false;
    }
    setLoadingSearch(true);
    setActivePrinciples([]);
    try {
      const result = await handleGetSearch(`activeprinciple`, input);
      setActivePrinciples(result);
    } catch (error) {
      console.error(error);
    }
    setLoadingSearch(false);
  };

  function isFullyReviewed(params) {
    if (
      params.row.review_id.brands_full_review &&
      params.row.review_id.adverse_effects_full_review &&
      params.row.review_id.dosage_full_review &&
      params.row.review_id.special_situation_full_review &&
      params.row.review_id.safety_full_review &&
      params.row.review_id.basic_review_log_id &&
      params.row.review_id.contra_indications_review_log_id
    ) {
      return true;
    }
    return false;
  }

  const columns = [
    {
      field: "title",
      headerName: "Nome",
      align: "left",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.title} placement='top'>
          <Typography component={"span"}>{row.title}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "createdAt",
      headerName: "Criado em:",
      align: "center",
      flex: 0.2,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.createdAt} placement='top'>
          <Typography component={"span"}>{row.createdAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em:",
      align: "center",
      flex: 0.2,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.updatedAt} placement='top'>
          <Typography component={"span"}>{row.updatedAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      hide: userRole === "editor",
      field: "review",
      headerName: "Revisado",
      align: "center",
      flex: 0.1,
      valueGetter: (params) => (isFullyReviewed(params) ? "Sim" : "Não"),
      cellClassName: (params) =>
        clsx("review_cell", {
          reviewed: isFullyReviewed(params) === true,
          notReviewed: isFullyReviewed(params) === false,
        }),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.2,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label='update'
            color='primary'
            onClick={() => goToUpdatePage(row._id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label='delete'
            color='secondary'
            onClick={() => handleDeleteButton(row._id, row.title)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const adapterActivePrinciplesForDataGrid = () => {
    let row = [];
    activePrinciples.length &&
      activePrinciples.map(
        ({ _id, title, review_id, createdAt, updatedAt }, index) =>
          row.push({
            id: index,
            _id,
            title,
            review_id,
            createdAt: moment(createdAt).format("D/M/YYYY - HH:mm"),
            updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
          })
      );
    return row;
  };

  let timeout = null;

  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  useEffect(() => {
    handleGetActivePrinciples();
  }, [input]);

  return (
    <DefaultLoggedTemplate>
      <ContentPageOrganism
        titlePage='Princípios Ativos'
        onClickAddButton={goToCreateActivePrinciple}
        onChangeSearch={debounceEventHandle}
        arrData={adapterActivePrinciplesForDataGrid()}
        columns={columns}
        loading={loadingSearch}
        helperText='Digite pelo menos 3 caracteres para iniciar a busca...'
      />
    </DefaultLoggedTemplate>
  );
};

export default ActivePrinciple;
