import React from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuOrganism from  '../../organisms/MenuOrganism';
import useStylesDefaultLoggedTemplate from "./styles";

function DefaultLoggedTemplate(props) {
  const classes = useStylesDefaultLoggedTemplate();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <CssBaseline />
      <MenuOrganism open={open} setOpen={setOpen}/>
      <div 
        className={clsx(classes.container, {
          [classes.containerShift]: open,
        })}
      >
        {props.children}
      </div>
    </>
  );
}

export default DefaultLoggedTemplate;
