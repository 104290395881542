import React, { useState } from "react";

import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DosageModal from "../../organisms/DosageModal";

import PresentationUpdateForm from "../PresentationForm/PresentationUpdateForm";
import UseAndSafetyModal from "../../organisms/UseAndSafetyModal";
import AdverseEffectsModal from "../../organisms/AdverseEffectsModal";
import SpecialSituationModal from "../../organisms/SpecialSituationModal";

export function OnClickEditLink({ objLink }) {
  const [editLinkModal, setEditLinkModal] = useState(null);

  const scroll = (id) => {
    document.querySelector(`${id}`).scrollIntoView({ behavior: "smooth" });
  };

  function handleOpenLinkPath(field) {
    const open = {
      basic_information: () => scroll("#observationBasicInformation"),

      dosage: (
        <DosageModal
          closeModal={(param) => setEditLinkModal(param)}
          idDosage={objLink.link_origin_id}
        />
      ),

      contraindications: () => scroll("#contraIndications"),

      specialsituation: (
        <SpecialSituationModal
          closeModal={(param) => setEditLinkModal(param)}
          specialSituationId={objLink.link_origin_id}
        />
      ),
      adverse: (
        <AdverseEffectsModal
          closeModal={(param) => setEditLinkModal(param)}
          idAdverseEffect={objLink.link_origin_id}
        />
      ),
      useandsafety: (
        <UseAndSafetyModal
          closeModal={(param) => setEditLinkModal(param)}
          idUseAndSafety={objLink.link_origin_id}
        />
      ),
      presentation: (
        <PresentationUpdateForm
          presentationId={objLink.link_origin_id}
          setEditLinkModal={(param) => setEditLinkModal(param)}
        />
      ),
    };

    const aux = open[field] || null;
    setEditLinkModal(aux);
  }

  return (
    <div>
      <IconButton
        aria-label="update"
        color="primary"
        onClick={() => handleOpenLinkPath(objLink.field_origin)}
      >
        <EditIcon />
      </IconButton>
      {editLinkModal && editLinkModal}
    </div>
  );
}
