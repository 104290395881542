import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import TooltipAtom from "../../components/atoms/TooltipAtom";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import ContentPageOrganism from "../../components/organisms/ContentPageOrganism";
import api from "../../services/axiosApi";

const PendingLinks = () => {
  const history = useHistory();
  const [pendingLinks, setPendingLinks] = useState([]);
  const [input, setInput] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);

  async function handleGetPendingLinks() {
    setLoadingSearch(() => true)
    try {
      const response = await api.get('/linkmanager/pending')
      setPendingLinks(response.data)
    } catch (error) {
      console.log(error)
    }
    setLoadingSearch(() => false)
  }

  const filteredPendingLinks = (data) => {
    if (input !== '') {
      return data.filter(({ link_origin_text }) =>
        link_origin_text?.toLowerCase().includes(input.toLowerCase())
      );
    }
    return data;
  };

  function handleType(field) {
    switch (field) {

      case "basic_information":
        return "Informações Básicas"

      case "presentation":
        return "Apresentação"

      case "dosage":
        return "Dose"

      case "specialsituation":
        return "Situação Especial"

      case "contraindications":
        return "Contraindicação"

      case "adverse":
        return "Efeitos Colaterais"

      case "useandsafety":
        return "Uso e Segurança"

      default:
        return "Link sem origem"
    }
  }

  let timeout = null;
  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  useEffect(() => {
    handleGetPendingLinks();
  }, [])

  const columns = [
    {
      field: "link_origin_text",
      headerName: "Texto",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row.link_origin_text}
          placement="top"
        >
          <Typography component={'span'}>{row.link_origin_text}</Typography>
        </TooltipAtom>
      ),
    },

    {
      field: "field_origin",
      headerName: "Origem",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
          <Typography component={'span'}>{handleType(row.field_origin)}</Typography>
      ),
    },

    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <div className="icon_container">
          <IconButton
            title="Ver link"
            aria-label="show_link"
            color="primary"
            onClick={() => history.push(`/pendinglinks/update/${row._id}`)}
          >
            <FaEye />
          </IconButton>
        </div>
      ),
    },
  ];

  let row = [];
  pendingLinks.map((object, index) => {
    row.push({
      ...object,
      id: index
    })
  });

  return (
    <DefaultLoggedTemplate>
      <ContentPageOrganism
        titlePage="Links Pendentes"
        columns={columns}
        lottieText="Nenhum link encontrado..."
        arrData={filteredPendingLinks(row)}
        onChangeSearch={debounceEventHandle}
        loading={loadingSearch}
      />
    </DefaultLoggedTemplate>
  );
}

export default PendingLinks;
