import { makeStyles } from '@material-ui/core/styles';

const useStylesLogin = makeStyles((theme) => ({
  page: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container_logo: {
    width: '35vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container_form: {
    width: '35vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '30vh',
    width: '25vw',
  },
  input_field: {
    boxShadow: theme.shadows[5],
    width: '15vw',
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label': {
      color: 'white',
    },
    '& input': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#424242',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },
  button: {
    background: '#606060',
    width: '12vw',
    color: '#ffff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#424242'
    }
  },
  visible: {
    color: 'dimgray'
  },

  snackbar_style: {
      left: '67.5%',
      bottom: '65%',
  }
}));

export default useStylesLogin;
