import React from "react";
import DataGridAtom from "../../atoms/DataGridAtom";

export default function LinkDatagridMolecule({
  columns,
  doneLinks,
  pendingLinks,
}) {
  return (
    <>
      <>
        <h2>Links feitos</h2>
        <DataGridAtom
          pageSize={10}
          arrData={doneLinks}
          columns={columns}
          theme="gray"
        />
        <h2>Links pendentes</h2>
        <DataGridAtom
          pageSize={10}
          arrData={pendingLinks}
          columns={columns}
          theme="gray"
        />
      </>
    </>
  );
}
