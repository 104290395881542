import { makeStyles } from '@material-ui/core/styles';

const useStylesDataGridAtom = makeStyles((theme) => ({
  data_grid_dark: {
    display: 'flex',
    flexDirection: 'row',
    height: '60vh',
    '& .MuiDataGrid-root': {
      fontSize: '1.1rem',
      color: '#FFF',
      border: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#FFF',
    },
    '& .MuiDataGrid-window': {
      overflowY: 'scroll',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#5D5D5D',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#424242',
      },
    },
    '& .MuiTablePagination-root': {
      color: '#fff',
    },
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'center',
      backgroundColor: '#424242',
    },
    '& .MuiDataGrid-cell': {
      borderColor: '#5D5D5D',
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiDataGrid-cellWithRenderer':{
      justifyContent: 'center',
      '&:first-child': {
        justifyContent: 'left',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#5D5D5D',
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#424242',
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: '1px solid #5D5D5D',
    },
    '& .review_cell.reviewed': {
      color: 'lawngreen',
    },
    '& .review_cell.notReviewed': {
      color: 'red',
    },
  },

  data_grid_blue: {
    '& .MuiDataGrid-root': {
      fontSize: '1.1rem',
      color: '#000',
      border: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#FFF',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#486278',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#769cab',
      },
    },
    '& .MuiTablePagination-root': {
      color: '#000',
    },
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'center',
      backgroundColor: '#486278',
    },
    '& .MuiDataGrid-cell': {
      borderColor: '#486278',
      display: 'flex',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      },
      '&:first-child': {
        justifyContent: 'left',
      },
      '&:nth-child(2)': {
        justifyContent: 'left',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#769cab'
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#486278',
      '&:focus': {
        outline: 'none'
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom:  '1px solid #769cab',
    },
  },

  data_grid_green: {
    '& .MuiDataGrid-root': {
      fontSize: '1.1rem',
      color: '#000',
      border: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#FFF',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#57755d',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#8da894',
      },
    },
    '& .MuiTablePagination-root': {
      color: '#000',
    },
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'center',
      backgroundColor: '#57755d',

    },
    '& .MuiDataGrid-cell': {
      borderColor: '#57755d',
      display: 'flex',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      },
      '&:first-child': {
        justifyContent: 'left',
      },
      '&:nth-child(2)': {
        justifyContent: 'left',
      },
      '&:nth-child(3)': {
        justifyContent: 'left',
      },
      '&:nth-child(4)': {
        justifyContent: 'left',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#8da894'
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#57755d',
      '&:focus': {
        outline: 'none'
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom:  '1px solid #8da894',
    },
  },

  data_grid_yellow: {
    '& .MuiDataGrid-root': {
      fontSize: '1.1rem',
      color: '#000',
      border: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#FFF',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#B08756',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#E3B278',
      },
    },
    '& .MuiTablePagination-root': {
      color: '#000',
    },
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'center',
      backgroundColor: '#B08756',
    },
    '& .MuiDataGrid-cell': {
      borderColor: '#B08756',
      display: 'flex',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      },
      '&:first-child': {
        justifyContent: 'left',
      },
      '&:nth-child(3)': {
        justifyContent: 'left',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#E3B278'
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#B08756',
      '&:focus': {
        outline: 'none'
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom:  '1px solid #e2b278',
    },
  },

  data_grid_red: {
    '& .MuiDataGrid-root': {
      fontSize: '1.1rem',
      color: '#000',
      border: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#FFF',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#B5605B',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#E88882',
      },
    },
    '& .MuiTablePagination-root': {
      color: '#000',
    },
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'center',
      backgroundColor: '#B5605B',

    },
    '& .MuiDataGrid-cell': {
      borderColor: '#B5605B',
      display: 'flex',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      },
      '&:first-child': {
        justifyContent: 'left',
      },
      '&:nth-child(2)': {
        justifyContent: 'left',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#E88882',
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#B5605B',
      '&:focus': {
        outline: 'none'
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom:  '1px solid #e88782',
    },
  },

  data_grid_purple: {
    '& .MuiDataGrid-root': {
      fontSize: '1.1rem',
      color: '#000',
      border: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#FFF',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#695E80',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#A089B2',
      },
    },
    '& .MuiTablePagination-root': {
      color: '#000',
    },
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'center',
      backgroundColor: '#695E80',

    },
    '& .MuiDataGrid-cell': {
      borderColor: '#695E7F',
      display: 'flex',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      },
      '&:first-child': {
        justifyContent: 'left',
      },
      '&:nth-child(2)': {
        justifyContent: 'left',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#A089B2'
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#695E7F',
      '&:focus': {
        outline: 'none'
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom:  '1px solid #a088b2',
    },
  },

  data_grid_gray: {
    '& .MuiDataGrid-root': {
      fontSize: '1.1rem',
      color: '#000',
      border: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#FFF',
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#545454',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#BAB9B9',
      },
    },
    '& .MuiTablePagination-root': {
      color: '#000',
    },
    '& .MuiDataGrid-colCellTitleContainer': {
      justifyContent: 'center',
      backgroundColor: '#545454',
    },
    '& .MuiDataGrid-cell': {
      borderColor: '#545454',
      display: 'flex',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      },
      '&:first-child': {
        justifyContent: 'left',
      },
      '&:nth-child(2)': {
        justifyContent: 'left',
      },
      '&:nth-child(3)': {
        justifyContent: 'left',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: '#BAB9B9'
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: '#545454',
      '&:focus': {
        outline: 'none'
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom:  '1px solid #BAB9B9',
    },
  },

}));

export default useStylesDataGridAtom;
