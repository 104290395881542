import React from "react";
import Modal from "@material-ui/core/Modal";
import bbloading from "../../../assets/animations/bbloading.json";

import { BBLoadingAtom } from "../../atoms/BBLoadingAtom";

export default function BBLoading(isOpen) {
  return (
    <>
      <Modal open={isOpen}>
        <BBLoadingAtom
          loadingJson={bbloading}
          paddingParam="150px"
          lottieText="Carregando..."
          textMargin={-80}
        />
      </Modal>
    </>
  );
}
