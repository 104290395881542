import { makeStyles } from '@material-ui/core/styles';

const useStylesItemLog = makeStyles((theme) => ({

    item_log: {
        backgroundColor: '#FFF',
        borderRadius: '10px',
        margin: '5px 0',
        height: '100px',
        wordBreak: 'break-all',
        color: '#000',
        '& .MuiTypography-colorTextSecondary': {
            color: '#303030'
        },
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#545454',
            borderRadius: '10px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#BAB9B9',
        }
    },

}));

export default useStylesItemLog;