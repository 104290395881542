import { TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStylesUnit = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },

  content: {
    height: '60vh',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: '#424242',
  },

  tool: {
    height: 'auto',
    width: '70vw',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  toolbar_header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem', 
  },

}));

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label': {
      color: 'dimgray',
    },
    '& input': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#424242',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
    width: '100%',
  },
})(TextField);

export default useStylesUnit;
