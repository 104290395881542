/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Autocomplete from "@material-ui/lab/Autocomplete";

import useStylesDosageExtraContentForm from "./style";
import UploadImageContainer from "./UploadImageContainer";

function DosageExtraContentForm({
  extraContentTypeData,
  dosageExtraContentRow,
  handleChange,
  setFileImage,
  fileImage,
  isUpdate,
}) {
  const classes = useStylesDosageExtraContentForm();
  const [type, setType] = useState(false);

  function renderOptionForm(type) {
    if (type === "Imagem") {
      return setType(true);
    }
    return setType(false);
  }

  const verifyId = (value) => {
    renderOptionForm(value?.title);
    if (value) return value._id;
    return null;
  };

  useEffect(() => {
    if (dosageExtraContentRow?.dosage_extra_content_type_id) {
      renderOptionForm(
        dosageExtraContentRow?.dosage_extra_content_type_id.title
      );
    }
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item={true} xs={12}>
          <FormControl className={classes.basic_select_field}>
            {isUpdate ? (
              <h1>
                {dosageExtraContentRow?.dosage_extra_content_type_id.title}
              </h1>
            ) : (
              <Autocomplete
                onChange={(_, value) =>
                  handleChange("dosage_extra_content_type_id", verifyId(value))
                }
                options={extraContentTypeData ? extraContentTypeData : []}
                getOptionLabel={(option) => option.title}
                defaultValue={
                  dosageExtraContentRow?.dosage_extra_content_type_id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Conteúdo extra"
                    margin="normal"
                  />
                )}
              />
            )}
            {!type && (
              <>
                <Grid item={true} xs={12}>
                  <TextField
                    className={classes.basic_text_field}
                    autoComplete="off"
                    required
                    defaultValue={dosageExtraContentRow?.title}
                    onChange={({ target }) =>
                      handleChange("title", target.value)
                    }
                    id="standard-basic"
                    label="Título"
                  />
                </Grid>
                <TextareaAutosize
                  className={classes.text_area_autosize}
                  rowsMin={10}
                  aria-label="maximum height"
                  placeholder="Seu texto aqui!"
                  defaultValue={dosageExtraContentRow?.content}
                  onChange={({ target }) =>
                    handleChange("content", target.value)
                  }
                />
              </>
            )}
            {type && (
              <UploadImageContainer image={fileImage} setImage={setFileImage} />
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default DosageExtraContentForm;
