export const brand_model = {
  title: '',
  status: 'DE',
  is_reference: false,
  is_client_reference: false,
  laboratory_id: null,
  classification_id: null,
  presentations_id: [],
  brand_review_author: '',
};
