/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { toast } from 'react-toastify';

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import api from "../../../services/axiosApi";
import { ContextAplication } from "../../../context";

import SpecialSituationModal from "../SpecialSituationModal";
import AccordionAtom from "../../atoms/AccordionAtom";
import DataGridAtom from "../../atoms/DataGridAtom";
import TooltipAtom from "../../atoms/TooltipAtom";
import CardHeaderMolecule from "../../molecules/CardHeaderMolecule";
import CardFooterMolecule from "../../molecules/CardFooterMolecule";

import useStylesDosageComponents from "./styles";
import { getNameAuthor, getReviewCreatedAt } from "../../../utils/reviewFunctions";

const AccordionSpecialSituation = ({ id, handleGetReviewById, review }) => {
  const [specialSituations, setSpecialSituations] = useState([]);
  const [specialSituationId, setSpecialSituationId] = useState(null);
  const [openEmptyReviewDialog, setOpenEmptyReviewDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {
    activePrincipleModel,
  } = useContext(ContextAplication);

  const classes = useStylesDosageComponents();

  const handleGetSpecialSituationsByActivePrincipleId = async (isFirstRendering = false) => {
    try {
      const response = await api.get(`specialsituation/by-active-principle/${activePrincipleModel._id}`);
      if(!isFirstRendering) {
        await handleGetReviewById()
      }
      return setSpecialSituations(response.data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar situação especial!');
    }
  };


  useEffect(() => {
    handleGetSpecialSituationsByActivePrincipleId(true);
  }, []);

  async function handleDeleteButton(specialSituationId) {
    try {
      if (window.confirm(`Deseja deletar?`)) {
        await api.delete(`specialsituation/${specialSituationId}/${activePrincipleModel._id}`);
        await handleGetSpecialSituationsByActivePrincipleId();
        await handleGetReviewById();
        toast.success('Situação especial deletada com sucesso!');
      }
    } catch (error) { toast.error('Ocorreu um erro ao deletar a situação especial!'); }
  }

  const handleEmptySessionReview = async () => {
    try {
      await api.post(`review/${activePrincipleModel.review_id}/special_situation_full_review`, {});
      await handleGetSpecialSituationsByActivePrincipleId();
      await handleGetReviewById();
      toast.success('Situação especial revisada como vazia.');
    } catch (error) {
      toast.error('Ocorreu um erro ao revisar como vazio!');
    }
    
    setOpenEmptyReviewDialog(false);
  };
  
  const handleOpenForm = (id) => {
    setOpenModal(true);
    setSpecialSituationId(id);
  }

  function parseHTML(text) {
    const aux = ReactHtmlParser(text);
    if (aux) {
      return aux;
    }

    return text;
  }

  const verifyItem = (specialSituation) => {
    if (specialSituation.antidote_active_principle_id) {
      return specialSituation.antidote_active_principle_id.title;
    }
    if (specialSituation.special_situations_item_type_id) {
      return specialSituation.special_situations_item_type_id.legend;
    }
    return "---";
  };

  const columns = [
    {
      field: "category",
      headerName: "Categoria",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.category} placement="top">
          <Typography
            component={'span'}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.category}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "items",
      headerName: "Item",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.items} placement="top">
          <Typography component={'span'}>{row.items}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "observation",
      headerName: "Conteúdo",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={parseHTML(row.observation)} placement="top">
          <Typography
            component={'span'}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <>{parseHTML(row.observation)}</>
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "review",
      headerName: "Revisão",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.review} placement="top">
          <Typography component={'span'}>{row.review}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleOpenForm(row._id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(row._id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let row = [];
  specialSituations &&
    specialSituations.map(
      (
        {
          _id,
          special_situations_item_type_id,
          special_situations_item_id,
          observation,
          antidote_active_principle_id,
          categorie,
          item,
          observation_source,
          icon_content_id,
          log_review_id,
          deleted,
        },
        index
      ) =>
        !deleted &&
        row.push({
          id: index,
          _id,
          special_situations_item_type_id,
          special_situations_item_id,
          antidote_active_principle_id,
          categorie,
          item,
          deleted,
          observation_source,
          icon_content_id,
          observation,
          category:
            special_situations_item_id && special_situations_item_id.title,
          items: verifyItem(specialSituations[index]),
          review: log_review_id
            ? moment(log_review_id.createdAt).format("D/M/YYYY - HH:mm")
            : "---",
        })
    );


  return (
    <AccordionAtom
      id={id}
      accordionTitle="Situações especiais"
      componentName="specialsituation"
      colorTheme="yellow"
      isReviewed={ review?.special_situation_full_review || false }
      reviewDate={
        review?.special_situation_full_review
        ? getReviewCreatedAt(specialSituations)
        : ""
      }
      reviewAuthor={
        review?.special_situation_full_review
        ? getNameAuthor(specialSituations)
        : ""
      }
    >
      <CardHeaderMolecule 
        handleOpenForm={()=> handleOpenForm(null)}
        textAddButton="Adicionar situação especial"
      />
      <Box>
        <DataGridAtom
          pageSize={10}
          arrData={row}
          columns={columns}
          theme="yellow"
        />
      </Box>
      <CardFooterMolecule 
        isEmpty={!row.filter(
          ({ deleted }) => deleted === false
        ).length}
        handleEmptySessionReview={handleEmptySessionReview}
        openEmptyReviewDialog={openEmptyReviewDialog}
        setOpenEmptyReviewDialog={setOpenEmptyReviewDialog}
      />

      {openModal && 
        <SpecialSituationModal
          closeModal={() => {setOpenModal(false); setSpecialSituationId(null)}}
          specialSituationId={specialSituationId}
          setSpecialSituationId={(param) => setSpecialSituationId(param)}
          handleGetSpecialSituationsByActivePrincipleId={handleGetSpecialSituationsByActivePrincipleId}
        />
      }
    </AccordionAtom>
  );
};

export default AccordionSpecialSituation;
