import { makeStyles } from '@material-ui/core/styles';

const useStylesBasicInformationForm = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#424242',
    position: 'relative',
  },

  accordion_summary: {
    position: 'sticky',
    top: 0,
    zIndex: '2',
    backgroundColor: '#545454',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      color: '#fff'
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    }
  },

  accordion_title: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginRight: '1rem'
  },

  accordion_container: {
    width: '100%',
  },

  accordion_content: {
    margin: theme.spacing(1),
    flexGrow: 1,
  },

  accordion_sub_content: {
    display: 'flex',
  },

  accordion_details: {
    backgroundColor: '#bab9b9',
  },

  basic_text_field: {
    display: 'flex',
    padding: 0,
    backgroundColor: '#bab9b9',
    color: '#000',
    fontWeight: '900',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  basic_text_field_grid: {
    display: 'flex',
    padding: 0,
    marginBottom: '10px',
    backgroundColor: '#bab9b9',
    fontWeight: 'bold',
    '& .MuiFormControl-marginNormal': {
      margin: 0
    },
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  checkbox_title: {
    '&.Mui-focused': {
      color: '#606060'
    }
  },

  checkbox_button: {
    '&.Mui-checked': {
      color: '#1661a1'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },

  save_button_box: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  reviewed_box: {
    marginRight: 'auto',
    color: 'lawngreen',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  not_reviewed_box: {
    marginRight: 'auto',
    color: 'darkred',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  
  dialog_style: {
    backgroundColor: '#424242',
    width: '40vw',
    color: '#FFF',
    '& .MuiDialogContentText-root': {
      color: '#FFF'
    }
  },

  saveButton: {
    float: 'right'
  },

  review_tag_style: {
    marginTop: '2.5%',
    color: 'black',
    fontWeight: 'bold'
  }
}));

export default useStylesBasicInformationForm;
