/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import moment from "moment";

import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import RateReviewRoundedIcon from "@material-ui/icons/RateReviewRounded";
import useStylesItemLog from './styles';
import TooltipAtom from "../TooltipAtom";

const ItemLog = ({
  action,
  name,
  createdAt,
  title,
}) => {
  const classes = useStylesItemLog();
  const switchIcon = (param) => {
    const icon = {
      create:
        <TooltipAtom title='Adicionado' placement='left'>
          <Avatar style={{ backgroundColor: 'green' }}>
            <AddCircleRoundedIcon />
          </Avatar>
        </TooltipAtom>,
      update:
        <TooltipAtom title='Atualizado' placement='left'>
          <Avatar style={{ backgroundColor: 'blue' }}>
            <EditRoundedIcon />
          </Avatar>
        </TooltipAtom>,
      delete:
        <TooltipAtom title='Deletado' placement='left'>
          <Avatar style={{ backgroundColor: 'red' }}>
            <DeleteIcon />
          </Avatar>
        </TooltipAtom>,
      review:
        <TooltipAtom title='Revisado' placement='left'>
          <Avatar style={{ backgroundColor: 'purple' }}>
            <RateReviewRoundedIcon />
          </Avatar>
        </TooltipAtom>,
    };
    return icon[param];
  };

  return (
    <>
      {title && (
        <ListItem className={classes.item_log}>
          <ListItemAvatar>
            {switchIcon(action)}
          </ListItemAvatar>
          <ListItemText
            primary={title}
            secondary={`${name}  -  ${moment(createdAt).format("D/M/YYYY - HH:mm")}`}
          />
        </ListItem>
      )}
    </>
  );
};

export default ItemLog;
