/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ContextAplication } from "../../../context";
import BBLoading from "../../molecules/BBLoading";
import AccordionAtom from "../../atoms/AccordionAtom";
import Footer from "../../atoms/ModalFooter";
import BasicInformationForm from "../../molecules/BasicInformationForm";
import { handleSubmitLinks } from "../../../utils/handleSubmitLinks";

const AccordionBasicInformation = ({ id, handleGetReviewById, review }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    handleSubmit,
    handleUpdate,
    shortList,
    shortListRemoved,
    isUpdate,
    activePrincipleModel,
    handleGetActivePrincipleModelById,
    setShortListRemoved,
    isLogTherapeuticGroup,
    setIsLogTherapeuticGroup,
  } = useContext(ContextAplication);

  const [initialDataValues, setInitialDataValues] = useState(activePrincipleModel)

  const hadleChengeTherapeuticGroup = (principleActiveId) => {
    return shortList.map(async ({ _id, active_principles_id }) => {
      let ids = [];
      if (active_principles_id) {
        active_principles_id.map((ap) => (ap ? ids.push(ap) : null));
      }

      await handleUpdate("therapeuticgroup", _id, {
        active_principles_id: [...ids, principleActiveId],
      });
      await handleUpdate(
        "therapeuticgroup/activeprinciple",
        _id,
        {
          activePrinciplesIds: [principleActiveId],
        },
        "add"
      );
    });
  };

  const hadleRemoveTherapeuticGroup = (principleActiveId) => {
    return shortListRemoved.map(
      async ({ _id, title, active_principles_id, free_access }) => {
        let ids = [];
        if (active_principles_id) {
          active_principles_id
            .filter((t) => t !== principleActiveId)
            .map((ap) => (ap ? ids.push(ap) : null));
        }
        await handleUpdate("therapeuticgroup", _id, {
          active_principles_id: ids,
        });
        await handleUpdate(
          "therapeuticgroup/activeprinciple",
          _id,
          {
            activePrinciplesIds: [principleActiveId],
          },
          "removed"
        );
      }
    );
  };

  const assemblesActivePrincipleObjecttoRequest = async () => ({
    title: activePrincipleModel.title,
    specific_observation: activePrincipleModel.specific_observation,
    prescription_icon_id: activePrincipleModel.prescription_icon_id,
    price_1: activePrincipleModel.price_1
      ? activePrincipleModel.price_1.toString()
      : "0.00",
    shot_1: activePrincipleModel.shot_1,
    price_2: activePrincipleModel.price_2
      ? activePrincipleModel.price_2.toString()
      : "0.00",
    shot_2: activePrincipleModel.shot_2,
    price_3: activePrincipleModel.price_3
      ? activePrincipleModel.price_3.toString()
      : "0.00",
    shot_3: activePrincipleModel.shot_3,
    source_observations: activePrincipleModel.source_observations,
    resume_source: activePrincipleModel.resume_source,
    ddd: activePrincipleModel.ddd,
    page: activePrincipleModel.page,
    observations: activePrincipleModel.observations,
    discontinued: activePrincipleModel.discontinued,
    imported: activePrincipleModel.imported,
    manipulated: activePrincipleModel.manipulated,
    publics: activePrincipleModel.publics,
    hidden: activePrincipleModel.hidden,
    whiteboard: activePrincipleModel.whiteboard,
    therapeutic_group_id: activePrincipleModel.therapeutic_group_id,
    review_id: activePrincipleModel.review_id,
  });

  const submit = async (isReview) => {
    const basicInformationObjectRequest =
      await assemblesActivePrincipleObjecttoRequest();
    setLoading(true);
    try {
      if (isUpdate) {


        await handleSubmitLinks(initialDataValues, basicInformationObjectRequest, ['observations', 'whiteboard'], initialDataValues._id)

        const activePrincipleResult = await handleUpdate(
          "activeprinciple",
          activePrincipleModel._id,
          basicInformationObjectRequest,
          "basic_review_log_id"
        );

        if (isLogTherapeuticGroup) {
          await hadleRemoveTherapeuticGroup(activePrincipleModel._id);
          await hadleChengeTherapeuticGroup(activePrincipleModel._id);
        }

        if (isReview) {
          await handleSubmit(
            `review/${activePrincipleResult.review_id}/basic_review_log_id`,
            {}
          );
        }

        setShortListRemoved([]);
        await handleGetActivePrincipleModelById(activePrincipleModel._id);
        await handleGetReviewById(activePrincipleModel.review_id);
        setIsLogTherapeuticGroup(false);
        toast.success("Princípio ativo atualizado com sucesso!");
      } else {
        const result = await handleSubmit(
          "activeprinciple",
          basicInformationObjectRequest
        );

        if (isLogTherapeuticGroup) {
          await hadleRemoveTherapeuticGroup(activePrincipleModel._id);
          await hadleChengeTherapeuticGroup(result._id);
        }

        await handleGetActivePrincipleModelById(result._id);
        toast.success("Princípio ativo criado com sucesso!");
        setShortListRemoved([]);
        setIsLogTherapeuticGroup(false);
        history.push(`/activeprinciple/${result._id}`);
      }
      return setLoading(false);
    } catch (error) {
      toast.error("Ocorreu um erro!");
      return setLoading(false);
    }
  };

  return (
    <AccordionAtom
      id={id}
      accordionTitle="Informações básicas"
      colorTheme="black"
      isReviewed={review?.basic_review_log_id || false}
      reviewDate={review?.basic_review_log_id?.createdAt || ""}
      reviewAuthor={
        review?.basic_review_log_id?.author_id?.name
          ? review.basic_review_log_id.author_id.name
          : ""
      }
    >
      <BasicInformationForm />

      <Footer
        log_review={review?.basic_review_log_id}
        isUpdate={isUpdate}
        submit={submit}
      />
      {loading && <BBLoading isOpen={loading} />}
    </AccordionAtom>
  );
};

export default AccordionBasicInformation;
