/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@material-ui/data-grid";
import bbloadinglist from "../../../assets/animations/bbloadinglist.json";
import { BBLoadingAtom } from "../BBLoadingAtom";

import useStylesDataGridAtom from "./styles";

const DataGridAtom = ({ arrData, columns, pageSize, lottieText, theme, loading }) => {
  const classes = useStylesDataGridAtom();

  const getTheme = (theme) => {
    const objectTheme = {
      dark: classes.data_grid_dark,
      blue: classes.data_grid_blue,
      green: classes.data_grid_green,
      yellow: classes.data_grid_yellow,
      red: classes.data_grid_red,
      purple: classes.data_grid_purple,
      gray: classes.data_grid_gray
    };
    return objectTheme[theme] || objectTheme.dark;
  }

  return (
    <Box className={getTheme(theme)}>
      {loading &&
        <BBLoadingAtom
          loadingJson={bbloadinglist}
          lottieText="Carregando..."
          textMargin={-80}
        />
      }
      { (!arrData?.length && !loading) &&  
        <BBLoadingAtom
          lottieText={lottieText}
          textMargin={-55}
        />
      }
      {(arrData?.length && columns && !loading) ?
        <DataGrid
          autoHeight={theme !== "dark" === true}
          rows={arrData}
          columns={columns}
          pageSize={pageSize}
          disableColumnMenu
          disableSelectionOnClick
        /> : null
      }
    </Box>
  );
};

export default DataGridAtom;
