/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sparse-arrays */
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import CardHeaderMolecule from "../../molecules/CardHeaderMolecule";
import BBLoading from "../../molecules/BBLoading";
import TooltipAtom from "../../atoms/TooltipAtom";
import DataGridAtom from "../../atoms/DataGridAtom";
import PresentationCreateForm from "../../molecules/PresentationForm/PresentationCreateForm";
import PresentationUpdateForm from "../../molecules/PresentationForm/PresentationUpdateForm";

import { ContextAplication } from "../../../context";
import { isRequiredFields } from "../../../utils/isRequiredField";

import useStylesPresentationComponents from "./styles";

const selectStatusPresentation = (value) => {
  const statusMap = {
    AT: "Ativo",
    IN: "Descontinuado",
  };

  return statusMap[value] || "Dúvida";
};

const PresentationDataGrid = ({ getBrand, idBrand }) => {
  const classes = useStylesPresentationComponents();
  const {
    activePrincipleModel,
    pharmaceuticalForm,
    getPharmaceuticalForm,
    getUnit,
    unit,
    handleDelete,
    handleSubmit,
    handleGet,
    handleUpdate,
  } = useContext(ContextAplication);

  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [row, setRow] = useState([]);
  const [presentationId, setPresentationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [brand, setBrand] = useState(null);
  const [presentationModel, setPresentationModel] = useState(null);

  const getBrandToAssemblesPresentationDatagrid = async () => {
    let responseBrand;
    setLoading(true);
    try {
      responseBrand = await handleGet(`brands/${idBrand}`);
      setBrand(responseBrand);
    } catch (error) {
      toast.error("Ocorreu um erro ao listar as marcas!");
    }
    setLoading(false);
    return responseBrand;
  };

  useEffect(() => {
    if (!pharmaceuticalForm || pharmaceuticalForm.length === 0) {
      getPharmaceuticalForm();
    }
    if (!unit || unit.length === 0) {
      getUnit();
    }
    getBrandToAssemblesPresentationDatagrid();
  }, []);

  const ids =
    brand &&
    brand.presentation_id.map((element) => {
      return element._id;
    });

  async function handleGetPresentationsAssemblesTheDatagrigLine() {
    const brandResponse = await getBrandToAssemblesPresentationDatagrid();

    setRow([]);
    let aux = [];

    brandResponse.presentation_id.map(
      (element, index) =>
        !element.deleted &&
        aux.push({
          ...element,
          id: index,
          statusPresentation: selectStatusPresentation(element.market),
          content: element.free_text
            ? `${element.free_text}`
            : `${element.pharmaceutical_form_id?.title}: ${element.volume} ${element.unity_id.title}${
              !!element.amount ? 
                ` - [${element.amount}]` : 
                  ''
            }`,
        })
    );
    return setRow(aux);
  }

  useEffect(() => {
    if (brand?.presentation_id) {
      handleGetPresentationsAssemblesTheDatagrigLine();
    }
  }, [ids?.length]);

  async function handleDeleteButton(presentationId) {
    setLoading(true);
    if (window.confirm("Deseja deletar?")) {
      await handleDelete(
        `presentation/${presentationId}/${brand._id}/${activePrincipleModel._id}`
      );
      await handleGetPresentationsAssemblesTheDatagrigLine();
      toast.success("Apresentação deletada com sucesso!");
    }
    setLoading(false);
  }

  const validateErrors = (validateErrors) => {
    if (Object.keys(validateErrors).length !== 0) {
      return true;
    }
  };

  const verifyValueInputRequired = async (requiredFields, brandData) => {
    const error = await isRequiredFields(requiredFields, brandData);
    setErrors(error);
    return error;
  };

  const assemblesTheObjectForTheRequisition = async (object) => {
    if (object.free_text) {
      return {
        free_text: object.free_text,
        is_default: false,
        market: object.market,
        pharmaceutical_form_id: null,
        volume: "",
        unity_id: null,
        amount: "",
        observation: object.observation,
        source_observation: object.source_observation,
      };
    }
    return {
      pharmaceutical_form_id: object.pharmaceutical_form_id,
      volume: object.volume,
      unity_id: object.unity_id,
      amount: object.amount,
      market: object.market,
      is_default: true,
      free_text: "",
      observation: object.observation,
      source_observation: object.source_observation,
    };
  };

  const optionRequest = async (objectRequest, isCreate) => {
    if (isCreate) {
      const response = await handleSubmit(
        `presentation/${brand._id}/${activePrincipleModel._id}`,
        objectRequest
      );
      return response;
    }
    return handleUpdate(
      `presentation/${presentationId}`,
      idBrand,
      presentationModel,
      activePrincipleModel._id
    );
  };

  const resetFormByData = (response) => {
    setOpenForm(true);
    setOpen(false);
    setPresentationModel(null);
    setPresentationId(response._id);
    setLoading(false);
  };

  const submitCreate = async (object, isCreate) => {
    setLoading(true);
    try {
      const existError = await verifyValueInputRequired(
        object.requiredFields,
        object.presentationModel
      );

      if (validateErrors(existError)) {
        setLoading(false);
        return false;
      }

      const objectRequest = await assemblesTheObjectForTheRequisition(
        object.presentationModel
      );

      const response = await optionRequest(objectRequest, isCreate);

      if (!isCreate) {
        setPresentationId(response?._id);
        setLoading(false);
        return setPresentationModel(response);
      }

      toast.success("Apresentação criada com sucesso!");
      setErrors({});

      await getBrand();

      await handleGetPresentationsAssemblesTheDatagrigLine();
      return resetFormByData(response);
    } catch (error) {
      toast.error("Ocorreu um erro ao criar apresentação!");
      setLoading(false);
    }
  };

  const renderOptionFormPresentation = (optionForm) => {
    if (optionForm) {
      return (
        <PresentationCreateForm
          open={open}
          setOpen={(param) => setOpen(param)}
          submit={(object, isCreate) => submitCreate(object, isCreate)}
          errors={errors}
          setErrors={setErrors}
          presentationModel={presentationModel}
          setPresentationModel={(param) => setPresentationModel(param)}
        />
      );
    }
    return (
      <PresentationUpdateForm
        presentationId={presentationId}
        setPresentationId={(param) => setPresentationId(param)}
        brandId={brand?._id}
        getPresentationRowDatagrid={() =>
          handleGetPresentationsAssemblesTheDatagrigLine()
        }
        getBrand={() => getBrand()}
      />
    );
  };

  const columns = [
    {
      field: "content",
      headerName: "Conteúdo",
      align: "left",
      flex: 0.6,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.content} placement="top">
          <Typography
            component={"span"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.content}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "statusPresentation",
      headerName: "Comercialização",
      align: "center",
      flex: 0.2,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.statusPresentation} placement="top">
          <Typography component={"span"} style={{ textAlign: "center" }}>
            {row.statusPresentation}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.2,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              setPresentationId(row._id);
              setOpenForm(true);
              setOpen(false);
            }}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(row._id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const clickOpenForm = async (param) => {
    setOpen(param);
    setOpenForm(param);
  };

  return (
    <Box>
      <CardHeaderMolecule
        textAddButton="Adicionar apresentação"
        handleOpenForm={() => clickOpenForm(true)}
      />
      <Box>
        <DataGridAtom
          pageSize={10}
          arrData={row}
          columns={columns}
          theme="blue"
        />
      </Box>
      {openForm
        ? open
          ? renderOptionFormPresentation(open)
          : renderOptionFormPresentation()
        : null}
      {loading && <BBLoading isOpen={loading} />}
    </Box>
  );
};

export default PresentationDataGrid;
