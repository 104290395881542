import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import useStylesFormLink from "./styles";

const FormDrugsLink = ({
  closeLinkModal,
  textAndIdLink,
  handleFormChange,
  therapeuticGroup,
  linkManager,
  activePrinciples,
  dosages,
  handleTheRequestToRemoveTheLink,
  handlesLinkCreate,
}) => {
  const classes = useStylesFormLink();

  return (
    <Box className={classes.paper}>
      <Box className={classes.modal_header}>
        <Typography component={"span"} variant="h5">
          Drogas
        </Typography>
        <Button onClick={closeLinkModal}>
          <CloseIcon className={classes.close_icon} />
        </Button>
      </Box>
      <Grid>
        <Grid>
          <Typography component={"span"}>
            Palavra selecionada: {textAndIdLink.text}
          </Typography>
          <FormControl classes={{ root: classes.basic_select_field }}>
            <Autocomplete
              onChange={(_, value) =>
                handleFormChange("therapeutic_group_id", value)
              }
              options={therapeuticGroup}
              getOptionLabel={(option) => option.title}
              defaultValue={linkManager?.therapeutic_group_id || []}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Grupo terapêutico"
                  margin="normal"
                />
              )}
            />
            <Autocomplete
              onChange={(_, value) =>
                handleFormChange("active_principle_id", value)
              }
              options={activePrinciples}
              getOptionLabel={(option) => option.title}
              defaultValue={linkManager?.active_principle_id}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Princípio ativo"
                  margin="normal"
                />
              )}
            />
            <Autocomplete
              onChange={(_, value) => handleFormChange("dosage_id", value)}
              options={dosages}
              getOptionLabel={(option) =>
                `${option?.category_type_id?.title} - ${option?.recommendation}`
              }
              defaultValue={linkManager?.dosage_id}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Indicação"
                  margin="normal"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Box className={classes.boxButton}>
          {linkManager?._id && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.insertLinkButton}
              onClick={handleTheRequestToRemoveTheLink}
            >
              Remover link
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.insertLinkButton}
            onClick={handlesLinkCreate}
          >
            Salvar link
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default FormDrugsLink;
