import styled from 'styled-components';

export const PendingLinksUpdateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  width: 90%;
  background-color: #424242;

  .pendingLink__title {
    font-size: 1.8rem;
    margin-bottom: 5px;
  }

  .activePrinciple__title {
    font-size: 1.6rem;
    margin-bottom: 5px;
  }

  .field__title {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  .save__button {
    align-self: flex-end;
    background-color: #3f51b5;
    color: white;
    border: none;
    padding: 0.5rem;
    margin-top: 10px;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
  `