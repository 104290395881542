/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { toast } from 'react-toastify';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import { ContextAplication } from "../../../context";
import { randomNumber } from "../../../services";

import useStylesListTherapeuticGroup from "./styles";

const ListTherapeuticGroup = () => {
  const classes = useStylesListTherapeuticGroup();

  const {
    shortList,
    setShortList,
    shortListRemoved,
    setShortListRemoved,
    handleChangeActivePrinciple,
    handleGetSearch,
    setIsLogTherapeuticGroup,
  } = useContext(ContextAplication);

  const [input, setInput] = useState("");
  const [dataTherapeuticGroup, setDataTherapeuticGroup] = useState([]);

  useEffect(() => {
    setShortListRemoved([]);
  }, []);

  const handleGetTherapeuticGroup = async () => {
    if (input.length < 3) {
      setDataTherapeuticGroup([]);
      return false;
    }
    try {
      const result = await handleGetSearch("therapeuticgroup", input);
      setDataTherapeuticGroup(result);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar grupo terapêutico!');
    }
  };

  useEffect(() => {
    if (input.length > 3) {
      handleGetTherapeuticGroup();
    }
  }, [input]);

  let timeout = null;
  const debounceEventHandle = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setInput(value);
    }, 800);
  };

  const handleFilterGTInActivePrincipleModel = () =>{
    const ids = [];

    shortList.filter(
        (ele, idx, arr) => idx === arr.findIndex((t) => t._id === ele._id)
      ).map((element) =>
      ids.push(element._id)
    );

    return ids;
  }

  const handleChange = (ele, name) => {
    setShortList((state) => [...state, ele]);

    const ids = handleFilterGTInActivePrincipleModel();

    ids.push(ele._id);
    handleChangeActivePrinciple(name, ids);
    setIsLogTherapeuticGroup(true);
  };
  
  const removeItemsShortList = (removed, _id, field) => {
    const gt = shortList.filter((e) => e._id === _id);
    setShortListRemoved([...shortListRemoved, ...gt]);
    setShortList((state) => state.filter((e) => e.title !== removed));
    const ids = handleFilterGTInActivePrincipleModel();
    handleChangeActivePrinciple(field, ids.filter((e) => e !== _id));
    setIsLogTherapeuticGroup(true);
  };

  const displayShortList = (array) =>
    array
      .filter(
        (ele, idx, arr) => idx === arr.findIndex((t) => t._id === ele._id)
      )
      .map(({ title, _id }) => (
        <Chip
          key={randomNumber()}
          classes={{ root: classes.chip_style }}
          label={title}
          onDelete={() =>
            removeItemsShortList(title, _id, "therapeutic_group_id")
          }
        />
      ));

  const list = (data) => (
    <Box classes={{ root: classes.therapeutic_group_list }}>
      {data
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(
          ({ title, deleted, _id, free_access, active_principles_id }) =>
            !deleted && (
              <Button
                className={classes.buttonList}
                key={randomNumber()}
                align="right"
                onClick={() =>
                  handleChange(
                    { title, _id, free_access, active_principles_id },
                    "therapeutic_group_id"
                  )
                }
              >
                <Typography component={'span'} style={{ textAlign: "left" }}>{title}</Typography>
              </Button>
            )
        )}
    </Box>
  );

  return (
    <Box>
      <Box className={classes.accordion_complete}>
        <Box
          aria-controls="panel1c-content"
          id="panel1c-header"
          classes={{ root: classes.accordion_summary }}
        >
          <Box style={{ margin: "2vh" }}>
            <Typography component={'span'}>Grupos Terapêuticos</Typography>
          </Box>
        </Box>
        <Box className={classes.accordion_details}>
          <Box>
            <TextField
              autoComplete="off"
              classes={{ root: classes.search_field }}
              id="outlined-basic"
              label="Buscar"
              variant="outlined"
              helperText="Digite pelo menos 3 caracteres para iniciar a busca..."
              onChange={({ target }) => debounceEventHandle(target.value)}
            />
          </Box>

          <Grid container spacing={3}>
            <Grid item={true} xs={6}>
              <Typography component={'span'} className={classes.title_accordion}>
                Lista de grupos terapêuticos:
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography component={'span'} className={classes.title_accordion}>
                Grupos terapêuticos selecionados:
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item={true} xs={6}>
              <Box className={classes.list_style}>
                <br />
                {dataTherapeuticGroup &&
                  list(dataTherapeuticGroup)}
              </Box>
            </Grid>
            <Grid item={true} xs={6}>
              <Box className={classes.list_selected_style}>
                <br />
                {shortList && displayShortList(shortList)}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};

export default ListTherapeuticGroup;
