import { makeStyles } from '@material-ui/core/styles';

const useStylesMenuOrganism = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default useStylesMenuOrganism;
