/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ContextAplication } from "../../../context";
import EditorHTML from "../../atoms/EditorHTML";

import useStylesUseAndSafetyUpdateForm from "./styles";

function UseAndSafetyForm({
  isUpdate,
  handleChange,
  useAndSafetyData,
  setUseAndSafetyData,
  errors,
}) {
  const classes = useStylesUseAndSafetyUpdateForm();

  const { useAndSafetyCategory, getUseAndSafetyCategory } =
    useContext(ContextAplication);

  const verifyId = (value) => {
    if (value) return value._id;
    return null;
  };

  useEffect(() => {
    getUseAndSafetyCategory();
  }, []);

  return (
    <>
      <Box>
        <FormControl classes={{ root: classes.basic_select_field }}>
          <Autocomplete
            onChange={(_, value) =>
              handleChange("category_type_id", verifyId(value))
            }
            options={useAndSafetyCategory ? useAndSafetyCategory : []}
            getOptionLabel={(option) => option.title}
            defaultValue={
              useAndSafetyData.category_type_id
                ? useAndSafetyData.category_type_id
                : ""
            }
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Categoria"
                margin="normal"
                error={errors.category_type_id ? true : false}
                helperText={
                  errors.category_type_id ? errors.category_type_id : false
                }
              />
            )}
          />
        </FormControl>
      </Box>

      {isUpdate &&
        <>
          <EditorHTML
            initialValue={useAndSafetyData?.content}
            field={"content"}
            handleChangeEditorHTML={(key, value) => handleChange(key, value)}
            title={"Conteúdo"}
            isLink={true}
            objectData={useAndSafetyData}
            setObjectData={setUseAndSafetyData}
            objectRoute="useandsafety"
            isDebounceNeeded={false}
          />

          <EditorHTML
            heightFieldEditor="15vh"
            initialValue={useAndSafetyData?.content_source}
            field={"content_source"}
            objectData={useAndSafetyData}
            handleChangeEditorHTML={(key, value) => handleChange(key, value)}
            title={"Fontes do conteúdo"}
            isDebounceNeeded={false}
          />
        </>
      }
    </>
  );
}

export default UseAndSafetyForm;
