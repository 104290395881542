import JwtDecode from './jwtDecode';

export const TOKEN_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9';
export const isAuthenticated = () => {
  const token = JwtDecode();
  if (!token) {
    return false;
  }
  var dateNow = new Date();
  if (token.exp < dateNow.getTime() / 1000) {
    return false;
  }
  return true;
};
export const getToken = () => localStorage.getItem(TOKEN_KEY) || null;
export const login = (token) => localStorage.setItem(TOKEN_KEY, token);
export const logout = () => localStorage.removeItem(TOKEN_KEY);
export const randomNumber = () => Math.random() * 16;
export const setModelLocalStorage = (name, model) =>
  localStorage.setItem(name, JSON.stringify(model));
export const getModel = (model) => JSON.parse(localStorage.getItem(model));
export const clearLocalStorage = () => localStorage.clear();
export const removeModelLocalStorage = (model) => localStorage.removeItem(model);
export const isManipulatedClassification = (valueId) => {
  if (
    valueId === "5fd0d96275482707b2fb53cb" ||
    valueId === "5fd0d95f75482707b2fb53c3" ||
    valueId === "5fd0d96075482707b2fb53c4" ||
    valueId === "5fd0d96075482707b2fb53c5" ||
    valueId === "5fd0d96075482707b2fb53c6" ||
    valueId === "5fd0d96175482707b2fb53c7" ||
    valueId === "5fd0d96175482707b2fb53c8"
    ) {
    return true;
  }
  return false;
}