/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { toast } from 'react-toastify';
import { ContextAplication } from "../../../context";
import ModalAtom from "../../atoms/ModalAtom";
import UnSaveDialogAtom from "../../atoms/UnSaveDialogAtom";
import BBLoading from "../../molecules/BBLoading";
import { isRequiredFields } from "../../../utils/isRequiredField";
import FormUpdateBrand from "../../molecules/BrandUpdateForm/FormUpdateBrand";
import FormCreateBrand from "../../molecules/BrandUpdateForm/FormCreateBrand";

const BrandModal = ({
  closeModal,
  idBrand,
  handleGetBrandByIdOfActivePrinciple,
}) => {
  const {
    handleGet,
    isManipulatedClassification,
    handleSubmit,
    activePrincipleModel,
    handleUpdate,
    getClassification,
  } = useContext(ContextAplication);
  const [brandData, setBrandData] = useState(null);
  const [errors, setErrors] = useState({});
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const requiredFields = ["classification_id", "title"];
  const [hideFields, setHideFields] = useState(
    isManipulatedClassification(
      brandData?.classification_id ? brandData.classification_id._id : ""
    )
  );

  const getBrand = async () => {
    let responseBrand
    setLoading(true);
    try {
      responseBrand = await handleGet(`brands/${idBrand}`);
    } catch (error) {
      toast.error('Ocorreu um erro ao listar as marcas!');
    }
    setLoading(false);
    setBrandData(responseBrand);
    return responseBrand;
  };

  useEffect(() => {
    if (idBrand) {
      getBrand();
    }
    getClassification();
  }, []);

  const validateErrors = (validateErrors) => {
    if (Object.keys(validateErrors).length !== 0) {
      return true;
    }
  };

  const verifyValueInputRequired = async (requiredFields, brandData) => {
    const error = await isRequiredFields(requiredFields, brandData);
    setErrors(error);
    return error;
  };

  const handleSubmitCreate = async () => {
    setLoading(true);
    try {
      const existError = await verifyValueInputRequired(requiredFields, brandData);

      if (validateErrors(existError)) {
        setLoading(false);
        return false;
      }

      if (hideFields) {
        await handleSubmit(`brands/${activePrincipleModel._id}`, {
          title: brandData.title,
          classification_id: brandData.classification_id,
          status: "DE",
        });
      } else {
        await handleSubmit(`brands/${activePrincipleModel._id}`, brandData);
      }
      toast.success('Marca criada com sucesso!');

      setBrandData(null);
      await handleGetBrandByIdOfActivePrinciple();
    } catch (error) {
      toast.error('Ocorreu um erro ao criar a marca!');
    }
    setHideFields(false);
    setLoading(false);
    closeModal(false);
  };

  const handleSubmitUpdate = async (isReview) => {
    setLoading(true);
    try {
      const existError = await verifyValueInputRequired(requiredFields, brandData);

      if (validateErrors(existError)) {
        setLoading(false);
        return false;
      }

      if (hideFields) {
        await handleUpdate(
          "brands",
          brandData._id,
          {
            ...brandData,
            laboratory_id: null,
          },
          activePrincipleModel._id
        );
      } else {
        await handleUpdate(
          "brands",
          brandData._id,
          brandData,
          activePrincipleModel._id
        );
      }
      if (isReview) {
        await handleUpdate(
          "brands/review",
          brandData._id,
          {},
          activePrincipleModel._id
        );
      }
      toast.success('Marca atualizada com sucesso!');

    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar a marca!');
    }
    await handleGetBrandByIdOfActivePrinciple();
    closeModal(false);
    setLoading(false);
  };

  const inputErrorValidateRequired = (key) => {
    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
  };

  const handleChange = (key, value) => {
    inputErrorValidateRequired(key);

    setBrandData((state) => ({ ...state, [key]: value }));

    if (key === "classification_id") {
      setHideFields(isManipulatedClassification(value));
    }
  };

  const renderOptionFormBrand = (idBrand) => {
    if (idBrand) {
      return (
        <FormUpdateBrand
          handleChange={(key, value) => handleChange(key, value)}
          brandData={brandData}
          errors={errors}
          idBrand={idBrand}
          hideFields={hideFields}
          getBrand={() => getBrand()}
        />
      );
    }
    return (
      <FormCreateBrand
        handleChange={(key, value) => handleChange(key, value)}
        errors={errors}
        hideFields={hideFields}
      />
    );
  };

  const closeModalUpdateListBrands = () => {
    closeModal(false);
    setBrandData(null);
    return handleGetBrandByIdOfActivePrinciple()
  }

  return (
    <>
      <ModalAtom
        modalTheme="blue"
        onClose={() => closeModalUpdateListBrands()}
        title={idBrand ? "Atualizar marca" : "Adicionar marca"}
        log_review={brandData?.log_review_id}
        isUpdate={idBrand}
        submit={idBrand ? handleSubmitUpdate : handleSubmitCreate}
        optionLink={false}
      >
        {brandData ? renderOptionFormBrand(idBrand) : renderOptionFormBrand()}
      </ModalAtom>

      {openUpdateDialog && (
        <UnSaveDialogAtom
          openUpdateDialog={openUpdateDialog}
          setOpenUpdateDialog={(param) => setOpenUpdateDialog(param)}
          unSaveAction={() => {
            closeModal();
            setBrandData(null);
          }}
        />
      )}
      {loading && <BBLoading isOpen={loading} />}
    </>
  );
};

export default BrandModal;
