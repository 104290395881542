/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

import moment from "moment";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import api from "../../../services/axiosApi";
import { ContextAplication } from "../../../context";

import AccordionAtom from "../../atoms/AccordionAtom";
import DataGridAtom from "../../atoms/DataGridAtom";
import TooltipAtom from "../../atoms/TooltipAtom";
import CardHeaderMolecule from "../../molecules/CardHeaderMolecule";
import CardFooterMolecule from "../../molecules/CardFooterMolecule";

import useStylesAdverseEffectsAccordion from "./styles";
import AdverseEffectsModal from "../AdverseEffectsModal";
import { getNameAuthor, getReviewCreatedAt } from "../../../utils/reviewFunctions";

const AccordionAdverseEffects = ({ id, handleGetReviewById, review }) => {
  const [adverseEffects, setAdverseEffects] = useState([]);
  const [adverseEffectsId, setAdverseEffectsId] = useState(null);
  const [openEmptyReviewDialog, setOpenEmptyReviewDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { activePrincipleModel } = useContext(ContextAplication);

  const classes = useStylesAdverseEffectsAccordion();

  function parseHTML(html) {
    var t = document.createElement("template");
    t.innerHTML = html;
    return t.content.cloneNode(true);
  }

  const handleGetAdverseEfecctssByActivePrincipleId = async (isFirstRendering = false) => {
    try {
      const response = await api.get(
        `adverse/by-active-principle/${activePrincipleModel._id}`
      );
      if(!isFirstRendering) {
        await handleGetReviewById();
      }
      return setAdverseEffects(response.data);
    } catch (error) {
      toast.error("Ocorreu um erro ao buscar efeitos colaterais!");
    }
  };

  useEffect(() => {
    handleGetAdverseEfecctssByActivePrincipleId(true);
  }, []);

  async function handleDeleteButton(adverseId, adverseTitle) {
    try {
      if (window.confirm(`Deseja deletar ${adverseTitle}?`)) {
        await api.delete(`adverse/${adverseId}/${activePrincipleModel._id}`);
        await handleGetAdverseEfecctssByActivePrincipleId();
        await handleGetReviewById();
        toast.success("Efeito colateral deletado com sucesso!");
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar o efeito colateral!");
    }
  }

  const handleEmptySessionReview = async () => {
    try {
      await api.post(
        `review/${activePrincipleModel.review_id}/adverse_effects_full_review`,
        {}
      );
      await handleGetAdverseEfecctssByActivePrincipleId();
      await handleGetReviewById();
      toast.success("Efeito colateral foi revisado como vazio.");
    } catch (error) {
      toast.error("Ocorreu um erro ao revisar como vazio!");
    }

    setOpenEmptyReviewDialog(false);
  };

  const handleOpenForm = (id) => {
    setOpenModal(true);
    setAdverseEffectsId(id);
  };

  const columns = [
    {
      field: "category",
      headerName: "Categoria",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.category} placement="top">
          <Typography
            component={"span"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.category}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "observation",
      headerName: "Conteúdo",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.observation} placement="top">
          <Typography
            component={"span"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.observation}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "review",
      headerName: "Revisão",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.review} placement="top">
          <Typography component={"span"}>{row.review}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleOpenForm(row._id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(row._id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let row = [];
  adverseEffects.length &&
    adverseEffects.map(
      (
        {
          _id,
          title,
          deleted,
          content,
          content_source,
          category_type_id,
          log_review_id,
        },
        index
      ) =>
        !deleted &&
        row.push({
          id: index,
          _id,
          title,
          content,
          content_source,
          category_type_id,
          category: category_type_id && category_type_id.title,
          observation: parseHTML(content).textContent,
          review: log_review_id
            ? moment(log_review_id.createdAt).format("D/M/YYYY - HH:mm")
            : "---",
        })
    );

  return (
    <AccordionAtom
      id={id}
      accordionTitle="Efeitos colaterais"
      componentName="adverseeffects"
      colorTheme="red"
      isReviewed={review?.adverse_effects_full_review || false}
      reviewDate={
        review?.adverse_effects_full_review
          ? getReviewCreatedAt(adverseEffects)
          : ""
      }
      reviewAuthor={
        review?.adverse_effects_full_review ? getNameAuthor(adverseEffects) : ""
      }
    >
      <CardHeaderMolecule
        handleOpenForm={() => handleOpenForm(null)}
        textAddButton="Adicionar efeito colateral"
      />
      <Box>
        <DataGridAtom
          pageSize={10}
          arrData={row}
          columns={columns}
          theme="red"
        />
      </Box>
      <CardFooterMolecule
        isEmpty={!row.length}
        handleEmptySessionReview={handleEmptySessionReview}
        openEmptyReviewDialog={openEmptyReviewDialog}
        setOpenEmptyReviewDialog={setOpenEmptyReviewDialog}
      />

      {openModal && (
        <AdverseEffectsModal
          closeModal={() => {
            setOpenModal(false);
            setAdverseEffectsId(null);
          }}
          idAdverseEffect={adverseEffectsId}
          setAdverseEffectsId={(param) => setAdverseEffectsId(param)}
          handleGetAdverseEffectsByActivePrincipleId={
            handleGetAdverseEfecctssByActivePrincipleId
          }
        />
      )}
    </AccordionAtom>
  );
};

export default AccordionAdverseEffects;
