import { uploadBytesResumable, ref } from '@firebase/storage';
import { toast } from 'react-toastify';
import { useStorage } from 'reactfire';

import routineApi from '../../../../services/routineApi'

export default function UploadImageContainer({
  image,
  setImage,
  initialImage,
}) {
  const storage = useStorage();

  async function uploadImage(img) {
    try {
      const parsedImageName = img.name
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z.])/g, '');

      const newRef = ref(
        storage,
        `images/${parsedImageName}`
      );


      await routineApi.put('/image/update-by-name', {imageName: parsedImageName})
      uploadBytesResumable(newRef, img);

      setImage(`images/${parsedImageName}`)

    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
  }

  return (
    <>
      <input
        className="input__image"
        type="file"
        accept="image/*"
        onChange={
          (e) => uploadImage(e.target.files[0])
        }
      />
      <img
        loading="lazy"
        src={`https://firebasestorage.googleapis.com/v0/b/${
          process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
        }/o/${image?.replace('images/', 'images%2F')}?alt=media`}
        alt={image}
      />
    </>
  );
}
