/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";

import ButtonAtom from "../../atoms/ButtonAtom";

import useStylesHeaderPageMolecule from "./style";

const HeaderPageMolecule = ({
  titlePage,
  onClickAddButton,
  onChangeSearch,
  helperText,
}) => {
  const classes = useStylesHeaderPageMolecule();

  return (
    <Box className={classes.box}>
      <Box className={classes.box_header}>
        <Typography component={"span"} variant="h4">
          {titlePage}
        </Typography>
        {onClickAddButton && (
          <ButtonAtom
            textButton="Adicionar"
            icon={<AddIcon />}
            onClick={() => onClickAddButton()}
          />
        )}
      </Box>
      <Box className={classes.box_text_field}>
        <TextField
          autoComplete="off"
          classes={{ root: classes.basic_text_field }}
          label="Buscar"
          helperText={helperText}
          variant="outlined"
          onChange={({ target }) => onChangeSearch(target.value)}
        />
      </Box>
    </Box>
  );
};

export default HeaderPageMolecule;
