import jwtDecode from 'jwt-decode';
import { getToken, TOKEN_KEY } from './index';

const JwtDecode = () => {
  let token = getToken(TOKEN_KEY);
  if(!token){
    return false;
  }
  token = jwtDecode(token);
  return token;
};

export default JwtDecode;
