import { makeStyles } from '@material-ui/core/styles';

const avatarColor = (((1 << 24) * Math.random()) | 0).toString(16);
const drawerWidth = 240;

const useStylesAppBarMolecule = makeStyles((theme) => ({
  image: {
    height: '20px',
    width: 'auto',
  },
  icon: {
    color: "#fff"
  },
  iconLogout: {
    color: "#fff",
    fontSize: "26px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: '#fff',
    backgroundColor: "#212121"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    color: '#fff',
    backgroundColor: "#212121"
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#424242",
    color: "#fff",
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  avatar_style: {
    backgroundColor: `#${avatarColor}`,
    color: theme.palette.getContrastText(`#${avatarColor}`),
    fontWeight: 'bold',
    width: '26px',
    height: '26px',
    fontSize: 'small',
  },

}));

export default useStylesAppBarMolecule;
