import { makeStyles } from '@material-ui/core/styles';

const useStylesHistoryLog = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },

  paper: {
    width: '400px',
    height: 'auto',
    color: '#fff',
    backgroundColor: '#545454',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: '10px',
  },

  modal_header: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-root': {
      minWidth: 0,
    },
    '& .MuiTypography-h5': {
      fontWeight: 'bold',
    }
  },

  close_icon: {
    color: '#fff',
    minWidth: 0,
  },

  history_log_button: {
    cursor: 'pointer',
    opacity: '1',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '0.8'
    }
  }

}));

export default useStylesHistoryLog;