export const active_principle = {
  title: '',
  specific_observation: '',
  prescription_id: null,
  price_1: '',
  shot_1: '',
  price_2: '',
  shot_2: '',
  price_3: '',
  shot_3: '',
  ddd: '',
  page: '',
  observations: '',
  source_observations: '',
  resume_source: '',
  contraindications: '',
  contraindications_source: '',
  discontinued: false,
  imported: false,
  manipulated: false,
  publics: false,
  hidden: false,
  whiteboard: '',
  brands_id: [],
  shots_id: [],
  special_situations_id: [],
  side_effects_id: [],
  uses_security_id: [],
  therapeutic_group_id: [],
  review_id: '',
};
