/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { toast } from 'react-toastify';
import moment from "moment";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import api from "../../../services/axiosApi";
import { ContextAplication } from "../../../context";

import DosageModal from "../DosageModal";
import AccordionAtom from "../../atoms/AccordionAtom";
import DataGridAtom from "../../atoms/DataGridAtom";
import TooltipAtom from "../../atoms/TooltipAtom";
import CardHeaderMolecule from "../../molecules/CardHeaderMolecule";
import CardFooterMolecule from "../../molecules/CardFooterMolecule";

import useStylesDosageComponents from "./styles";
import { getNameAuthor, getReviewCreatedAt } from "../../../utils/reviewFunctions";

const AccordionDosage = ({ id, handleGetReviewById, review }) => {
  const [dosages, setDosages] = useState([]);
  const [dosageId, setDosageId] = useState(null);
  const [openEmptyReviewDialog, setOpenEmptyReviewDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {
    activePrincipleModel,
  } = useContext(ContextAplication);

  const classes = useStylesDosageComponents();

  const handleGetDosagesByActivePrincipleId = async (isFirstRendering = false) => {
    try {
      const response = await api.get(`dosage/by-active-principle/${activePrincipleModel._id}`);
      if(!isFirstRendering) {
        await handleGetReviewById()
      }
      return setDosages(response.data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar doses!');
    }
  };

  useEffect(() => {
    handleGetDosagesByActivePrincipleId(true);
  }, []);

  async function handleDeleteButton(dosageId, dosageTitle) {
    try {
      if (window.confirm(`Deseja deletar ${dosageTitle}?`)) {
        await api.delete(`dosage/${dosageId}/${activePrincipleModel._id}`);
        await handleGetDosagesByActivePrincipleId();
        await handleGetReviewById();
        toast.success('Dose deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao deletar a dose!');
    }
  }

  const handleEmptySessionReview = async () => {
    try {
      await api.post(`review/${activePrincipleModel.review_id}/dosage_full_review`, {});
      await handleGetDosagesByActivePrincipleId();
      await handleGetReviewById();
      toast.success('Dose revisada como vazia.');
    } catch (error) {
      toast.error('Ocorreu um erro ao revisar como vazio!');
    }

    setOpenEmptyReviewDialog(false);
  };

  const handleOpenForm = (id) => {
    setOpenModal(true);
    setDosageId(id);
  }

  const columns = [
    {
      field: "category",
      headerName: "Grupo",
      align: "left",
      flex: 0.2,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.category} placement="top">
          <Typography component={'span'}>{row.category}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "recommendation",
      headerName: "Indicação",
      align: "left",
      flex: 0.2,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.recommendation} placement="top">
          <Typography
            component={'span'}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: row.is_usual ? "bold" : "regular"
            }}
          >
            {row.recommendation}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "extra_content_type",
      headerName: "Conteúdo Extra",
      align: "left",
      flex: 0.1,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.extra_content_type} placement="top">
          <Typography component={'span'}>{row.extra_content_type}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "show_in_search",
      headerName: "Busca",
      align: "center",
      flex: 0.1,
    },
    {
      field: "review",
      headerName: "Revisão",
      align: "center",
      flex: 0.2,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.review} placement="top">
          <Typography component={'span'}>{row.review}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.2,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="update"
            color="primary"
            onClick={() => handleOpenForm(row._id)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(row._id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let row = [];
  dosages.length &&
    dosages
      .sort((a, b) => a.recommendation.localeCompare(b.recommendation))
      .sort((a, b) => a.category_type_id.title.localeCompare(b.category_type_id.title))
      .map(
        (
          {
            _id,
            recommendation,
            category_type_id,
            content,
            content_source,
            extra_contents_id,
            keywords_id,
            show_in_search,
            deleted,
            log_review_id,
            is_usual
          },
          index
        ) =>
          !deleted &&
          row.push({
            id: index,
            _id,
            deleted,
            recommendation,
            category_type_id,
            content,
            content_source,
            extra_contents_id,
            keywords_id,
            category: category_type_id.title,
            extra_content_type: extra_contents_id.title,
            show_in_search: show_in_search ? "Sim" : "Não",
            is_usual,
            review: log_review_id
              ? moment(log_review_id.createdAt).format("D/M/YYYY - HH:mm")
              : "---",
          })
      );

  return (
    <AccordionAtom
      id={id}
      accordionTitle="Doses e indicações"
      componentName="dosage"
      colorTheme="green"
      isReviewed={review?.dosage_full_review || false}
      reviewDate={
        review?.dosage_full_review
          ? getReviewCreatedAt(dosages)
          : ""
      }
      reviewAuthor={
        review?.dosage_full_review
          ? getNameAuthor(dosages)
          : ""
      }
    >
      <CardHeaderMolecule
        handleOpenForm={() => handleOpenForm(null)}
        isLog="dosage"
        textAddButton="Adicionar dose"
      />
      <Box>
        <DataGridAtom
          pageSize={10}
          arrData={row}
          columns={columns}
          theme="green"
        />
      </Box>
      <CardFooterMolecule
        isEmpty={!row.filter(
          ({ deleted }) => deleted === false
        ).length}
        handleEmptySessionReview={handleEmptySessionReview}
        openEmptyReviewDialog={openEmptyReviewDialog}
        setOpenEmptyReviewDialog={setOpenEmptyReviewDialog}
      />

      {openModal &&
        <DosageModal
          dosages={dosages}
          closeModal={() => { setOpenModal(false); setDosageId(null) }}
          idDosage={dosageId}
          setIdDosage={(param) => setDosageId(param)}
          handleGetDosagesByActivePrincipleId={handleGetDosagesByActivePrincipleId}
          handleGetReviewById={() => handleGetReviewById()}
        />
      }

    </AccordionAtom>
  );
};

export default AccordionDosage;
