import React, { useContext } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import useStylesAccordions from "./style";
import Historylog from "../HistoryLog";
import { ContextAplication } from "../../../context";

const CardHeaderMolecule = ({ handleOpenForm, isLog, textAddButton }) => {
  const classes = useStylesAccordions();
  const { userRole } = useContext(ContextAplication);

  return (
    <Box className={classes.display_header}>
      <Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenForm()}
        >
          {textAddButton}
        </Button>
      </Box>
      {isLog && userRole !== "editor" && <Historylog field={isLog} />}
    </Box>
  );
};

export default CardHeaderMolecule;
