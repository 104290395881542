/* eslint-disable default-case */
/* eslint-disable array-callback-return */
import React, { useContext } from "react";

import moment from "moment";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ContextAplication } from "../../../context";
import TooltipAtom from "../../atoms/TooltipAtom";

import { OnClickEditLink } from "../../molecules/OnClickEditLink";
import AccordionAtom from "../../atoms/AccordionAtom";
import LinkDatagridMolecule from "../../molecules/LinkDatagridMolecule";

const AccordionLinks = ({ id }) => {
  const { activePrincipleModel } = useContext(ContextAplication);

  const getOriginLink = (field) => {
    switch (field) {
      case "dosage":
        return "Doses e indicações";
      case "contraindications":
        return "Contraindicações";
      case "basic_information":
        return "Informações básicas";
      case "adverse":
        return "Efeitos Colaterais";
      case "useandsafety":
        return "Uso e segurança";
      case "specialsituation":
        return "Situação especial";
      case "presentation":
        return "Marcas e apresentações";
    }
  };

  const columns = [
    {
      field: "link_origin_text",
      headerName: "Palavra",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.link_origin_text} placement="top">
          <Typography component={"span"}>{row.link_origin_text}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "origin",
      headerName: "Origem",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.origin} placement="top">
          <Typography component={"span"}>{row.origin}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "destiny",
      headerName: "Destino",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.destiny} placement="top">
          <Typography component={"span"}>{row.destiny}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em:",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.updatedAt} placement="top">
          <Typography component={"span"}>{row.updatedAt}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box>
          <OnClickEditLink objLink={row} />
        </Box>
      ),
    },
  ];

  let doneLinks = [];
  let pendingLinks = [];
  activePrincipleModel.link_manager_id &&
    activePrincipleModel.link_manager_id.map(
      (
        {
          _id,
          field_origin,
          link_origin_id,
          link_origin_text,
          link_id,
          routine_id,
          tab_id,
          card_id,
          active_principle_id,
          therapeutic_group_id,
          dosage_id,
          type,
          updatedAt,
        },
        index
      ) => {
        if (therapeutic_group_id || routine_id) {
          doneLinks.push({
            id: index,
            _id,
            field_origin,
            link_origin_id,
            link_origin_text,
            origin: getOriginLink(field_origin),
            destiny: therapeutic_group_id
              ? `${therapeutic_group_id.title} / 
                        ${
                          active_principle_id ? active_principle_id.title : ""
                        } / 
                        ${dosage_id ? dosage_id.recommendation : ""}`
              : "" || routine_id
              ? `${routine_id.title} / 
                        ${tab_id ? tab_id.title : ""} / 
                        ${card_id ? card_id.title : ""}`
              : "",
            link_id,
            routine_id,
            tab_id,
            card_id,
            active_principle_id,
            therapeutic_group_id,
            dosage_id,
            type,
            updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
          });
        } else {
          pendingLinks.push({
            id: index,
            _id,
            field_origin,
            link_origin_id,
            link_origin_text,
            origin: getOriginLink(field_origin),
            destiny: "Pendente",
            link_id,
            routine_id,
            tab_id,
            card_id,
            active_principle_id,
            therapeutic_group_id,
            dosage_id,
            type,
            updatedAt: moment(updatedAt).format("D/M/YYYY - HH:mm"),
          });
        }
      }
    );

  return (
    <AccordionAtom
      id={id}
      accordionTitle="Links"
      componentName="links"
      colorTheme="black"
    >
      {activePrincipleModel.link_manager_id && (
        <LinkDatagridMolecule
          columns={columns}
          doneLinks={doneLinks}
          pendingLinks={pendingLinks}
        />
      )}
    </AccordionAtom>
  );
};

export default AccordionLinks;
