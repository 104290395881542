import { makeStyles } from '@material-ui/core/styles';

const useStylesContentPageOrganism = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },

  content: {
    height: '60vh',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: '#424242',
  },
}));

export default useStylesContentPageOrganism;
