import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStylesSideBarMolecule = makeStyles((theme) => ({
  image: {
    height: '20px',
    width: 'auto',
  },
  icon: {
    color: "#fff"
  },
  listItem: {
    "&.Mui-selected" : {
      borderRight: "solid #3f51b5 4px",
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#424242",
    color: "#fff",
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "#424242",
    color: "#fff",
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  container_logo: {
    width: '12vw',
    display: 'flex',
    alignItems: 'center',
    margin: '1.25rem',
    justifyContent: 'space-around',
  },
}));

export default useStylesSideBarMolecule;
