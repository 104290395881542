import React, { useContext } from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from "@material-ui/icons/Close";
import useStylesDosageUpdateForm from "./styles";
import { ContextAplication } from "../../../context";

export default function DosageUpdateForm({
  onClose,
  title
}) {
  const {
    activePrincipleModel
  } = useContext(ContextAplication);
  const classes = useStylesDosageUpdateForm();

  return (
    <>
      <div className={classes.modal_title}>
        <h3>
          Princípio Ativo:{" "} {activePrincipleModel ? activePrincipleModel.title : ""}
        </h3>
        <Button onClick={() => onClose()}>
          <CloseIcon className={classes.close_icon} />
        </Button>
      </div>
      <Typography component={'span'} variant="h5">{title}</Typography>
    </>
  );
}
