import React, { useContext } from "react";
import clsx from "clsx";

import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";

import { ContextAplication } from "../../../context";
import useStylesAppBarMolecule from "./styles";

export default function AppBarMolecule({
  open,
  handleDrawerOpen,
  handleLogout,
  logo,
}) {
  const classes = useStylesAppBarMolecule();
  const { getModel } = useContext(ContextAplication);

  const loggedInUser = getModel("loggedinuser");

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <img
            src={logo}
            alt="Imagem logo BlackBook"
            className={classes.image}
          />
        </IconButton>
        <div className={classes.content}>
          <Avatar variant="rounded" className={classes.avatar_style}>
            {loggedInUser[0].toUpperCase()}
          </Avatar>
          <IconButton onClick={handleLogout}>
            <ExitToAppIcon className={classes.iconLogout} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
