import { makeStyles } from '@material-ui/core/styles';

const useStylesUseAndSafetyComponents = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '5vh',
    width: '60vw',
    borderBottom: '0.5px solid dimgray',
  },

  close_icon: {
    color: '#fff',
    minWidth: 0,
  },

  dialog_style: {
    background: '#424242',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    '& .MuiTypography-colorTextSecondary': {
      color: '#fff',
    },
  },

  dialog_action: {
    fontSize: '1rem',
    fontWeight: '700',
  },

  header_content: {
    width: '10vw',
  },

  icons_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  use_and_safety_header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
  },

  modal_header: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },

  paper: {
    margin: '6vh 20vh 20vh 20vh',
    position: 'absolute',
    width: '80%',
    height: '90%',
    color: '#fff',
    backgroundColor: '#A089B2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#695E80',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#A089B2',
    },

  },

  fields_box: {
    display: 'flex',
  },

  basic_select_field: {
    display: 'flex',
    padding: 0,
    marginBottom: '10px',
    backgroundColor: '#A089B2',
    fontWeight: 'bold',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  basic_text_field: {
    display: 'flex',
    padding: 0,
    backgroundColor: '#A089B2',
    color: '#000',
    fontWeight: '900',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  save_button_box: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  saveButton: {
    width: 'auto',
    height: 40,
    float: 'right',
    marginTop: '1%',
  },

  reviewAndSaveButton: {
    width: 'auto',
    height: 40,
    float: 'left',
    marginTop: '1%',
  },

  table: {
    '& .MuiTableRow-root': {
      verticalAlign: 'baseline',
    },
    '& .MuiTableCell-root .MuiTableCell-head .MuiTableCell-alignLeft': {
      position: 'fixed',
    },

    '& MuiTableRow-head': {
      position: 'absolute',
      width: '60vw',
      display: 'flex',
      justifyContent: 'space-around',
    },

    '& .MuiTableCell-head': {
      color: '#000',
      height: '7vh',
      fontSize: '1.3rem',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiTableCell-root': {
      padding: 0,
      borderBottom: '0.5px rgba(224, 224, 224, 1)',
    },
  },

  title_content: {
    fontSize: '1rem',
    fontWeight: 'bold',
    maxWidth: '10vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'baseline'
  },

  empty_session_button: {
    width: 'fit-content',
    display: 'flex',
    marginTop: '2px',
    fontWeight: 'bold'
  },

  review_tag_style: {
    marginTop: '2.5%',
    color: 'black',
    fontWeight: 'bold'
  }
}));

export default useStylesUseAndSafetyComponents;
