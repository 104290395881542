import React, { useContext } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import { ContextAplication } from "../../../context";
import useStylesDosageUpdateForm from "./styles";
import ButtonAtom from "../ButtonAtom";

export default function ModalFooter({
  log_review,
  isUpdate,
  submit,
}) {
  const classes = useStylesDosageUpdateForm();
  const {
    userRole,
    isButtonDisabledByDebounce,
  } = useContext(ContextAplication);

  return (
    <Box className={classes.save_button_box}>
      <Grid container spacing={3}>
        <Grid item={true} xs={4}>
          {userRole !== "editor" && isUpdate && (
            <ButtonAtom
              textButton="Revisar e Salvar"
              icon={<BeenhereIcon />}
              styles={classes.reviewAndSaveButton}
              onClick={() => submit(true)}
              isDisabled={isButtonDisabledByDebounce}
            />
          )}
        </Grid>
        <Grid item={true} xs={4}>
          {userRole !== "editor" && log_review && isUpdate && (
            <Typography component={"span"} className={classes.review_tag_style}>
              Revisado em{" "}
              {moment(log_review.createdAt).format("D/M/YYYY - HH:mm")} por{" "}
              {log_review?.author_id?.name}
            </Typography>
          )}
        </Grid>
        <Grid item={true} xs={4}>
          <ButtonAtom
            textButton={isUpdate ? "Salvar" : "Criar"}
            icon={<SaveIcon />}
            styles={classes.saveButton}
            onClick={() => submit(false)}
            isDisabled={isButtonDisabledByDebounce}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
