import styled from 'styled-components';

export const SpecialSituationFormContainer = styled.div `
    .select__category {
        color: black;
        font-family: 'Mulish';
        font-size: 1rem;
        height: 2.5rem;
        border: none;
        border-bottom: 1px solid black;
        background-color: unset;
        width: 100%;
        margin-bottom: 15px;

        &:hover {
            border-bottom: 2px solid black;
        }

        &:focus {
            outline: none;
            border-bottom: 2px solid #3f51b5;
        }
    }

    .select__item {
        color: black;
        font-family: 'Mulish';
        font-size: 1rem;
        height: 2.5rem;
        border: none;
        border-bottom: 1px solid black;
        background-color: unset;
        width: 100%;
        margin-bottom: 20px;

        &:hover {
            border-bottom: 2px solid black;
        }

        &:focus {
            outline: none;
            border-bottom: 2px solid #3f51b5;
          }
    }
`