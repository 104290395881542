/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import NumberFormat from 'react-number-format';

import { ContextAplication } from "../../../context";

import ListTherapeuticGroup from "../../atoms/ListTherapeuticGroup";
import EditorHTML from "../../atoms/EditorHTML";

import useStylesBasicInformationForm from "./style";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandsGroupStyle="thousand"
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

const BasicInformationForm = () => {
  const {
    handleChangeActivePrinciple,
    activePrincipleModel,
    setActivePrincipleModel,
    prescription,
    getPrescriptions,
    getActivePrincipleType,
    activePrincipleType,
  } = useContext(ContextAplication);

  const classes = useStylesBasicInformationForm();

  useEffect(() => {
    if (!prescription || prescription.length === 0) {
      getPrescriptions();
    }

    if (!activePrincipleType || activePrincipleType.length === 0) {
      getActivePrincipleType();
    }
  }, []);

  let timeout = null;

  function DebounceEventHandle(field, value) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      return handleChangeActivePrinciple(field, value);
    }, 500);
  }

  return (
    <Box className={classes.accordion_container}>
      <Box className={classes.accordion_content}>
        <TextField
          autoComplete="off"
          classes={{ root: classes.basic_text_field }}
          label="Princípio ativo"
          defaultValue={activePrincipleModel.title}
          onChange={(e) => DebounceEventHandle("title", e.target.value)}
        />
      </Box>
      <Box className={classes.accordion_content}>
        <TextField
          autoComplete="off"
          classes={{ root: classes.basic_text_field }}
          label="Observação (parênteses) do princípio ativo"
          defaultValue={activePrincipleModel.specific_observation}
          onChange={(e) =>
            DebounceEventHandle("specific_observation", e.target.value)
          }
        />
      </Box>
      <Box className={classes.accordion_content}>
        <Grid container spacing={3}>
          <Grid item={true} xs={3}>
            <FormControl classes={{ root: classes.basic_text_field_grid }}>
              <InputLabel id="demo-simple-select-label">Receituário</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={activePrincipleModel?.prescription_icon_id}
                onChange={(_, { props }) =>
                  handleChangeActivePrinciple(
                    "prescription_icon_id",
                    props?.value
                  )
                }
              >
                {prescription &&
                  prescription.map(({ _id, title }) => (
                    <MenuItem key={_id} value={_id}>
                      {title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item={true} xs={3}>
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="DDD"
              defaultValue={activePrincipleModel.ddd}
              onChange={(e) => DebounceEventHandle("ddd", e.target.value)}
            />
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="Página PEDS"
              defaultValue={activePrincipleModel.page}
              onChange={(e) => DebounceEventHandle("page", e.target.value)}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                classes={{ root: classes.checkbox_title }}
              >
                Atributo do Princípio ativo
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Descontinuado"
                    color="default"
                    defaultValue={activePrincipleModel.discontinued}
                    checked={activePrincipleModel.discontinued}
                    onChange={(e) =>
                      handleChangeActivePrinciple(
                        "discontinued",
                        !activePrincipleModel.discontinued
                      )
                    }
                  />
                }
                label="Descontinuado"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="Oculto"
                    color="default"
                    defaultValue={activePrincipleModel.hidden}
                    checked={activePrincipleModel.hidden}
                    onChange={(e) =>
                      handleChangeActivePrinciple(
                        "hidden",
                        !activePrincipleModel.hidden
                      )
                    }
                  />
                }
                label="Oculto"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="Público"
                    color="default"
                    defaultValue={activePrincipleModel.publics}
                    checked={activePrincipleModel.publics}
                    onChange={(e) =>
                      handleChangeActivePrinciple(
                        "publics",
                        !activePrincipleModel.publics
                      )
                    }
                  />
                }
                label="Público"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item={true} xs={6}>
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="Preço em reais"
              defaultValue={activePrincipleModel.price_1}
              onChange={(e) =>
                handleChangeActivePrinciple(
                  "price_1",
                  e.target.value
                )
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="Preço em reais"
              defaultValue={activePrincipleModel.price_2}
              onChange={(e) =>
                handleChangeActivePrinciple(
                  "price_2",
                 e.target.value
                )
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="Preço em reais"
              defaultValue={activePrincipleModel.price_3}
              onChange={(e) =>
                handleChangeActivePrinciple(
                  "price_3",
                  e.target.value
                )
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="Dose"
              defaultValue={activePrincipleModel.shot_1}
              onChange={(e) => DebounceEventHandle("shot_1", e.target.value)}
            />
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="Dose"
              defaultValue={activePrincipleModel.shot_2}
              onChange={(e) => DebounceEventHandle("shot_2", e.target.value)}
            />
            <TextField
              autoComplete="off"
              classes={{ root: classes.basic_text_field_grid }}
              label="Dose"
              defaultValue={activePrincipleModel.shot_3}
              onChange={(e) => DebounceEventHandle("shot_3", e.target.value)}
            />
          </Grid>
        </Grid>
        <div id="observationBasicInformation">
          <EditorHTML
            initialValue={activePrincipleModel.observations}
            field={"observations"}
            handleChangeEditorHTML={(key, value) =>
              handleChangeActivePrinciple(key, value)
            }
            title={"Observações"}
            isLink={activePrincipleModel?._id ? true : false}
            objectData={activePrincipleModel}
            setObjectData={setActivePrincipleModel}
            objectRoute="basic_information"
          />
          <EditorHTML
            heightFieldEditor="15vh"
            initialValue={activePrincipleModel.source_observations}
            field={"source_observations"}
            handleChangeEditorHTML={(key, value) =>
              handleChangeActivePrinciple(key, value)
            }
            title={"Fontes (Observações)"}
          />
          <EditorHTML
            initialValue={activePrincipleModel.whiteboard}
            field={"whiteboard"}
            handleChangeEditorHTML={(key, value) =>
              handleChangeActivePrinciple(key, value)
            }
            title={"Quadro Branco"}
            isLink={activePrincipleModel?._id ? true : false}
            objectData={activePrincipleModel}
            setObjectData={setActivePrincipleModel}
            objectRoute="basic_information"
          />
          <EditorHTML
            heightFieldEditor="15vh"
            initialValue={activePrincipleModel.resume_source}
            field={"resume_source"}
            handleChangeEditorHTML={(key, value) =>
              handleChangeActivePrinciple(key, value)
            }
            title={"Fontes (Quadro Branco)"}
          />
        </div>
        <Grid container spacing={3}>
          <Grid item={true} xs={12}>
            <ListTherapeuticGroup />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BasicInformationForm;
