/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ContextAplication } from "../../../context";
import { isRequiredFields } from "../../../utils/isRequiredField";
import { randomNumber } from "../../../services";

import useStylesPresentationComponents from "../../organisms/Presentation/styles";
import EditorHTML from "../../atoms/EditorHTML";
import BBLoading from "../../molecules/BBLoading";
import { handleSubmitLinks } from "../../../utils/handleSubmitLinks";
import { useParams } from "react-router-dom";

const selectCommercialization = (value) => {
  const statusMap = {
    AT: "Ativo",
    IN: "Descontinuado",
  };

  return statusMap[value] || "Dúvida";
};

const selectCommercializationCreate = (value) => {
  const statusMap = {
    Ativo: "AT",
    Descontinuado: "IN",
  };

  return statusMap[value] || "DU";
};

const PresentationUpdateForm = ({
  presentationId,
  setPresentationId,
  brandId,
  callbackForGet,
  setEditLinkModal,
  getPresentationRowDatagrid,
  getBrand,
}) => {

  const { id } = useParams()

  const classes = useStylesPresentationComponents();
  const {
    pharmaceuticalForm,
    unit,
    handleUpdate,
    activePrincipleModel,
    handleGet,
  } = useContext(ContextAplication);

  const [openFormPresentation, setOpenFormPresentation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageObject, setPageObject] = useState({});
  const [initialDataValues, setInitialDataValues] = useState({});
  const [errors, setErrors] = useState({});
  const [requiredFields, setRequiredFields] = useState(null);
  const [idBrand, setIdBrand] = useState(brandId || null);
  const [presentationModel, setPresentationModel] = useState(null);

  const verifyIdPresentationIncludesBrands = async (
    brandsArray,
    presentationObject
  ) => {
    brandsArray.forEach(({ presentation_id, _id }) => {
      const brand = presentation_id.filter((ele) => ele._id === presentationObject._id);
      if (brand?.length) {
        return setIdBrand(_id);
      }
    });
  };

  async function getPresentation() {
    try {
      setLoading(true);
      const responsePresentation = await handleGet(
        `presentation/${presentationId}`
      );
      const responseBrandsArray = await handleGet(
        `brands/by-active-principle/${activePrincipleModel._id}`
      );

      await verifyIdPresentationIncludesBrands(
        responseBrandsArray,
        responsePresentation
      );

      setPresentationModel(responsePresentation);

      setPageObject({
        ...responsePresentation,
        format: responsePresentation?.free_text === "" ? true : false,
        comercialization: ["Ativo", "Descontinuado", "Dúvida"],
      });

      setInitialDataValues(responsePresentation);

      setRequiredFields(
        responsePresentation?.free_text === ""
          ? ["pharmaceutical_form_id", "volume", "unity_id", "market"]
          : ["free_text", "market"]
      );

      setOpenFormPresentation(true);

      return setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Ocorreu um erro ao carregar a apresentação");
    }
  }

  useEffect(() => {
    if (presentationId) {
      getPresentation();
    }
  }, [presentationId]);

  const whenExitingAndCleanThePresentationModule = async () => {
    if (setEditLinkModal) {
      setEditLinkModal(null);
    }
    if (callbackForGet) {
      await callbackForGet();
    }
    if (setPresentationId) {
      setPresentationId();
    }
    setPageObject({});
    setPresentationModel({});
    return setOpenFormPresentation(!openFormPresentation);
  };

  const validateErrors = (validateErrors) => {
    if (Object.keys(validateErrors).length !== 0) {
      return true;
    }
  };

  const verifyValueInputRequired = async (requiredFields, pageObject) => {
    const error = await isRequiredFields(requiredFields, pageObject);
    setErrors(error);
    return error;
  };

  const assemblesTheObjectForTheRequisition = async () => {
    if (pageObject.format) {
      return {
        pharmaceutical_form_id: pageObject.pharmaceutical_form_id,
        volume: pageObject.volume,
        unity_id: pageObject.unity_id,
        amount: pageObject.amount,
        market: pageObject.market,
        free_text: "",
        is_default: true,
        observation: pageObject.observation,
        source_observation: pageObject.source_observation,
      };
    }
    return {
      free_text: pageObject.free_text,
      is_default: false,
      market: pageObject.market,
      pharmaceutical_form_id: null,
      volume: "",
      unity_id: null,
      amount: "",
      observation: pageObject.observation,
      source_observation: pageObject.source_observation,
    };
  };

  const submitPresentation = async () => {
    setLoading(true);
    try {
      const existError = await verifyValueInputRequired(
        requiredFields,
        pageObject
      );

      if (validateErrors(existError)) {
        setLoading(false);
        return false;
      }

      const presentationBodyToSubmit =
        await assemblesTheObjectForTheRequisition();

      
      await handleSubmitLinks(initialDataValues, presentationBodyToSubmit, ['observation'], id)

      await handleUpdate(
        `presentation/${presentationId}`,
        idBrand,
        presentationBodyToSubmit,
        activePrincipleModel._id
      );

      if (getPresentationRowDatagrid && getBrand) {
        await getPresentationRowDatagrid();
        await getBrand();
      }

      toast.success("Apresentação atualizada com sucesso!");
      whenExitingAndCleanThePresentationModule();
    } catch (error) {
      toast.error("Ocorreu um erro ao atualizar a apresentação!");
    }
    setOpenFormPresentation(false);
    setLoading(false);
  };

  const handleChange = (key, value) => {
    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
    setPageObject((state) => ({ ...state, [key]: value }));
  };

  const verifyId = (value) => {
    if (value) return value._id;
    return null;
  };

  const formPresentation = (
    <Box className={classes.paper}>
      <div className={classes.modal_title}>
        <h3>
          Princípio Ativo:{" "} {activePrincipleModel ? activePrincipleModel.title : ""}
        </h3>
        <Button onClick={() => whenExitingAndCleanThePresentationModule()}>
          <CloseIcon className={classes.close_icon} />
        </Button>
      </div>
      <Box className={classes.modal_header}>
        <Typography variant="h5">Atualizar apresentação</Typography>
      </Box>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Formato</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={({ target }) => handleChange("format", target.value)}
          value={pageObject.format}
        >
          <MenuItem key={randomNumber()} value={true}>
            Padrão
          </MenuItem>
          <MenuItem key={randomNumber()} value={false}>
            Texto livre
          </MenuItem>
        </Select>
      </FormControl>
      {pageObject?.format && (
        <FormControl className={classes.formControl}>
          <Autocomplete
            onChange={(_, value) =>
              handleChange("pharmaceutical_form_id", verifyId(value))
            }
            options={pharmaceuticalForm || []}
            getOptionLabel={(option) => option.title}
            defaultValue={pageObject?.pharmaceutical_form_id}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Forma farmacêutica"
                margin="normal"
                helperText={
                  errors.pharmaceutical_form_id
                    ? errors.pharmaceutical_form_id
                    : false
                }
                error={errors.pharmaceutical_form_id ? true : false}
              />
            )}
          />
        </FormControl>
      )}
      {pageObject?.format && (
        <TextField
          className="native-key-bindings"
          tabIndex="-1"
          type="Text"
          autoComplete="off"
          onChange={(e) => {
            handleChange("volume", e.target.value);
          }}
          id="standard-basic"
          label="Concentração"
          value={pageObject?.volume}
          helperText={errors.volume ? errors.volume : false}
          error={errors.volume ? true : false}
        />
      )}
      {pageObject?.format && (
        <FormControl className={classes.formControl}>
          <Autocomplete
            onChange={(_, value) => handleChange("unity_id", verifyId(value))}
            options={unit.sort((a, b) => a.title.localeCompare(b.title))}
            getOptionLabel={(option) => option.title}
            defaultValue={pageObject?.unity_id}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Unidade"
                margin="normal"
                helperText={errors.unity_id ? errors.unity_id : false}
                error={errors.unity_id ? true : false}
              />
            )}
          />
        </FormControl>
      )}
      {pageObject?.format && (
        <TextField
          autoComplete="off"
          onChange={({ target }) => handleChange("amount", target.value)}
          id="standard-basic"
          label="Quantidade"
          value={pageObject?.amount}
          helperText={errors.amount ? errors.amount : false}
          error={errors.amount ? true : false}
        />
      )}
      {!pageObject?.format && (
        <TextField
          autoComplete="off"
          onChange={({ target }) => handleChange("free_text", target.value)}
          id="standard-basic"
          label="Campo texto livre"
          value={pageObject?.free_text}
          helperText={errors.free_text ? errors.free_text : false}
          error={errors.free_text ? true : false}
        />
      )}
      <FormControl className={classes.formControl}>
        <Autocomplete
          onChange={(_, value) =>
            handleChange("market", selectCommercializationCreate(value))
          }
          disableClearable={true}
          options={pageObject?.comercialization || []}
          getOptionLabel={(option) => option}
          defaultValue={selectCommercialization(pageObject?.market)}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              {...params}
              label="Comercialização"
              margin="normal"
              helperText={errors.market ? errors.market : false}
              error={errors.market ? true : false}
            />
          )}
        />
      </FormControl>
      <EditorHTML
        initialValue={presentationModel?.observation}
        field={"observation"}
        handleChangeEditorHTML={(key, value) => handleChange(key, value)}
        title={"Observações"}
        isLink={presentationModel?._id ? true : false}
        objectData={presentationModel}
        setObjectData={(param) => setPresentationModel(param)}
        objectRoute="presentation"
        brandId={brandId ? brandId : idBrand}
        setOpenFormPresentation={() =>
          whenExitingAndCleanThePresentationModule()
        }
        setPresentationId={
          setPresentationId ? (param) => setPresentationId(param) : null
        }
        isDebounceNeeded={false}
      />
      <EditorHTML
        heightFieldEditor="15vh"
        initialValue={presentationModel?.source_observation}
        field={"source_observation"}
        handleChangeEditorHTML={(key, value) => handleChange(key, value)}
        title={"Fontes (Observações)"}
        isDebounceNeeded={false}
      />
      <Box>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          startIcon={<SaveIcon />}
          onClick={submitPresentation}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Modal
        open={openFormPresentation}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {formPresentation}
      </Modal>
      {loading && <BBLoading isOpen={loading} />}
    </Box>
  );
};

export default PresentationUpdateForm;
