/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { toast } from 'react-toastify';
import moment from "moment";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { ContextAplication } from "../../../context";

import BrandModal from "../BrandModal";
import AccordionAtom from "../../atoms/AccordionAtom";
import DataGridAtom from "../../atoms/DataGridAtom";
import TooltipAtom from "../../atoms/TooltipAtom";
import CardHeaderMolecule from "../../molecules/CardHeaderMolecule";
import CardFooterMolecule from "../../molecules/CardFooterMolecule";

import useStylesDosageComponents from "./styles";
import { getNameAuthor, getReviewCreatedAt } from "../../../utils/reviewFunctions";

const selectStatusBrand = (value) => {
  const statusMap = {
    AT: "Ativo",
    DE: "Descontinuado",
    DU: "Dúvida",
  };

  return statusMap[value] || "Descontinuado";
};

const AccordionBrand = ({ id, handleGetReviewById, review }) => {
  const { activePrincipleModel, handleSubmit, handleGet, handleDelete } =
    useContext(ContextAplication);

  const classes = useStylesDosageComponents();

  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState(null);
  const [openEmptyReviewDialog, setOpenEmptyReviewDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleGetBrandByIdOfActivePrinciple = async (isFirstRendering = false) => {
    try {
      const response = await handleGet(
        `brands/by-active-principle/${activePrincipleModel._id}`
      );
      if(!isFirstRendering) {
        await handleGetReviewById();
      }
      return setBrands(response);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar marcas!');
    }
  };

  useEffect(() => {
    handleGetBrandByIdOfActivePrinciple(true);
  }, []);

  const handleDeleteButton = async (brandId, brandTitle) => {
    try {
      if (window.confirm(`Deseja deletar ${brandTitle}?`)) {
        await handleDelete(`brands/${brandId}/${activePrincipleModel._id}`);
        await handleGetReviewById();
        toast.success('Marca deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao deletar!');
    }
    return handleGetBrandByIdOfActivePrinciple();
  };

  const handleEmptySessionReview = async () => {
    try {
      await handleSubmit(
        `review/${activePrincipleModel.review_id}/brands_full_review`,
        {}
      );
      await handleGetBrandByIdOfActivePrinciple();
      await handleGetReviewById();
      toast.success('Marca revisada como vazia.');
    } catch (error) {
      toast.error('Ocorreu um erro ao revisar como vazio!');
    }

    setOpenEmptyReviewDialog(false);
  };

  const handleOpenFormBrand = (id) => {
    setOpenModal(true);
    return setBrandId(id);
  };

  const columnsDataGrid = [
    {
      field: "title",
      headerName: "Nome",
      align: "left",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.title} placement="top">
          <Typography
            component={'span'}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.title}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "laboratory",
      headerName: "Laboratório",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.laboratory} placement="top">
          <Typography
            component={'span'}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.laboratory}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "presentation_id",
      valueGetter: (params) => params.row.presentation_id.length,
      headerName: "Apresentações",
      align: "center",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.status} placement="top">
          <Typography component={'span'}>{row.status}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "review",
      headerName: "Revisão",
      align: "center",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.review} placement="top">
          <Typography component={'span'}>{row.review}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      align: "center",
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="update"
            color="primary"
            onClick={() => handleOpenFormBrand(row._id)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(row._id, row.title)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let rowDataGrid = [];
  brands &&
    brands
      .map(
        (
          {
            _id,
            title,
            presentation_id,
            laboratory_id,
            status,
            log_review_id,
            deleted,
            classification_id,
          },
          index
        ) =>
          !deleted &&
          rowDataGrid.push({
            id: index,
            _id,
            title,
            classification_id,
            laboratory_id,
            presentation_id: presentation_id.filter((p) => !p.deleted),
            laboratory: laboratory_id ? laboratory_id.title : "---",
            status: selectStatusBrand(status),
            review: log_review_id
              ? moment(log_review_id.createdAt).format("D/M/YYYY - HH:mm")
              : "---",
          })
      );

  return (
    <AccordionAtom
      id={id}
      accordionTitle="Marcas e apresentações"
      componentName="brands"
      colorTheme="blue"
      isReviewed={review?.brands_full_review || false}
      reviewDate={review?.brands_full_review ? getReviewCreatedAt(brands) : ""}
      reviewAuthor={review?.brands_full_review ? getNameAuthor(brands) : ""}
    >
      <CardHeaderMolecule
        handleOpenForm={() => handleOpenFormBrand()}
        textAddButton="Adicionar marca"
      />

      <>
        <DataGridAtom
          pageSize={10}
          arrData={rowDataGrid}
          columns={columnsDataGrid}
          theme="blue"
        />
      </>

      <CardFooterMolecule
        isEmpty={!rowDataGrid.filter(({ deleted }) => deleted !== false).length}
        handleEmptySessionReview={() => handleEmptySessionReview()}
        openEmptyReviewDialog={openEmptyReviewDialog}
        setOpenEmptyReviewDialog={setOpenEmptyReviewDialog}
      />

      {openModal && (
        <BrandModal
          closeModal={(param) => {
            setOpenModal(param);
            setBrandId(null);
          }}
          idBrand={brandId}
          handleGetBrandByIdOfActivePrinciple={
            handleGetBrandByIdOfActivePrinciple
          }
        />
      )}
    </AccordionAtom>
  );
};

export default AccordionBrand;
