/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";

import { ContextAplication } from "../../../context";
import BBLoading from "../../molecules/BBLoading";

import { isRequiredFields } from "../../../utils/isRequiredField";
import ModalAtom from "../../atoms/ModalAtom";
import DosageForm from "../../molecules/DosageForm";
import UnSaveDialogAtom from "../../atoms/UnSaveDialogAtom";
import { handleSubmitLinks } from "../../../utils/handleSubmitLinks";
import { useParams } from "react-router-dom";

const DosageModal = ({
  closeModal,
  idDosage,
  setIdDosage,
  handleGetDosagesByActivePrincipleId,
  handleGetReviewById,
  dosages
}) => {
  const {
    handleUpdate,
    handleGet,
    activePrincipleModel,
    filterIds,
    shotGroup,
    getShotGroup,
    handleSubmit,
  } = useContext(ContextAplication);

  const {id} = useParams()

  const [dosageData, setDosageData] = useState(null);
  const [initialDataValues, setInitialDataValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const requiredFields = ["category_type_id", "recommendation"];

  const assemblesTheObjectForTheRequisition = async (isCreate) => {
    if (isCreate) {
      return {
        recommendation: dosageData?.recommendation,
        category_type_id: dosageData?.category_type_id,
        content: dosageData?.content,
        content_source: dosageData?.content_source,
        keywords_id: dosageData.keywords_id
          ? filterIds(dosageData?.keywords_id)
          : [],
        show_in_search: Boolean(dosageData?.show_in_search),
        is_usual: Boolean(dosageData?.is_usual),
      };
    }
    return {
      recommendation: dosageData.recommendation,
      category_type_id: dosageData.category_type_id,
      content: dosageData.content,
      content_source: dosageData.content_source,
      keywords_id: filterIds(dosageData.keywords_id),
      show_in_search: Boolean(dosageData.show_in_search),
      is_usual: Boolean(dosageData.is_usual),
    };
  };

  const validateErrors = (validateErrors) => {
    if (Object.keys(validateErrors).length !== 0) {
      return true;
    }
  };

  const verifyValueInputRequired = async (requiredFields, dosageData) => {
    const error = await isRequiredFields(requiredFields, dosageData);
    setErrors(error);
    return error;
  };

  function exitModalDosage() {
    closeModal(null);
    setDosageData(null);
  }

  const handleSubmitCreate = async (isCreate) => {
    setLoading(true);
    try {
      const existError = await verifyValueInputRequired(
        requiredFields,
        dosageData
      );

      if (validateErrors(existError)) {
        setLoading(false);
        return false;
      }

      const dosageAdapterData = await assemblesTheObjectForTheRequisition(true);

      const responseDosage = await handleSubmit(
        `dosage/${activePrincipleModel._id}`,
        dosageAdapterData
      );

      toast.success("Dose criada com sucesso!");
      await handleGetDosagesByActivePrincipleId();

      if (!isCreate) {
        setDosageData(responseDosage);
        setIdDosage(responseDosage._id);
      }

      setLoading(false);
      return responseDosage;
    } catch (error) {
      toast.error("Ocorreu um erro ao criar a dose!");
    }
  };

  const handleSubmitUpdate = async (isReview) => {
    setLoading(true);
    try {
      const validateErrors = await isRequiredFields(requiredFields, dosageData);
      setErrors(validateErrors);
      if (Object.keys(validateErrors).length !== 0) {
        setLoading(false);
        return false;
      }

      const dosageAdapterData = await assemblesTheObjectForTheRequisition(
        false
      );

      await handleSubmitLinks(initialDataValues, dosageAdapterData, ['content'], id)

      await handleUpdate(
        "dosage",
        idDosage,
        dosageAdapterData,
        activePrincipleModel._id
      );

      if (isReview) {
        await handleUpdate(
          "dosage/review",
          idDosage,
          {}, //body
          activePrincipleModel._id
        );
      }
      if (handleGetDosagesByActivePrincipleId) {
        await handleGetDosagesByActivePrincipleId();
      }
      closeModal(null);
      setDosageData(null);
      toast.success("Dose atualizada com sucesso!");
    } catch (error) {
      toast.error("Ocorreu um erro ao atualizar a dose!");
    }
    setLoading(false);
  };

  const handleChange = (key, value) => {
    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
    setDosageData((state) => ({ ...state, [key]: value }));
  };

  const getOneDosage = async (id) => {
    try {
      if (!id) {
        setDosageData({ show_in_search: true });
        return;
      }
      setLoading(true);
      const response = await handleGet(`dosage/${id}`);
      setDosageData(response);
      setInitialDataValues(response)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Ocorreu um erro ao carregar a dose!");
    }
  };

  useEffect(() => {
    getOneDosage(idDosage);
    if (!shotGroup) {
      getShotGroup();
    }
  }, []);

  return (
    <>
      {dosageData && (
        <ModalAtom
          modalTheme="green"
          onClose={() => {
            closeModal(null);
            setDosageData(null);
          }}
          title={idDosage ? "Atualizar dose" : "Adicionar dose"}
          log_review={dosageData?.log_review_id}
          isUpdate={idDosage}
          submit={idDosage ? handleSubmitUpdate : handleSubmitCreate}
          optionLink={idDosage ? false : true}
        >
          <DosageForm
            dosages={idDosage}
            handleChange={(key, value) => handleChange(key, value)}
            dosageData={dosageData}
            errors={errors}
            isUpdate={idDosage}
            setDosageData={(param) => setDosageData(param)}
            handleGetReviewById={() => handleGetReviewById()}
            handleSubmitCreate={(param) => handleSubmitCreate(param)}
            exitModalDosage={() => exitModalDosage()}
          />
        </ModalAtom>
      )}
      {openUpdateDialog && (
        <UnSaveDialogAtom
          openUpdateDialog={openUpdateDialog}
          setOpenUpdateDialog={(val) => setOpenUpdateDialog(val)}
          unSaveAction={() => {
            closeModal(null);
            setDosageData(null);
          }}
        />
      )}
      {loading && <BBLoading isOpen={loading} />}
    </>
  );
};

export default DosageModal;
