import styled from 'styled-components';

import { colorSelector } from '../../../services/colorSelector';

export const Container = styled.div`
    margin: 6vh 20vh 20vh 20vh;
    position: absolute;
    width: 80%;
    height: auto;
    max-height: 90%;
    color: #fff;
    background-color: ${({ modalTheme }) => colorSelector(modalTheme, 'hard')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    :focus {
      outline: none;
    }

    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${({ modalTheme }) => colorSelector(modalTheme, 'dark')};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${({ modalTheme }) => colorSelector(modalTheme, 'hard')};
    };
`;
