/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {Box} from "@material-ui/core";
import useStylesContentPageOrganism from "./style";
import HeaderPageMolecule from "../../molecules/HeaderPageMolecule";
import DataGridAtom from "../../atoms/DataGridAtom";

const ContentPageOrganism = ({
  titlePage, 
  onClickAddButton, 
  onChangeSearch, 
  arrData, 
  columns, 
  loading,
  lottieText,
  helperText
}) => {
  const classes = useStylesContentPageOrganism();

  return (
    <Box className={classes.container}>
      <HeaderPageMolecule
        titlePage={titlePage}
        onClickAddButton={onClickAddButton}
        onChangeSearch={onChangeSearch}
        helperText={helperText}
      />
      <Box className={classes.content}>
        <DataGridAtom 
          lottieText={lottieText}
          pageSize={99}
          arrData={arrData}
          columns={columns}
          loading={loading}
          theme="dark"
        />
      </Box>
    </Box>
  );
};

export default ContentPageOrganism;
