/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { ContextAplication } from '../../../context';
import BBLoading from '../../molecules/BBLoading';

import { isRequiredFields } from '../../../utils/isRequiredField';
import ModalAtom from '../../atoms/ModalAtom';
import UnSaveDialogAtom from '../../atoms/UnSaveDialogAtom';
import AdverseEffectsForm from '../../molecules/AdverseEffectsForm';
import { handleSubmitLinks } from '../../../utils/handleSubmitLinks';
import { useParams } from 'react-router-dom';

const AdverseEffectsModal = ({
  closeModal,
  idAdverseEffect,
  setAdverseEffectsId,
  handleGetAdverseEffectsByActivePrincipleId,
}) => {
  const { id } = useParams();

  const { handleUpdate, handleGet, activePrincipleModel, handleSubmit } =
    useContext(ContextAplication);
  const [loading, setLoading] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const requiredFields = ['category_type_id'];

  const [adverseEffectsData, setAdverseEffectsData] = useState(null);
  const [initialDataValues, setInitialDataValues] = useState(null);

  const handleSubmitCreate = async (isCreate) => {
    setLoading(true);
    try {
      const validateErrors = await isRequiredFields(
        requiredFields,
        adverseEffectsData
      );
      setErrors(validateErrors);
      if (Object.keys(validateErrors).length !== 0) {
        setLoading(false);
        return false;
      }

      const adverseEffectsAdapterData = {
        category_type_id: adverseEffectsData?.category_type_id,
        content: adverseEffectsData?.content,
        content_source: adverseEffectsData?.content_source,
      };

      const response = await handleSubmit(
        `adverse/${activePrincipleModel._id}`,
        adverseEffectsAdapterData
      );

      if (!isCreate) {
        await setAdverseEffectsId(response?._id);
        setLoading(false);
        return setAdverseEffectsData(response);
      }

      toast.success('Efeito colateral criado com sucesso!');
      await handleGetAdverseEffectsByActivePrincipleId();
      closeModal(null);
      setAdverseEffectsData(null);
    } catch (error) {
      toast.error('Ocorreu um erro ao criar o efeito colateral!');
    }

    setLoading(false);
  };

  const handleSubmitUpdate = async (isReview) => {
    setLoading(true);
    try {
      const validateErrors = await isRequiredFields(
        requiredFields,
        adverseEffectsData
      );
      setErrors(validateErrors);
      if (Object.keys(validateErrors).length !== 0) {
        setLoading(false);
        return false;
      }

      const adverseEffectsAdapterData = {
        category_type_id: adverseEffectsData.category_type_id,
        content: adverseEffectsData.content,
        content_source: adverseEffectsData.content_source,
      };

      await handleSubmitLinks(
        initialDataValues,
        adverseEffectsAdapterData,
        ['content'],
        id
      );

      await handleUpdate(
        'adverse',
        idAdverseEffect,
        adverseEffectsAdapterData,
        activePrincipleModel._id
      );

      if (isReview) {
        await handleUpdate(
          'adverse/review',
          idAdverseEffect,
          {}, //body
          activePrincipleModel._id
        );
      }
      if (handleGetAdverseEffectsByActivePrincipleId) {
        await handleGetAdverseEffectsByActivePrincipleId();
      }
      closeModal(null);
      setAdverseEffectsData(null);
      toast.success('Efeito colateral atualizado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar o efeito colateral!');
    }
    setLoading(false);
  };

  const handleChange = (key, value) => {
    if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
    setAdverseEffectsData((state) => ({ ...state, [key]: value }));
  };

  const getOneAdverseEffect = async (id) => {
    try {
      if (!id) {
        setAdverseEffectsData({});
        return;
      }

      setLoading(true);
      const response = await handleGet(`adverse/${id}`);
      setAdverseEffectsData(response);
      setInitialDataValues(response);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      toast.error('Ocorreu um erro ao carregar o efeito colateral!');
    }
  };

  useEffect(() => {
    getOneAdverseEffect(idAdverseEffect);
  }, []);

  return (
    <>
      {adverseEffectsData && (
        <ModalAtom
          modalTheme="red"
          onClose={() => {
            closeModal(null);
            setAdverseEffectsData(null);
          }}
          title={
            idAdverseEffect
              ? 'Atualizar efeitos colaterais'
              : 'Adicionar efeitos colaterais'
          }
          log_review={adverseEffectsData?.log_review_id}
          isUpdate={idAdverseEffect}
          submit={idAdverseEffect ? handleSubmitUpdate : handleSubmitCreate}
          optionLink={idAdverseEffect ? false : true}
        >
          <AdverseEffectsForm
            isUpdate={idAdverseEffect}
            handleChange={(key, value) => handleChange(key, value)}
            adverseEffectsData={adverseEffectsData}
            setAdverseEffectsData={(param) => setAdverseEffectsData(param)}
            errors={errors}
          />
        </ModalAtom>
      )}
      {openUpdateDialog && (
        <UnSaveDialogAtom
          openUpdateDialog={openUpdateDialog}
          setOpenUpdateDialog={(val) => setOpenUpdateDialog(val)}
          unSaveAction={() => {
            closeModal(null);
            setAdverseEffectsData(null);
          }}
        />
      )}
      {loading && <BBLoading isOpen={loading} />}
    </>
  );
};

export default AdverseEffectsModal;
