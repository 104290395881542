/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";

import Box from "@material-ui/core/Box";

import { ContextAplication } from "../../../context";
import EditorHTML from "../../atoms/EditorHTML";

import useStylesContraIndicationsForm from "./style";

const ContraIndicationsForm = () => {
  const {
    handleChangeActivePrinciple,
    activePrincipleModel,
    setActivePrincipleModel,
  } = useContext(ContextAplication);

  const classes = useStylesContraIndicationsForm();

  return (
    <Box className={classes.accordion_container}>
      <Box className={classes.accordion_content}>
        <EditorHTML
          initialValue={activePrincipleModel.contraindications}
          field={"contraindications"}
          handleChangeEditorHTML={(field, value) =>
            handleChangeActivePrinciple(field, value)
          }
          title={"Contraindicações"}
          isLink={activePrincipleModel?._id ? true : false}
          objectData={activePrincipleModel}
          setObjectData={setActivePrincipleModel}
          objectRoute="contraindications"
        />
        <EditorHTML
          heightFieldEditor="15vh"
          initialValue={activePrincipleModel.contraindications_source}
          field={"contraindications_source"}
          handleChangeEditorHTML={(field, value) =>
            handleChangeActivePrinciple(field, value)
          }
          title={"Fontes (Contraindicações)"}
        />
      </Box>
    </Box>
  );
};

export default ContraIndicationsForm;
