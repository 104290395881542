import styled from 'styled-components';

import { colorSelector } from '../../../services/colorSelector';

export const Container = styled.div`
  width: auto;
  height: auto;
  position: relative;
  margin: 9px 0 0 0;
  background-color: #545454;

  .accordion_summary {
    background: ${({ colorTheme }) => colorSelector(colorTheme, 'dark')};
    position: sticky;
    top: 0;
    z-index: 2;
    color: #FFF;
    display: flex;
    justify-content: space-between;

    .MuiIconButton-root {
      color: #fff;
    }

    .MuiAccordionSummary-content {
      justify-content: space-between;
    }
  }

  .accordion_title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-right: 1rem;
    color: #fff;
  }

  .reviewed_box {
    margin-right: auto;
    color: lawngreen;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not_reviewed_box {
    margin-right: auto;
    color: darkred;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordion_container {
    width: 100%;
  }

  .accordion_content {
    margin: 10px;
    flex-grow: 1;
  }

  .accordion_sub_content {
    display: flex;
  }

  .MuiAccordionDetails-root {
    background-color: ${({ colorTheme }) => colorSelector(colorTheme, 'hard')};
    flex-direction: column;
  }

  .display_header {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
    padding-right: 10px;
  }


  .display_header_title {
    font-weight: 500;
  }

`;
