import { makeStyles } from '@material-ui/core/styles';

const useStylesHeaderPageMolecule = makeStyles((theme) => ({
  box: {
    marginBottom: '20px',
    display: 'flex',
    width: '70vw',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  box_header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem', 
  },

  basic_text_field: {
    display: 'flex',
    padding: 0,
    fontWeight: 'bold',
    width: '100%',
    color: '#FFF',
    '& label.Mui-focused': {
      color: '#FFF',
    },
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#FFF',
      fontWeight: 'bold',
    },
    '& .MuiFormHelperText-root': {
      color: '#FFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#606060',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#424242',
      },
      '&:hover fieldset': {
        borderColor: '#606060',
      },
      '& .Mui-focused fieldset': {
        borderColor: '#909090',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor:'#5d5d5d'
    },
  },

  box_text_field: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
  },
}));

export default useStylesHeaderPageMolecule;
