import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Lottie from 'react-lottie';

export function BBLoadingAtom({loadingJson, paddingParam, lottieText, textMargin}) {
  return (
    <Box style={{
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      paddingBottom: paddingParam,
    }}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingJson,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height='auto'
        width={300}
      />
      <Typography component={'span'} variant="h4" style={{
        color: '#fff', 
        marginTop: textMargin, 
        fontWeight: 'bold',
        fontSize: '16px'
        }}>{lottieText}</Typography>
    </Box>
  )
}
