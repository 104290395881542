/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Typography, Box, IconButton } from "@material-ui/core";

import { ContextAplication } from "../../../context";

import TooltipAtom from "../../atoms/TooltipAtom";
import DataGridAtom from "../../atoms/DataGridAtom";
import ModalAtom from "../../atoms/ModalAtom";
import BBLoading from "../../molecules/BBLoading";
import routineApi from "../../../services/routineApi"

import DosageExtraContentForm from "../../molecules/DosageExtraContentForm";
import CardHeaderMolecule from "../../molecules/CardHeaderMolecule";
import ImageForm from "../../molecules/DosageExtraContentForm/imageForm";
import useStylesDosageExtraContentForm from '../../molecules/DosageExtraContentForm/style';
import { StorageProvider, useFirebaseApp } from "reactfire";
import { getStorage } from "@firebase/storage";

require('dotenv').config();

const DosageExtraContentModal = ({
  idDosage,
  isUpdateDosage,
  handleGetReviewById,
  handleSubmitCreateDosage,
  exitModalDosage,
}) => {
  const appFirebase = useFirebaseApp();

  const {
    handleGet,
    handleSubmit,
    handleUpdate,
    activePrincipleModel,
    handleDelete,
  } = useContext(ContextAplication);

  const classes = useStylesDosageExtraContentForm();

  const [loading, setLoading] = useState(false);
  const [extraContentTypeData, setExtraContentTypeData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [dosageExtraContent, setDosageExtraContent] = useState([]);
  const [dosageExtraContentRow, setDosageExtraContentRow] = useState(null);
  const [idDosageExtraContent, setidDosageExtraContent] = useState(idDosage);
  const [fileImage, setFileImage] = useState();

  const getExtraContentType = async () => {
    setLoading(true);
    try {
      const response = await handleGet(`dosageextracontenttype`);
      setLoading(false);
      return setExtraContentTypeData(response);
    } catch (error) {
      toast.error("Ocorreu um erro ao buscar o tipo de conteúdo extra!");
      setLoading(false);
    }
  };

  const handleGetDosageExtraContentByDosage = async () => {
    try {
      const response = await handleGet(`dosageextracontent/by-dosage/${idDosage ? idDosage : idDosageExtraContent}`);
      return setDosageExtraContent(response);
    } catch (erro) {
      toast.error("Ocorreu um erro ao buscar conteúdos extras!");
    }
  };

  function exitModal() {
    setOpenModal(false);
    setidDosageExtraContent(null);
    setDosageExtraContentRow(null);
    setFileImage(null);
    setLoading(false);
  }

  let timeout= null;
  const handleChange = (field, value) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      return setDosageExtraContentRow((state) => ({
        ...state,
        [field]: value,
      }));
    }, 500);
  };

  useEffect(() => {
    if (isUpdateDosage) {
      handleGetDosageExtraContentByDosage();
    }
    getExtraContentType();
    setidDosageExtraContent(idDosage);
  }, []);

  async function handleDeleteButton(dosageExtraContent) {
    setLoading(true);
    try {
      if (window.confirm(`Deseja deletar?`)) {
        await handleDelete(
          `dosageextracontent/${dosageExtraContent._id}/${activePrincipleModel._id}`
        );
        if(dosageExtraContent?.dosage_extra_content_type_id?.title === "Imagem"){
          let imageName = dosageExtraContent.content.split('/')[dosageExtraContent.content.split('/').length - 1]
          await routineApi.delete(`/image/${imageName}/${dosageExtraContent._id}`)
        }
        await handleGetReviewById();
        toast.success("Conteúdo extra deletado com sucesso!");
      }
      await handleGetDosageExtraContentByDosage(idDosage);
      return exitModal()
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar!");
      exitModal()
    }
  }

  async function handleSubmitCreate() {
    setLoading(true);
    try {
      const submitReturn = await handleSubmit(
        `dosageextracontent/${idDosage ? idDosage : idDosageExtraContent}/${activePrincipleModel._id}`,
        {
          ...dosageExtraContentRow,
          content: fileImage ? fileImage : dosageExtraContentRow.content
        }
      );

      if(fileImage){
        await routineApi.post(`/image/create`, 
        {
          url: fileImage,
            name: fileImage.split('/')[fileImage.split('/').length - 1],
            origin: {
                id: submitReturn._id,
                collection: "d_dosage_extra_content",
                field: "content"
            }
        }
        )
      }

      toast.success("Conteúdo extra salvo com sucesso!");

      await handleGetDosageExtraContentByDosage();

      if (!idDosage) {
        exitModalDosage();
      }
      return exitModal();
    } catch (error) {
      toast.error("Erro ao salvar conteúdo extra!");
      exitModal();
    }
  }

  async function handleSubmitUpdate() {
    setLoading(true);
    try {
      await handleUpdate(
        "dosageextracontent",
        `/${dosageExtraContentRow._id}/${idDosage ? idDosage : idDosageExtraContent}/${activePrincipleModel._id}`,
        dosageExtraContentRow
      );

      toast.success("Conteúdo extra editado com sucesso!");
      await handleGetDosageExtraContentByDosage();
      return exitModal();
    } catch (error) {
      toast.error("Erro ao editar conteúdo extra!");
      exitModal();
    }
  }

  const columns = [
    {
      field: "title",
      headerName: "Título",
      flex: 0.3,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.title} placement="top">
          <Typography
            component={"span"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.title}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 0.15,
      renderCell: ({ row }) => (
        <TooltipAtom
          title={row?.dosage_extra_content_type_id.title}
          placement="top"
        >
          <Typography
            component={"span"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.dosage_extra_content_type_id.title}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "content",
      headerName: "Conteúdo",
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.content} placement="top">
          <Typography component={"span"} style={{ textAlign: "center" }}>
            {row.content}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: "",
      headerName: "Ações",
      sortable: false,
      flex: 0.15,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box>
          <IconButton
            aria-label="update"
            color="primary"
            onClick={() => {
              setOpenModal(true);
              setDosageExtraContentRow(row);
            }}
          >
            <EditIcon />
          </IconButton>
          {row?.dosage_extra_content_type_id.title !== "Imagem" && (
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => handleDeleteButton(row)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  let row = [];
  dosageExtraContent.length &&
    dosageExtraContent.map(
      ({ _id, title, deleted, content, dosage_extra_content_type_id }, index) =>
        !deleted &&
        row.push({
          id: index,
          _id,
          title,
          content,
          type: dosage_extra_content_type_id.title,
          dosage_extra_content_type_id,
        })
    );

  async function handleSubmitCreateDosageAndOpenModalExtraContent() {
    const responseCreateDosage = await handleSubmitCreateDosage(true);
    if (responseCreateDosage?._id) {
      setidDosageExtraContent(responseCreateDosage?._id);
      return setOpenModal(true);
    }
  }

  function renderOptionFormUpdate(paramRow) {
    if (paramRow?.dosage_extra_content_type_id?.title === "Imagem") {
      return (
        <>
          <ImageForm
            modalTheme="green"
            onClose={() => exitModal()}
            title="Deletar imagem"
            submit={() => handleDeleteButton(paramRow)}
            image={paramRow?.content}
          />
        </>
      );
    }
    return (
      <>
        <ModalAtom
          modalTheme="green"
          onClose={() => exitModal()}
          title={
            dosageExtraContentRow?._id
              ? "Atualizar conteúdo extra"
              : "Adicionar conteúdo extra"
          }
          submit={
            dosageExtraContentRow?._id ? handleSubmitUpdate : handleSubmitCreate
          }
        >
          <DosageExtraContentForm
            handleChange={(field, value) => handleChange(field, value)}
            extraContentTypeData={extraContentTypeData}
            setExtraContentTypeData={setExtraContentTypeData}
            dosageExtraContentRow={dosageExtraContentRow}
            setFileImage={(param) => setFileImage(param)}
            fileImage={fileImage}
            isUpdate={dosageExtraContentRow?._id ? true : false}
          />
        </ModalAtom>
      </>
    );
  }

  return (

    <StorageProvider sdk={getStorage(appFirebase)}>
    <Box className={classes.container}>
        <CardHeaderMolecule
          textAddButton="Adicionar conteúdo extra"
          handleOpenForm={() =>
            isUpdateDosage
              ? setOpenModal(true)
              : handleSubmitCreateDosageAndOpenModalExtraContent()
          }
        />
        {isUpdateDosage && (
          <DataGridAtom
            pageSize={10}
            arrData={row}
            columns={columns}
            theme="green"
          />
        )}

      {openModal && renderOptionFormUpdate(dosageExtraContentRow)}

      {loading && <BBLoading isOpen={loading} />}
    </Box>

    </StorageProvider>
  );
};

export default DosageExtraContentModal;
