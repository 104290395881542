/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ContextAplication } from '../../../context';
import BBLoading from '../../molecules/BBLoading';
import AccordionAtom from '../../atoms/AccordionAtom';
import Footer from '../../atoms/ModalFooter';
import ContraIndicationsForm from '../../molecules/ContraIndicationsForm';
import { handleSubmitLinks } from '../../../utils/handleSubmitLinks';

const AccordionContraIndications = ({ id, handleGetReviewById, review }) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    handleUpdate,
    isUpdate,
    activePrincipleModel,
    handleGetActivePrincipleModelById,
  } = useContext(ContextAplication);

  const [initialDataValues, setInitialDataValues] = useState(null);
  const [changeInitialDataValues, setChangeInitialDataValues] = useState(true);

  useEffect(() => {
    if (changeInitialDataValues) {
      setInitialDataValues(activePrincipleModel);
      setChangeInitialDataValues(false);
    }
  }, [, changeInitialDataValues]);

  const submit = async (isReview) => {
    setLoading(true);
    try {
      await handleSubmitLinks(
        initialDataValues,
        activePrincipleModel,
        ['contraindications'],
        activePrincipleModel._id
      );

      await handleUpdate(
        'activeprinciple',
        activePrincipleModel._id,
        {
          contraindications:
            activePrincipleModel?.contraindications.toString() === '<p>null</p>'
              ? ''
              : activePrincipleModel.contraindications,
          contraindications_source:
            activePrincipleModel.contraindications_source,
          review_id: activePrincipleModel.review_id,
        },
        'contra_indications_review_log_id'
      );

      if (isReview) {
        await handleSubmit(
          `review/${activePrincipleModel.review_id}/contra_indications_review_log_id`,
          {}
        );
      }
      toast.success('Contraindicação atualizada com sucesso!');

      setLoading(false);
      await handleGetActivePrincipleModelById(activePrincipleModel._id);
      setChangeInitialDataValues(true);
      return handleGetReviewById();
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar a contraindicação!');
      setLoading(false);
    }
  };

  return (
    <AccordionAtom
      id={id}
      accordionTitle="Contraindicações"
      colorTheme="orange"
      isReviewed={review?.contra_indications_review_log_id || false}
      reviewDate={review?.contra_indications_review_log_id?.createdAt || ''}
      reviewAuthor={
        review?.contra_indications_review_log_id?.author_id?.name
          ? review.contra_indications_review_log_id.author_id.name
          : ''
      }
    >
      <ContraIndicationsForm />

      <Footer
        log_review={review?.contra_indications_review_log_id}
        isUpdate={isUpdate}
        submit={submit}
      />
      {loading && <BBLoading isOpen={loading} />}
    </AccordionAtom>
  );
};

export default AccordionContraIndications;
