import React from 'react';

import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

import { Container } from '../../atoms/ModalAtom/styles';

import ModalHeader from '../../atoms/ModalHeader';
import ButtonAtom from '../../atoms/ButtonAtom';

import useStylesDosageExtraContentForm from './style';

function HandleShowImage({ image, className, style }) {
  return (
    <img
      loading="lazy"
      style={style}
      className={className}
      src={`https://firebasestorage.googleapis.com/v0/b/${
        process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
      }/o/${image?.replace('images/', 'images%2F')}?alt=media`}
      alt={image}
    />
  );
}

const ImageForm = ({ modalTheme, onClose, title, image, submit }) => {
  const classes = useStylesDosageExtraContentForm();


  return (
    <>
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container modalTheme={modalTheme}>
          <ModalHeader onClose={onClose} title={title} />

          <HandleShowImage image={image} className={classes.box_image}/>

          <Box className={classes.button_delete_image}>
            <ButtonAtom
              textButton="Deletar imagem"
              icon={false}
              styles={classes.delete_button_color}
              onClick={() => submit(false)}
            />
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default ImageForm;
