/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import api from '../../../services/axiosApi';
import { ContextAplication } from '../../../context';
import AccordionAtom from '../../atoms/AccordionAtom';
import DataGridAtom from '../../atoms/DataGridAtom';
import TooltipAtom from '../../atoms/TooltipAtom';
import CardHeaderMolecule from '../../molecules/CardHeaderMolecule';
import CardFooterMolecule from '../../molecules/CardFooterMolecule';

import useStylesDosageComponents from './styles';
import UseAndSafetyModal from '../UseAndSafetyModal';
import { getNameAuthor, getReviewCreatedAt } from '../../../utils/reviewFunctions';

const AccordionUseAndSafety = ({ id, handleGetReviewById, review }) => {
  const [useAndSafety, setUseAndSafety] = useState([]);
  const [useAndSafetyId, setUseAndSafetyId] = useState(null);
  const [openEmptyReviewDialog, setOpenEmptyReviewDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { activePrincipleModel } = useContext(ContextAplication);

  const classes = useStylesDosageComponents();

  function parseHTML(html) {
    var t = document.createElement('template');
    t.innerHTML = html;
    return t.content.cloneNode(true);
  }

  const handleGetUseAndSafetiesByActivePrincipleId = async (isFirstRendering = false) => {
    try {
      const response = await api.get(
        `useandsafety/by-active-principle/${activePrincipleModel._id}`
      );
      if(!isFirstRendering) {
        await handleGetReviewById();
      }
      setUseAndSafety(response.data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar uso e segurança!');
    }
  };

  useEffect(() => {
    handleGetUseAndSafetiesByActivePrincipleId(true);
  }, []);

  async function handleDeleteButton(useAndSafetyId, useAndSafetyTitle) {
    try {
      if (window.confirm(`Deseja deletar ${useAndSafetyTitle}?`)) {
        await api.delete(
          `useandsafety/${useAndSafetyId}/${activePrincipleModel._id}`
        );
        await handleGetUseAndSafetiesByActivePrincipleId();
        await handleGetReviewById();
        toast.success('Uso e segurança deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao deletar uso e segurança!');
    }
  }

  const handleEmptySessionReview = async () => {
    try {
      await api.post(
        `review/${activePrincipleModel.review_id}/safety_full_review`,
        {}
      );
      await handleGetUseAndSafetiesByActivePrincipleId();
      await handleGetReviewById();
      toast.success('Uso e segurança revisado como vazio.');
    } catch (error) {
      toast.error('Ocorreu um erro ao revisar como vazio!');
    }

    setOpenEmptyReviewDialog(false);
  };

  const handleOpenForm = (id) => {
    setOpenModal(true);
    setUseAndSafetyId(id);
  };

  const columns = [
    {
      field: 'category',
      headerName: 'Categoria',
      align: 'left',
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.category} placement="top">
          <Typography
            component={'span'}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {row.category}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: 'observation',
      headerName: 'Conteúdo',
      align: 'left',
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.observation} placement="top">
          <Typography
            component={'span'}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {row.observation}
          </Typography>
        </TooltipAtom>
      ),
    },
    {
      field: 'review',
      headerName: 'Revisão',
      align: 'center',
      flex: 0.5,
      renderCell: ({ row }) => (
        <TooltipAtom title={row.review} placement="top">
          <Typography component={'span'}>{row.review}</Typography>
        </TooltipAtom>
      ),
    },
    {
      field: '',
      headerName: 'Ações',
      sortable: false,
      align: 'center',
      flex: 0.3,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <Box className={classes.icons_container}>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleOpenForm(row._id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleDeleteButton(row._id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  let row = [];
  useAndSafety.length &&
    useAndSafety.map(
      (
        {
          _id,
          content,
          deleted,
          category_type_id,
          content_source,
          log_review_id,
        },
        index
      ) =>
        !deleted &&
        row.push({
          id: index,
          _id,
          content,
          deleted,
          content_source,
          category_type_id,
          category: category_type_id && category_type_id.title,
          observation: content && parseHTML(content).textContent,
          review: log_review_id
            ? moment(log_review_id.createdAt).format('D/M/YYYY - HH:mm')
            : '---',
        })
    );

  return (
    <AccordionAtom
      id={id}
      accordionTitle="Uso e segurança"
      componentName="useandsafety"
      colorTheme="purple"
      isReviewed={review?.safety_full_review || false}
      reviewDate={
        review?.safety_full_review ? getReviewCreatedAt(useAndSafety) : ''
      }
      reviewAuthor={
        review?.safety_full_review ? getNameAuthor(useAndSafety) : ''
      }
    >
      <CardHeaderMolecule
        handleOpenForm={() => handleOpenForm(null)}
        textAddButton="Adicionar uso e segurança"
      />
      <Box>
        <DataGridAtom
          pageSize={10}
          arrData={row}
          columns={columns}
          theme="purple"
        />
      </Box>
      <CardFooterMolecule
        isEmpty={!row.filter(({ deleted }) => deleted === false).length}
        handleEmptySessionReview={handleEmptySessionReview}
        openEmptyReviewDialog={openEmptyReviewDialog}
        setOpenEmptyReviewDialog={setOpenEmptyReviewDialog}
      />

      {
        openModal &&
        <UseAndSafetyModal
          closeModal={() => {setOpenModal(false); }}
          idUseAndSafety={useAndSafetyId}
          setUseAndSafetyId={(param) => setUseAndSafetyId(param)}
          handleGetUseAndSafetiesByActivePrincipleId={() => handleGetUseAndSafetiesByActivePrincipleId()}
        />
      }
    </AccordionAtom>
  );
};

export default AccordionUseAndSafety;
