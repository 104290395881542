import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";

import TooltipAtom from "../../atoms/TooltipAtom";

import useStylesSideBarMolecule from "./styles";

export default function SideBarMolecule({
  open,
  handleDrawerClose,
  selected,
  sideBarOptions,
  logo,
}) {
  const classes = useStylesSideBarMolecule();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <div className={classes.container_logo}>
          <img
            src={logo}
            alt="Imagem logo BlackBook"
            className={classes.image}
          />
          <Typography component={"span"} variant="h5">
            BlackBook
          </Typography>
        </div>
        <IconButton onClick={handleDrawerClose} className={classes.icon}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {sideBarOptions.map((option) => (
          <TooltipAtom
            key={option.name}
            disableHoverListener={open}
            title={option.name}
            placement="right"
          >
            <ListItem
              button
              className={classes.listItem}
              selected={option.href === selected}
              component={Link}
              to={option.href}
            >
              <ListItemIcon className={classes.icon}>
                {option.icon}
              </ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItem>
          </TooltipAtom>
        ))}
      </List>
    </Drawer>
  );
}
