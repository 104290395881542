import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

function TooltipAtom({ children, placement, title, disableHoverListener }) {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
    },
  }))(Tooltip);

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography component={"span"} color="inherit">
            {title}
          </Typography>
        </React.Fragment>
      }
      placement={placement}
      disableHoverListener={
        disableHoverListener ? disableHoverListener : undefined
      }
    >
      {children}
    </HtmlTooltip>
  );
}

export default TooltipAtom;
