import moment from 'moment';

// retorna o log_review_id com a útima data de review
const sortedReviewDates = (field) => {
  const sortedByDate = field
    .filter(({ log_review_id, deleted }) => !deleted && log_review_id)
    .sort(
      (a, b) =>
        moment(b.log_review_id.createdAt) - moment(a.log_review_id.createdAt)
    );
  if (sortedByDate.length) {
    return sortedByDate[0].log_review_id;
  }

  return null;
};

// retorna a última data de revisão
export const getReviewCreatedAt = (field) => {
  if (field.length) {
    const data = sortedReviewDates(field);
    if (data && data.createdAt) {
      return data.createdAt;
    }
  }

  return '';
};

// retorna o último autor que revisou
export const getNameAuthor = (field) => {
  if (field.length) {
    const data = sortedReviewDates(field);
    if (data && data.author_id) {
      return data.author_id?.name;
    }
  }
  return "";
};