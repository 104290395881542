export const dosage_model = {
  recommendation: '',
  category_type_id: '',
  content: '',
  content_source: '',
  extra_contents_id: [],
  keywords_id: [],
  show_in_search: true,
  is_usual: false,
};
