import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
  CssBaseline,
} from '@material-ui/core';
import {
  AllInbox as ActivePrincipleIcon,
  ViewList as SpecialtyIcon,
  GroupWork as TherapeuticGroupIcon,
  Business as LaboratoryIcon,
  EmojiSymbols as PharmaceuticalFormIcon,
  Straighten as UnitIcon,
  History as HistoryIcon
} from '@material-ui/icons';
import { clearLocalStorage, logout } from "../../../services";
import AppBarMolecule from "../../molecules/AppBarMolecule";
import SideBarMolecule from "../../molecules/SideBarMolecule";
import useStylesMenuOrganism from "./styles";
import logo from '../../../assets/images/logo.png';
import { ImLink } from 'react-icons/im';

const sideBarOptions = [
  {
    name: 'Princípios Ativos',
    href: '/activeprinciple',
    icon: <ActivePrincipleIcon />
  },
  {
    name: 'Especialidades',
    href: '/specialty',
    icon: <SpecialtyIcon />
  },
  {
    name: 'Grupos Terapêuticos',
    href: '/therapeuticgroup',
    icon: <TherapeuticGroupIcon />
  },
  {
    name: 'Laboratórios',
    href: '/laboratory',
    icon: <LaboratoryIcon />
  },
  {
    name: 'Formas Farmacêuticas',
    href: '/pharmaceuticalform',
    icon: <PharmaceuticalFormIcon />
  },
  {
    name: 'Unidades',
    href: '/unit',
    icon: <UnitIcon />
  },
  {
    name: 'Histórico',
    href: '/logs',
    icon: <HistoryIcon />
  },
  {
    name: 'Links Pendentes',
    href: '/pendinglinks',
    icon: <ImLink style={{fontSize: '16px'}} />
  }
];

export default function MenuOrganism({open, setOpen}) {
  const classes = useStylesMenuOrganism();
  const history = useHistory();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    clearLocalStorage();
    return history.push("/login");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarMolecule 
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleLogout={handleLogout}
        logo={logo}
      />
      <SideBarMolecule 
        open={open}
        handleDrawerClose={handleDrawerClose}
        selected={location.pathname}
        sideBarOptions={sideBarOptions}
        logo={logo}
      />
    </div>
  );
}
