/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

import { toast } from "react-toastify";

import { codeHTMLToTranformAccents } from "../../../utils/codeHTMLToTranformAccents";
import LinkAtom from "../../atoms/LinkAtom";
import BBLoading from "../../molecules/BBLoading";
import FormRoutineLink from "../FormsLink/FormRoutineLink";
import FormDrugsLink from "../FormsLink/FormDrugsLink";

import { ContextAplication } from "../../../context";

const ModalLink = ({
  setOpenModalLink,
  linkManager,
  setLinkManager,
  content,
  setContent,
  objectData,
  setObjectData,
  objectRoute,
  field,
  brandId,
  setPresentationId,
}) => {
  const {
    handleSubmit,
    textAndIdLink,
    setTextAndIdLink,
    activePrincipleModel,
    setActivePrincipleModel,
    handleUpdate,
    handleGetActivePrincipleModelById,
    handleDelete,
    handleGet,
  } = useContext(ContextAplication);

  const [open, setOpen] = useState(false);
  const [arrSelected, setArrSelected] = useState(linkManager);
  const [loading, setLoading] = useState(false);
  const [therapeuticGroup, setTherapeuticGroup] = useState([]);
  const [activePrinciples, setActivePrinciples] = useState([]);
  const [dosages, setDosages] = useState([]);
  const [routines, setRoutines] = useState([]);
  const [tabs, setTabs] = useState([]);

  const closeLinkModal = () => {
    setOpen(!open);
    setTextAndIdLink({ text: "", id: "" });
    setLinkManager(null);
    setOpenModalLink(false);
    setLoading(false);
  };

  const handleByQuery = async (url, setState) => {
    try {
      const response = await handleGet(url);
      if (setState) {
        setState(response);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const checkWhatTheRequestOptionWillBe = async () => {
    let response;

    if (brandId) {
      response = await handleUpdate(
        `${objectRoute}`,
        `${objectData._id}/${brandId}/${activePrincipleModel._id}`,
        objectData
      );
      if (setPresentationId) {
        await setPresentationId(objectData._id);
      }
    } else if (
      objectRoute === "contraindications" ||
      objectRoute === "basic_information"
    ) {
      response = await handleUpdate(
        objectRoute === "contraindications" ||
          objectRoute === "basic_information"
          ? "activeprinciple"
          : `${objectRoute}`,
        objectData._id,
        { [field]: objectData[field] },
        activePrincipleModel._id
      );

      await setActivePrincipleModel({
        ...activePrincipleModel,
        [field]: content,
      });
    } else {
      response = await handleUpdate(
        `${objectRoute}`,
        objectData._id,
        objectData,
        activePrincipleModel._id
      );
    }
    return response;
  };

  const handlesLinkUpdates = async () => {
    const responseRequest = await checkWhatTheRequestOptionWillBe();

    await handleGetActivePrincipleModelById(activePrincipleModel._id);

    closeLinkModal();
    return responseRequest;
  };

  const handleTheRequestToRemoveTheLink = async () => {
    setLoading(true);
    try {
      await handleDelete(
        `linkmanager/${linkManager._id}/${activePrincipleModel._id}`
      );

      objectData[field] = await content.replaceAll(
        linkManager.href,
        linkManager.text
      );

      await setContent(objectData[field]);
      const response = await handlesLinkUpdates();
      await setObjectData(response);
      setLoading(false);
      toast.success("Sucesso!");
      return closeLinkModal();
    } catch (error) {
      toast.error("Ocorreu um erro ao realizar essa alteração!");
      setLoading(false);
      return closeLinkModal();
    }
  };

  const assemblesRequestObject = async () => ({
    therapeutic_group_id: linkManager?.therapeutic_group_id
      ? linkManager.therapeutic_group_id._id
      : null,
    active_principle_id: linkManager?.active_principle_id
      ? linkManager.active_principle_id._id
      : null,
    dosage_id: linkManager?.dosage_id ? linkManager.dosage_id._id : null,
    type: arrSelected?.routine_id ? "R" : "D",
    routine_id: arrSelected?.routine_id ? arrSelected.routine_id._id : null,
    tab_id: arrSelected?.tab_id ? arrSelected.tab_id._id : null,
    card_id: arrSelected?.card_id ? arrSelected.card_id._id : null,
    link_origin_id: objectData._id,
    link_origin_text: textAndIdLink.text,
    link_id: textAndIdLink.id,
    field_origin: objectRoute,
  });

  const handlesLinkCreate = async () => {
    if (!objectData?._id) {
      toast.error("Não é possível criar um link sem uma origem!");
      return closeLinkModal();
    }
    try {
      const objLink = await assemblesRequestObject();

      let newRowText = textAndIdLink.newRow;

      if (!linkManager?._id) {
        if(objLink.therapeutic_group_id !== null || objLink.routine_id !== null) {
          const newRowParsed = await codeHTMLToTranformAccents(newRowText);

          newRowText = newRowParsed
            .replace(
              `<a data-pending="true" href=\"{{link=${objLink.link_id}}}\">`, 
              `<a href=\"{{link=${objLink.link_id}}}\">`
              ).replace(
                `<a href=\"{{link=${objLink.link_id}}}\" data-pending="true">`, 
                `<a href=\"{{link=${objLink.link_id}}}\">`
              )
        }

        const oldRowParsed = codeHTMLToTranformAccents(textAndIdLink.oldRow)

        objectData[field] = await content.replaceAll(
          oldRowParsed,
          newRowText
          );

      } else {
        if(objLink.therapeutic_group_id === null && objLink.routine_id === null) {
          objectData[field] = await content.replaceAll(
            `<a href="{{link=${objLink.link_id}}}">`,
            `<a data-pending="true" href="{{link=${objLink.link_id}}}">`, 
            )
          } else {
          objectData[field] = await content.replaceAll(
            `<a data-pending="true" href="{{link=${objLink.link_id}}}">`, 
            `<a href="{{link=${objLink.link_id}}}">`
            )
          objectData[field] = await content.replaceAll(
            `<a href="{{link=${objLink.link_id}}}" data-pending="true">`, 
            `<a href="{{link=${objLink.link_id}}}">`
            )
        }  
      }

      await setContent(objectData[field]);

      setLoading(true);

      !linkManager?._id
        ? await handleSubmit(`linkmanager/${activePrincipleModel._id}`, objLink)
        : await handleUpdate("linkmanager", linkManager._id, objLink);

      const response = await handlesLinkUpdates();
      await setObjectData(response);
      setLoading(false);
      return closeLinkModal();
    } catch (error) {
      setLoading(false);
      return closeLinkModal();
    }
  };

  const handleFormChange = async (field, value) => {
    switch (field) {
      case "therapeutic_group_id":
        setLinkManager({ _id: linkManager?._id || undefined, href: linkManager?.href, text: linkManager?.text, [field]: value });
        setArrSelected(null);
        const pathUrl = `activeprinciple/query-filter?select[]=title&query[therapeutic_group_id][_id]=${value._id}`;
        if (value?._id) {
          handleByQuery(
            pathUrl,
            setActivePrinciples
          );
        }
        break;
      case "active_principle_id":
        setLinkManager({
          ...linkManager,
          [field]: value,
          dosage_id: undefined,
        });
        if (value?._id) {
          handleByQuery(`dosage/by-active-principle/${value._id}`, setDosages);
        }
        break;
      case "dosage_id":
        setLinkManager({ ...linkManager, [field]: value });
        break;
      case "routine_id":
        let response = [];
        if (value?.routine_tabs_id.length) {
          const query = value.routine_tabs_id.map((id) => `query[_id][]=${id}`).join('&');
          response = await handleByQuery(
            `routine/collection-and-query/r_routine_tabs?select[]=tab_id&select[]=routine_tabs_cards_id&${query}`
          );
        }
        let arrTab = [];
        setTabs(response);
        response.forEach((e) => arrTab.push(e?.tab_id));
        setArrSelected({ [field]: value });
        setLinkManager({
          _id: linkManager?._id || undefined,
          href: linkManager?.href,
          text: linkManager?.text,
          [field]: value,
          tab_id: arrTab,
        });
        break;
      case "tab_id":
        let arrCard = [];
        tabs.forEach(async (e) => {
          if (value?.title === e?.tab_id.title) {
            let resCards = [];
            if (e?.routine_tabs_cards_id.length) {
              const query = e.routine_tabs_cards_id.map((id) => `query[_id][]=${id}`).join('&');
              resCards = await handleByQuery(
                `routine/collection-and-query/r_routine_tab_cards?select[]=card_id&${query}`
              );
            }
            return resCards.forEach((card) => arrCard.push(card.card_id));
          }
        });
        setLinkManager({ ...linkManager, card_id: arrCard });
        setArrSelected({
          ...arrSelected,
          [field]: value,
          card_id: undefined,
        });
        break;
      case "card_id":
        setArrSelected({ ...arrSelected, [field]: value });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleByQuery(
      `therapeuticgroup/query-filter?select[]=title`,
      setTherapeuticGroup
    );
    handleByQuery(
      `routine/collection-and-query/r_routine?select[]=title&select[]=routine_tabs_id&select[]=legend`,
      setRoutines
    );
  }, []);

  return (
    <Box>
      <Modal open={true}>
        <LinkAtom
          formOption1={
            <FormRoutineLink
              handleFormChange={(field, value) => handleFormChange(field, value)}
              routines={routines}
              arrSelected={arrSelected}
              linkManager={linkManager}
              textAndIdLink={textAndIdLink}
              closeLinkModal={closeLinkModal}
              handleTheRequestToRemoveTheLink={handleTheRequestToRemoveTheLink}
              handlesLinkCreate={handlesLinkCreate}
            />
          }
          formOption2={
            <FormDrugsLink
              closeLinkModal={closeLinkModal}
              textAndIdLink={textAndIdLink}
              handleFormChange={(field, value) => handleFormChange(field, value)}
              therapeuticGroup={therapeuticGroup}
              linkManager={linkManager}
              activePrinciples={activePrinciples}
              dosages={dosages}
              handleTheRequestToRemoveTheLink={handleTheRequestToRemoveTheLink}
              handlesLinkCreate={handlesLinkCreate}
            />
          }
          open={open}
          tabValue={linkManager?.routine_id ? 1 : 0}
          setOpen={setOpen}
        />
      </Modal>
      {loading && <BBLoading isOpen={loading} />}
    </Box>
  );
};

export default ModalLink;
