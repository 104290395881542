/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ContextAplication } from "../../context";
import DefaultLoggedTemplate from "../../components/templates/DefaultLoggedTemplate";
import { active_principle } from "../../models/activePrinciple";
import AccordionBasicInformation from "../../components/organisms/AccordionBasicInformation";

import useStylesActivePrincipleCreate from "./style";

const ActivePrincipleCreate = () => {
  const classes = useStylesActivePrincipleCreate();
  const history = useHistory();
  const {
    setIsUpdate,
    setActivePrincipleModel,
    activePrincipleModel,
    setIsLogTherapeuticGroup,
  } = useContext(ContextAplication);

  useEffect(() => {
    setActivePrincipleModel(active_principle);
    setIsUpdate(false);
  }, []);

  const back = () => {
    setIsLogTherapeuticGroup(false);
    setActivePrincipleModel(null);
    return history.push("/activeprinciple");
  };

  return (
    <DefaultLoggedTemplate>
      <Box className={classes.page_container}>
        <Box className={classes.content_box}>
          <Box className={classes.header_active_principle}>
            <Typography component={'span'} variant="h5" className={classes.active_principle_title}>
              Princípio Ativo:{" "}
              {activePrincipleModel ? activePrincipleModel.title : ""}
            </Typography>
            <Button
              classes={{ root: classes.back_button }}
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={back}
            >
              Voltar
            </Button>
          </Box>
          <Box className={classes.grid_accordions_style}>
            {activePrincipleModel &&
              <AccordionBasicInformation style={{marginBottom: '30px !important'}}/>
            }
          </Box>
        </Box>
      </Box>
    </DefaultLoggedTemplate>
  );
};

export default ActivePrincipleCreate;
