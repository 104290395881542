import _ from 'lodash';

import axiosApi from '../services/axiosApi';

function splitLinksFromHTML(html) {
  const arrayOfLinks = html?.match(/<a(.*?)<\/a>/gm);

  const temp = arrayOfLinks?.map(link => {
    const link_id = parseInt(link.replace(
      /<a(.*?)link=%22|%22%7D%7D(.*?)<\/a>|<a(.*?)link="|"}}(.*?)<\/a>|<a(.*?)link=|}}(.*?)<\/a>/gm, ''));
    const link_origin_text = link.replace(/<a(.*?)>|<\/a>/gm, '');
    return {link_id, link_origin_text};
  })

  return temp
}

function parseLinks(before, after) {
  const linksBefore = splitLinksFromHTML(before)
  const linksAfter = splitLinksFromHTML(after)

  let linksToUpdate = _.differenceWith(linksAfter, linksBefore, _.isEqual);
  let linksToDelete = _.differenceWith(linksBefore, linksAfter, _.isEqual);

  linksToDelete = linksToDelete.filter(({ link_id: id1 }) => !linksToUpdate.some(({ link_id: id2 }) => id2 === id1));
  linksToDelete = linksToDelete.map((link) => link.link_id);

  return { linksToUpdate, linksToDelete };
}

export async function handleSubmitLinks(objectBefore, objectAfter, fields, activePrincipleId) {
  try {
    let tempDelete = []
    let tempUpdate = []
    fields.forEach(field => {
      const {linksToUpdate, linksToDelete} = parseLinks(objectBefore[field], objectAfter[field])
      tempDelete = [...tempDelete, ...linksToDelete]
      tempUpdate = [...tempUpdate, ...linksToUpdate]
    })

    tempUpdate.length && await axiosApi.put('linkManager/update-many-links', {linksToUpdate: tempUpdate})
    tempDelete.length && await axiosApi.delete(`linkManager/delete-many-links/${activePrincipleId}`, {data: {linksToDelete: tempDelete}})
  
  } catch (error) {
    console.log(error)    
  }
} 