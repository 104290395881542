export function codeHTMLToTranformAccents(text) {
  var htmlCodigo = {
    á: { code: "&aacute;" },
    ã: { code: "&atilde;" },
    à: { code: "&agrave;" },
    â: { code: "&acirc;" },
    Á: { code: "&Aacute;" },
    Ã: { code: "&Atilde;" },
    À: { code: "&Agrave;" },
    é: { code: "&eacute;" },
    ê: { code: "&ecirc;" },
    É: { code: "&Eacute;" },
    í: { code: "&iacute;" },
    Í: { code: "&Iacute;" },
    ó: { code: "&oacute;" },
    õ: { code: "&otilde;" },
    ô: { code: "&ocirc;" },
    Ó: { code: "&Oacute;" },
    Õ: { code: "&Otilde;" },
    ú: { code: "&uacute;" },
    Ú: { code: "&Uacute;" },
    ç: { code: "&ccedil;" },
    Ç: { code: "&Ccedil;" },
    "÷": { code: "&divide;" },
    " ": { code: "&nbsp;" },
    "&nbsp;": { code: " " },
    "β": { code: "&beta;" },
    "–": { code: "&ndash;" },
    "°": { code: "&deg;" },
    "º": { code: "&ordm;" },
    "ª": { code: "&ordf;" },
    "+": { code: "&plus;" },
    "≥": { code: "&ge;" },
    "≤": { code: "&le;" },
    "•": { code: "&bull;" },
    "μ": { code: "&mu;" },
    ">": { code: "&gt;" },
    "<": { code: "&lt;" }
  };
  var accents = [
    "á",
    "Á",
    "ã",
    "Ã",
    "à",
    "â",
    "À",
    "é",
    "ê",
    "É",
    "í",
    "Í",
    "ó",
    "Ó",
    "õ",
    "ô",
    "Õ",
    "ú",
    "Ú",
    "ç",
    "Ç",
    "÷",
    " ",
    "β",
    "–",
    "°",
    "º",
    "ª",
    "+",
    "≥",
    "≤",
    "•",
    "μ",
    ">",
    "<"
  ];

  for (var i = 0; i < accents.length; i++) {
    if (htmlCodigo[accents[i]] !== undefined) {
      text = text.replaceAll(htmlCodigo[accents[i]].code, accents[i]);
    }
  }

  return text;
}
