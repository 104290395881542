import React, { useEffect } from "react";
import api from "../../../services/axiosApi";
import { HiOutlineTrash } from "react-icons/hi";


export function KeywordsList({ keywordArray, keywordsList, setKeywordsList, route, contentIds }) {

  async function handleRemoveKeyword(keywordId) {
    try {
      if (window.confirm('Deseja deletar essa palavra-chave?')) {
        const response = await api.put(`${route}/remove-keywords/${keywordId}/${contentIds}`)
        setKeywordsList(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function handleGetKeywords() {
    try {
      const response = await api.post('/keyword/by-array', { keywordIds: keywordArray })
      setKeywordsList(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetKeywords()
  }, [])

  return (
    <>
      {keywordsList.length ? (
        <div className={"keywordsList"}>
          <ul>
            {keywordsList && keywordsList.map((keyword) => (
              keyword.new ?
                <li className="newKeyword">{keyword.title}
                  <button
                    className="removeKeyword__button"
                    onClick={() => handleRemoveKeyword(keyword._id)}
                  >
                    <HiOutlineTrash />
                  </button>
                </li>
                :
                <li>{keyword.title}
                  <button
                    className="removeKeyword__button"
                    onClick={() => handleRemoveKeyword(keyword._id)}
                  >
                    <HiOutlineTrash />
                  </button>
                </li>
            ))}
          </ul>
        </div>
      ) : (
        <h3 className="emptyKeyword__list">Não há palavras-chave selecionadas.</h3>
      )}
    </>
  );
}