import { makeStyles } from '@material-ui/core/styles';

const useStylesPresentationComponents = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '5vh',
    width: '60vw',
    borderBottom: '0.5px solid dimgray',
  },

  close_icon: {
    color: '#fff',
    minWidth: 0,
  },

  dialog_style: {
    background: '#424242',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    '& .MuiTypography-colorTextSecondary': {
      color: '#fff',
    },
  },

  dialog_action: {
    fontSize: '1rem',
    fontWeight: '700',
  },

  header_content: {
    width: '10vw',
  },

  icons_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  presentation_header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
  },

  modal_title: {
    display: 'flex',
    flex: 'row',
    color: '#fff',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },

  modal_header: {
    color: '#fff',
  },

  paper: {
    margin: '6vh 20vh 20vh 20vh',
    position: 'absolute',
    width: '80%',
    height: 'auto',
    maxHeight: '90%',
    color: '#000',
    backgroundColor: '#769CAB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#486278',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#769CAB',
    },
  },

  basic_select_field: {
    display: 'flex',
    padding: 0,
    marginBottom: '10px',
    backgroundColor: '#769cab',
    fontWeight: 'bold',
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  basic_text_field: {
    display: 'flex',
    padding: 0,
    backgroundColor: '#769cab',
    color: '#000',
    fontWeight: '900',
    '& label.Mui-focused': {
      color: '#1661a1',
    },
    '& label': {
      color: '#606060',
    },
    '& input': {
      color: '#000',
      fontWeight: 'bold',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1661a1',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#606060',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  saveButton: {
    width: 110,
    height: 40,
    float: 'right',
    marginTop: '1%',
    marginLeft: '1%'
  },

  table_container: {
    backgroundColor: '#7196a4',
  },

  table: {
    '& .MuiTableCell-root .MuiTableCell-head .MuiTableCell-alignLeft': {
      position: 'fixed',
    },

    '& MuiTableRow-head': {
      position: 'absolute',
      width: '60vw',
      display: 'flex',
      justifyContent: 'space-around',
    },

    '& .MuiTableCell-head': {
      color: '#000',
      height: '7vh',
      fontSize: '1.3rem',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiTableCell-root': {
      padding: '0 0 0 10px',
      borderBottom: '0.5px rgba(224, 224, 224, 1)',
    },
  },

  title_content: {
    color: '#000',
    fontSize: '1rem',
    fontWeight: 'bold',
    maxWidth: '10vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

}));

export default useStylesPresentationComponents;
