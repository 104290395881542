import React from "react";
import Button from "@material-ui/core/Button";

function ButtonAtom({ textButton, icon, onClick, styles }) {
  return (
    <Button
      variant="contained"
      color="primary"
      className={styles ? styles : null}
      startIcon={icon ? icon : null}
      onClick={() => onClick()}
    >
      {textButton}
    </Button>
  );
}

export default ButtonAtom;
