import { makeStyles } from '@material-ui/core/styles';

const useStylesListTherapeuticGroup = makeStyles((theme) => ({

  accordion_complete: {},

  accordion_summary: {
    backgroundColor: '#545454',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2vh',
    height: '5vh',
  },

  accordion_details: {
    backgroundColor: '#909090',
    display: 'flex',
    flexDirection: 'column',
  },

  search_field: {
    display: 'flex',
    padding: 0,
    margin: '7px',
    backgroundColor: '#909090',
    fontWeight: 'bold',
    '& label.Mui-focused': {
      color: '#606060',
    },
    '& label': {
      color: '#000',
    },
    '& input': {
      color: '#606060',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#424242',
      },
      '&:hover fieldset': {
        borderColor: 'dimgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'dimgray',
      },
    },
  },

  title_accordion: {
    fontWeight: 'bold', 
    display: 'flex',
    marginLeft: '10px'
  },

  therapeutic_group_list: {
    display: 'grid',
  },

  list_style: {
    height: '23vh',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#545454',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#909090',
    },
  },

  list_selected_style: {
    height: '23vh',
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: '5px',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#545454',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#909090',
    },
  },

  chip_style: {
    backgroundColor: '#545454',
    color: '#FFF',
    fontWeight: 'bold',
    minHeight: '32px',
    marginTop: '2px',
  },

  buttonList: {
    display: 'block',
    marginLeft: '5px'
  },
  
}));

export default useStylesListTherapeuticGroup;
